import React, { useState, useEffect, useContext } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Row,
  Icon,
  TooltipComponent,
  UserAvatar,
  BlockAthenty,
} from "../../../../components/Component";
import { Link, useParams } from "react-router-dom";
import ticketData from "../data/tickets";
import { Badge, Button, Card, Form, Input, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import attachmentA from "../../../../images/gfx/attach-a.jpg";
import attachmentB from "../../../../images/gfx/attach-b.jpg";
import { apiRequest } from "../../../../utils/Api";
import moment from "moment/moment";
import { findUpper } from "../../../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../../layout/Index";

const TicketDetails = () => {
  const [img, setImg] = useState("");
  const { userData } = useContext(UserContext)

  // console.log(userData);
  const [ticket, setTicket] = useState();
  const [loading, setLoading] = useState();
  const location = useParams();
  const [open, setOpen] = useState(false);
  const toggle = (base64) => {
    setImg(base64);
    setOpen(!open);
  };
  const [modal, setModal] = useState(false);
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    getTicket();
  }, []);

  const getTicket = async () => {
    try {
      setLoading(true);
      await apiRequest(`/customer/a/tickets/${location.ticket_id}`, "GET").then((resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          setTicket(resp.data[0]);
          console.log(resp.data[0]);
          console.log(ticket);
          setLoading(false);
        } else {
          console.log(resp);
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const onFormSubmit = async () => {
    console.log("submit");
    setLoading(true);
    try {
      let data = getValues();
      data.from_name = userData.name;
      data.department = ticket.queue_name.toLowerCase();
      data.from_email = userData.email;
      data.subject = ticket.subject;
      // data.subject = ticket.subject;
      await apiRequest(`/customer/a/tickets/${location.ticket_id}`, "POST", data).then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setLoading(false);
          toggleModal();
          toast.success("You message has been sent", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          console.log(resp);
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Ticket Details"></Head>
      {ticket && (
        <Content>
          <BlockAthenty
            head={"Client List"}
            nav="Tickets"
            title={`Ticket #${ticket.ticket_id}`}
            back="/customer/tickets"
            buttons={[
              {
                // type:"submit",
                text: "Reply To Ticket",
                icon: "plus",
                link: "",
                onclick: toggleModal,
                tooltip: "Create New Ticket",
                id: "submit",
                form: "verify-form",
                color: "primary",
              },
            ]}
            description={
              <div className="ticket-info">
                <ul className="ticket-meta">
                  <li className="ticket-id">
                    <span>Ticket ID:</span> <strong>{ticket.ticket_id}</strong>
                  </li>
                  <li className="ticket-date">
                    <span>Submitted:</span>{" "}
                    <strong>
                      {moment(ticket.ticket_created_at).format("YYYY-MM-DD")},{" "}
                      {moment(ticket.ticket_created_at).fromNow()}
                    </strong>
                  </li>
                </ul>
                <div className="ticket-status">
                  <Badge
                    className={`${ticket.status === "Open" ? " bg-success" : ""}${ticket.status === "Close" ? " bg-light" : ""
                      }`}
                  >
                    {ticket.ticket_status}
                  </Badge>
                </div>
              </div>
            }
          />

          <Block>
            <Card className="card-bordered">
              <div className="card-inner">
                {ticket.messages.map((message, index) => {
                  return (
                    <Card key={index} className="card-bordered mb-1">
                      <div key={index} className="card-inner">
                        <div className="ticket-msg-item">
                          <div className="ticket-msg-from">
                            <div className="ticket-msg-user user-card">
                              <UserAvatar
                                theme={message.avatarBg}
                                text={findUpper(message.creator)}
                                image={message.facial}
                                className=""
                              >
                                {/* <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                                <div
                                  className={`status dot dot-lg ${message.status === "attestation" ? "dot-success" : "dot-warning"
                                    } `}
                                >
                                  {/* <Icon name="shield-check" /> */}
                                </div>
                              </UserAvatar>
                              <div className="user-info">
                                <span className="lead-text">
                                  {message.creator === "RT_System" ? "Support Team" : message.creator}
                                </span>
                              </div>
                            </div>
                            <div className="ticket-msg-date">
                              <span className="sub-text">{moment(message.created_at).fromNow()}</span>
                            </div>
                          </div>
                          <div className="ticket-msg-comment">
                            <p>{message.content}</p>
                          </div>
                        </div>
                        {message.attachments && (
                          <div className="ticket-msg-attach">
                            <h6 className="title">Attachment</h6>
                            <ul className="ticket-msg-attach-list">
                              {message.attachments.map((attachment, index) => {
                                return attachment.filetype !== "text/plain" ? (
                                  <li>
                                    <a
                                      href="/#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        toggle(`data:${attachment.filetype};base64,${atob(attachment.content)}`);
                                      }}
                                    >
                                      <img
                                        src={`data:${attachment.filetype};base64,${atob(attachment.content)}`}
                                        alt=""
                                      />
                                      <Icon name="eye"></Icon>
                                    </a>
                                  </li>
                                ) : null;
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                      <Modal isOpen={open} toggle={toggle} size="large">
                        <button type="button" className="mfp-close" onClick={toggle}>
                          ×
                        </button>
                        <img className="w-100 rounded-top" style={{ height: "100%" }} src={img} alt="" />
                      </Modal>
                    </Card>
                  );
                })}
              </div>
            </Card>
          </Block>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader
              toggle={toggleModal}
              close={
                <button className="close" onClick={toggleModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              CONTACT US
            </ModalHeader>

            <ModalBody>
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Message<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <textarea
                      id="message"
                      name="message"
                      type="text"
                      className="form-control form-control-lg "
                      {...register("message", { required: true })}
                    ></textarea>
                    {errors.message && <p className="invalid">This field is required</p>}
                  </div>
                </div>

                <div className="center mt-2">
                  <button
                    onClick={toggleModal}
                    className={`${loading ? "disabled" : ""} center btn border me-2 m-1 closer`}
                    style={{ width: "90px" }}
                  >
                    Cancel
                  </button>
                  <Button color="primary" size="" className="" outline="">
                    {!loading ? "Send" : <Spinner />}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default TicketDetails;

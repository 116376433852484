import React from "react";

import { Row, Col } from "../../components/Component";
import { Link } from "react-router-dom";

const AuthFooter = () => {
  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container wide-lg">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <Link className="text-primary nav-link" target="_blank" to={`https://www.athenty.com/legal/terms`}>
                  Terms
                </Link>
              </li>
              <li className="nav-item">
                <Link className="text-primary nav-link" target="_blank" to={`https://www.athenty.com/legal/privacy`}>
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link className="text-primary nav-link" target="_blank" to={`https://www.athenty.com/legal/cookie`}>
                  Cookies Policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-center text-lg-start">
              <div className="nk-footer-copyright"> &copy; Copyright {new Date().getFullYear()}. Athenty Inc. </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;

import React, { useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { Icon, ReactDataTable } from "../../../../../../components/Component";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const verificationsColumns = [
  {
    name: "Request Id",
    selector: (row) => row.uuid,
    grow: 0.3,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">...{row.uuid.slice(-5)} </div>
      </div>
    ),
  },
  {
    name: "Type",
    selector: (row) => row.doctype,
    hide: 370,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">POI POA </span>
        </div>
      </div>
    ),
  },
  {
    name: "Status",
    selector: (row) => row.status,
    hide: "sm",
    grow: 1,
    cell: (row) => {
      if (row.status === "Approved") {
        return (
          <span
            className="text-success sub-text"
            aria-label="Pending Verification"
            data-bs-original-title="Pending Verification"
          >
            {row.status}
          </span>
        );
      }
      if (row.status === "Expired") {
        return (
          <span className="text-danger" aria-label="Pending Verification" data-bs-original-title="Pending Verification">
            {row.status}
          </span>
        );
      }
      if (row.status === "Received") {
        return (
          <span
            className="text-warning"
            aria-label="Pending Verification"
            data-bs-original-title="Pending Verification"
          >
            {row.status}
          </span>
        );
      }
    },
  },
  {
    name: "Date Request",
    selector: (row) => row.status,
    grow: 1.5,
    hide: 370,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">Jun 8 5:23PM </span>
        </div>
      </div>
    ),
  },
  {
    name: "COMPLETION DATE",
    selector: (row) => row.status,
    hide: "sm",
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">Jun 8 5:23PM </span>
        </div>
      </div>
    ),
  },

  {
    name: "Requested By",
    selector: (row) => row.status,
    hide: "sm",
    cell: (row) => (
      <div className="ms-3 user-card">
        <div className="user-avatar xs bg-primary">
          <span data-toggle="tooltip" aria-label="Jerald Hoffstead" data-bs-original-title="Jerald Hoffstead">
            <img src="https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png" alt="" />
          </span>
        </div>
      </div>
    ),
  },

  {
    name: "",
    selector: (row) => row.status,
    hide: "sm",
    cell: (row) => (
      <div className="row">
        <div style={{ paddingRight: "35px" }} className="col-6 col-sm-3">
          <UncontrolledDropdown className="mr-2">
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr ">
                <li>
                  <a
                    href="#sendEmail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="check"></Icon>
                    <span>Approve</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#edit"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="cross"></Icon>
                    <span>Reject</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    ),
  },
];

const VerifyVerification = (props) => {
  const [vetificationsData, setVerificationsData] = useState(props.data);

  return (
    <React.Fragment>
      <Content>
        {vetificationsData && <ReactDataTable data={vetificationsData} columns={verificationsColumns} pagination />}
      </Content>
    </React.Fragment>
  );
};

export default VerifyVerification;

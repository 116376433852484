import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  Input,
  PreviewCard,
} from "../../components/Component";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../utils/Api";
import { Spinner } from "reactstrap";

const ResetPassword = () => {
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState();
  const [passState, setPassState] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  useState(() => {
    setValue("code", query.get("code"));
  });

  const onFormSubmit = async (e) => {
    setSubmitButtonLoading(true);
    // console.log("form submit");
    console.log(getValues());
    try {
      await apiRequest("/customer/reset-password", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setSubmitButtonLoading(false);
        } else {
          console.log("error response: ", resp.message);
          setSubmitButtonLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
    }
  };

  return (
    <>
      <Head title="Forgot-Password" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <Input
                errors={errors}
                required
                type="password"
                requiredMessage={{
                  required: "This field is required",
                  validate: {
                    content: (value) =>
                      /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z]).*$/.test(value) ||
                      "Password must contain at least one number, symbol, lowercase and uppercase letter",
                    length: (value) => /^.{8,12}$/.test(value) || "Password must be between 8 and 12 characters long",
                  },
                  // pattern: {
                  //   value: /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z]).*$/,
                  //   message: "Should contain at least one each of numbers, symbols, lowercase and uppercase letters",
                  // },
                  // pattern: {
                  //   value: /^.{8,12}$/,
                  //   message: "Password should be between 8 - 12 characters",
                  // },
                }}
                // requiredMessage="This field is required"
                register={register}
                label="Password"
                id="password1"
                name="password1"
                className="form-control-lg form-control"
              ></Input>
              <Input
                errors={errors}
                required
                requiredMessage={{
                  required: "This field is required",
                  validate: (value) => value === watch("password1") || "Passwords do not match",
                }}
                register={register}
                type="password"
                label="Verify Password"
                id="password2"
                name="password2"
                className="form-control-lg form-control"
              ></Input>
            </div>
            <div className="form-group">
              <Button color="primary" size="lg" className="btn-block">
                {submitButtonLoading ? <Spinner /> : "Reset Password"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Return to login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default ResetPassword;

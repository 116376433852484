import React from "react";
import Content from "../../../../../../layout/content/Content";

const VerifyCompliance = ({ data }) => {
  console.log(data);
  return (
    <React.Fragment>
      <Content>
        <div className="nk-block-head">
          <h5 className="title">Interactions</h5>
        </div>
        {!data.intelligence.steps && (
          <div className="nk-block-head">
            <div className="card card-bordered card-stretch">
              <h6 style={{ textAlign: "center", margin: "10px" }}>No Interactions Collected</h6>
            </div>
          </div>
        )}
        {data.intelligence.steps && (
          <>
            <div className="card card-bordered card-stretch pb-2">
              <div className="card-inner-group">
                <div className="card-inner p-0">
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        color: "#8094ae",
                        margin: "0px 0px 0px 20px",
                      }}
                    ></span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        color: "#8094ae",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      Coordinates
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        color: "#8094ae",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      IP Address
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        color: "#8094ae",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      ASN Name
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        color: "#8094ae",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      Device
                    </span>
                  </div>

                  {data.intelligence.steps &&
                    data.intelligence.steps.map((step, index) => {
                      return (
                        <div key={index} style={{ display: "flex" }}>
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#8094ae",
                            }}
                          >
                            Step {index + 1}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#526484",
                              width: "20%",
                            }}
                            id="mobile-number"
                          >
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${step.location.device.lat},${step.location.device.long}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`${step.location.device.lat}, ${step.location.device.long}`}
                            </a>
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#526484",
                              width: "20%",
                            }}
                            id="mobile-number"
                          >
                            {step.ipv4 ? step.ipv4 : ""}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#526484",
                              width: "20%",
                            }}
                            id="mobile-number"
                          >
                            {step.asn_name ? step.asn_name : ""}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#526484",
                              width: "20%",
                            }}
                            id="mobile-number"
                          >
                            {step.device.device.vendor +
                              ", " +
                              step.device.device.model +
                              ", " +
                              step.device.device.type}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {/* <div className="nk-block-head">
              <h5 className="title">Mobile</h5>
            </div> */}
            {/* <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner p-0">
                  <div className="nk-tb-list nk-tb-ulist">
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        Mobile #
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="mobile-number"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.role_id ? getBusinessRoleFromId(data.business.role_id): ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        Caller ID
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="caller-id"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        Carrier
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="carrier"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        Geo Coordinates
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="geocoordinates"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        City
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="city"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        State
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="state"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        Country
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="country"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="profile-ud wider">
                      <span
                        style={{
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "50%",
                        }}
                        className="profile-ud-label"
                      >
                        Flags
                      </span>

                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 20px 0px 0px",
                          width: "50%",
                        }}
                        id="flags-mobile"
                        className="profile-ud-value"
                      >
                        {data.business && data.business.name ? data.business.name: ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}

        {/* <div className="nk-block-head">
          <h5 className="title">Internet Service Provider (ISP)</h5>
        </div> */}
        {/* <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    IP Address
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="ip-address"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.role_id ? getBusinessRoleFromId(data.business.role_id): ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Device
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="device"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    ASN
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="asn"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Geo Coordinates
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="geocoordinates"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    City
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="city"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    State
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="state"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Country
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="country"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Remarks
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="remarks"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Flags
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="flags-isp"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="nk-block-head">
          <h5 className="title">Social Networks</h5>
        </div>
        <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    LinkedIn
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="mobile-number"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.role_id ? getBusinessRoleFromId(data.business.role_id): ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Facebook
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="caller-id"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Twitter
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="carrier"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Instagram
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="geocoordinates"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Remarks
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="country"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Flags
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="flags-mobile"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.name ? data.business.name: ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="nk-block-head">
          <h5 className="title">Look Ups</h5>
        </div> */}
        {/* <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Equifax
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="look-up-equifax"
                    className="profile-ud-value"
                  >
                    {data.business && data.business.role_id ? getBusinessRoleFromId(data.business.role_id): ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="nk-block-head">
          <h5 className="title">Credit File</h5>
        </div> */}
        {/* <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="nk-tb-list nk-tb-ulist">
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Remarks
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="credit-file-remarks"
                    className="profile-ud-value"
                  ></span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Disclaimer
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 20px 0px 0px",
                      width: "50%",
                    }}
                    id="credit-file-disclaimer"
                    className="profile-ud-value"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Content>
    </React.Fragment>
  );
};

export default VerifyCompliance;

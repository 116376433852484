import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Block, Icon, Input, PreviewCard } from "../../components/Component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Alert, Button, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../utils/Api";
import { ToastContainer, toast } from "react-toastify";
import Content from "../../layout/content/Content";
import ThirdPartyPdf from "./ThirdPartyPdf";

const CodeVerification = () => {
  const [errorVal, setError] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const share_uuid = params.smartVerifyUuid;
  const [validLink, setValidLink] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [showCodeField, setShowCodeField] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [data, setData] = useState();
  const [buttonLoadin, setButtonLoading] = useState();

  let request_id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    checkLink();
  }, []);

  const checkLink = async () => {
    await apiRequest(`/client/solution/smart-verify/${share_uuid}/share/check`, "post").then((resp) => {
      if (resp.code === 200) {
        setValidLink(true);
        setShowEmailField(true);
      } else {
        setError(resp.message);
        setValidLink(false);
        // toast.error(resp.message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
      }
    });
  };

  const checkEmail = async () => {
    const email = getValues("email");
    setButtonLoading(true);
    console.log(email);
    await apiRequest(`/client/solution/smart-verify/${share_uuid}/share/check/verify-third-email`, "post", {
      share_uuid,
      email,
    }).then((resp) => {
      console.log(resp);
      if (resp.code === 200) {
        setShowEmailField(false);
        setShowCodeField(true);
        setButtonLoading(false);
        toast.success("Email with code has been sent", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        // setError(resp.message);
        setButtonLoading(false);
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const checkCode = async () => {
    console.log("checkCodeeeeeeeeeeeeeeeeeeeeeeeeee");
    const code = getValues("code");
    const email = getValues("email");
    setButtonLoading(true);
    await apiRequest(`/client/solution/smart-verify/${share_uuid}/share/check/verify-third-code`, "post", {
      share_uuid,
      email,
      code,
    }).then((resp) => {
      if (resp.code === 200) {
        // request_id = resp.data.request_id;
        console.log("responseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
        console.log(resp.data);
        console.log(request_id);
        console.log(share_uuid);
        setData(resp.data);
        setButtonLoading(false);
        // if (validLink) {
        //   navigate(`/share/svr/${share_uuid}/third-party/report`, { state: resp.data.result });
        // }
        setShowCodeField(false);
        // navigate(`/download-pdf/${resp.data.request_id}`, { state: resp.data.result });
        // setShowCodeField(false);
        // getPdf();
        setShowPdf(true);
      } else {
        setButtonLoading(false);
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const onFormSubmit = async () => {
    if (showEmailField) {
      checkEmail();
    } else if (showCodeField) {
      checkCode();
    }
  };

  // const getPdf = async () => {
  //   console.log("get pdf");
  //   await apiRequest(`/customer/a/pdf/${request_id}`, "get").then(async (response) => {
  //     setData(response.data);
  //   });
  // };

  return (
    <>
      <Head title="Share PDF" />
      {!data && (
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <div className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </div>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            {!validLink && (
              <div className="center text-danger">
                <span>{errorVal}</span>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              {showEmailField && (
                <>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="email">
                        Email address <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        tabIndex={1}
                        register={register}
                        required
                        requiredMessage="This field is required"
                        placeholder="Enter your email address"
                        className="form-control-lg form-control"
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </div>
                  <Button disabled={buttonLoadin} size="lg" className="btn-block" color="primary" type="submit">
                    {buttonLoadin ? <Spinner size="sm" /> : "Next"}
                  </Button>
                </>
              )}
              {showCodeField && (
                <>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="code">
                        Verification Code <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <Input
                        type="text"
                        id="code"
                        name="code"
                        tabIndex={2}
                        register={register}
                        required
                        requiredMessage="This field is required"
                        placeholder="Enter your 6 digit code"
                        className="form-control-lg form-control"
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </div>
                  <Button disabled={buttonLoadin} size="lg" className="btn-block" color="primary" type="submit">
                    {buttonLoadin ? <Spinner size="sm" /> : "Verify Code"}
                  </Button>
                </>
              )}

              {/* <div className="form-group">
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {errorVal}
                  </Alert>
                </div>
              )}
              <Button size="lg" className="btn-block" type="submit" color="primary" tabIndex={3}>
                {loading ? <Spinner size="sm" color="light" /> : "Verify"}
              </Button>
            </div> */}
            </Form>
          </PreviewCard>
          <ToastContainer />
        </Block>
      )}

      {data && (
        <Block>
          <Content>
            <ThirdPartyPdf data={data} />
          </Content>
        </Block>
      )}
    </>
  );
};

export default CodeVerification;

import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Block, Icon, BlockAthenty } from "../../../components/Component";
import { Link } from "react-router-dom";
import { Badge, Card } from "reactstrap";
import { apiRequest } from "../../../utils/Api";
import moment from "moment/moment";
import { toast } from "react-toastify";

const Notifications = () => {
  const [data, setData] = useState();

  const getNotifications = async () => {
    try {
      await apiRequest(`/customer/a/notifications/all`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp.data);
          setData(resp.data);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const notificationSetRead = async (id) => {
    try {
      await apiRequest(`/customer/a/notifications/${id}`, "PUT").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp.data);
          setData(resp.data);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      {data && (
        <Content>
          <BlockAthenty head={"Notifications"} title={`All Notifications`} />
          <Block>
            <Card className="card-bordered">
              <div className="card-inner">
                {/* {data.map((item) => {
                  return (
                    <Link to={item.link1_url}>
                      <Card key={item} className="card-bordered mb-1">
                        <div key={item} className="card-inner">
                          <div className="">
                            <div className="ticket-msg-from">
                              <div className="ticket-msg-user user-card">
                                <div className="nk-notification-icon">
                                  <Icon name={item.icon} className="icon-circle" />
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{item.description}</span>
                                </div>
                              </div>
                              <div className="ticket-msg-date">
                                <span className="sub-text">{moment(item.created_at).fromNow()}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  );
                })} */}
                {data &&
                  data.map((item, index) => {
                    return (
                      <li key={index} className={`sp-pdl-item ${!item.read && "bg-light"}`}>
                        <div className="sp-pdl-desc">
                          <div className="sp-pdl-img">
                            <Icon name={item.icon} className="icon-circle" />
                          </div>
                          <div className="sp-pdl-info">
                            <h6 className="sp-pdl-title ">
                              <span className="sp-pdl-name">{item.description}</span>{" "}
                              {!item.read && (
                                <Badge color="primary" className="badge-dim rounded-pill">
                                  New
                                </Badge>
                              )}
                            </h6>
                            <div className="sp-pdl-meta">
                              <span className="release">
                                <span className="text-soft"> </span> <span>{moment(item.created_at).fromNow()}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="sp-pdl-btn" onClick={() => notificationSetRead(item.id)}>
                          <Link to={item.link1_url} className="btn btn-primary">
                            View
                          </Link>
                        </div>
                      </li>
                    );
                  })}
              </div>
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default Notifications;

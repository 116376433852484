import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Block, Button, DocumentScan } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Input } from "reactstrap";

const CreditOcr = () => {
  const [text, setText] = useState();
  const [image, setImage] = useState();
  const [counter, setCounter] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onFormSubmit = async () => {
    // console.log(getValues())
    var startTime = performance.now();
    var data = new FormData();
    data.append("image1", getValues("document1")[0], getValues("document1")[0].name);
    // console.log(data)
    await apiRequest(`/ocr/ocr-credit`, "POST", data, "file").then((resp) => {
      console.log(JSON.parse(resp));
      // setText(JSON.parse(resp).data.response);
      setImage(JSON.parse(resp).data)
      var endTime = performance.now();
      setCounter((endTime - startTime) / 1000);
    });
  };

  const onFormSubmit2 = async () => {
    var startTime = performance.now();
    console.log(getValues());
    console.log("2");
    // console.log(data)
    await apiRequest(`/ocr/ocr`, "POST", getValues()).then((resp) => {
      console.log(resp);
      setText(resp.data);
      var endTime = performance.now();
      setCounter((endTime - startTime) / 1000);
    });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      {
        <Content>
          {counter && <div>{counter}</div>}
          {/* <form className="is-alter" onSubmit={handleSubmit(onFormSubmit2)}>
            <div className="form-group">
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  tabIndex={1}
                  {...register("name", { required: "This field is required" })}
                  placeholder="Enter your email address"
                  className="form-control-lg form-control"
                />
                {errors.name && <span className="invalid">{errors.name.message}</span>}
          
                <Button>Submita</Button>
              </div>
            </div>
          </form> */}
          <Block>
            <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <DocumentScan
                    setValue={setValue}
                    height={400}
                    value={watch("document1")}
                    requiredMessage="This field is required"
                    required
                    errors={errors}
                    capture="environment"
                    title="Image"
                    id="1"
                    register={register}
                    name="document1"
                  // onChange={setValue}
                  />
                  <Button>Submita</Button>
                </div>
              </div>
            </form>
          </Block>
          <Block>
            {text &&
              text.map((item) => {
                return (
                  <div>
                    {item.Type.Text} : {item.ValueDetection.Text}
                  </div>
                );
              })}
            {/* {text &&
              text.map((item) => {
                
              })} */}

          </Block>
          <div>test</div>
          {image && <img
            width={500}
            src={image}
            alt="Red dot"
          />}
        </Content>
      }
    </React.Fragment>
  );
};

export default CreditOcr;

import React, { useEffect, useRef, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { ClientListTable, Icon, PreviewCard } from "../../../../../components/Component";
import { Block, BlockAthenty, Loading } from "../../../../../components/Component";
import { apiRequest } from "../../../../../utils/Api";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PaymentMethods = () => {
  const [methodData, setMethodData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getMethodList();
  }, []);

  const getMethodList = async () => {
    setLoading(true);
    const methodList = await apiRequest("/customer/a/method/list", "GET");
    setMethodData(methodList.data);
    setLoading(false);
  };

  const methodEdit = (id) => {
    navigate(`/customer/admin/billing/payment-methods/edit/${id}`);
  };

  const methodDefault = async (id) => {
    // console.log(id)
    setLoading(true);
    try {
      await apiRequest(`/customer/a/method/default/${id}`, "post").then(async (resp) => {
        // console.log('======resp=======')
        if (resp.code === 200) {
          await apiRequest("/customer/a/method/list", "GET").then(async (resp) => {
            if (resp.code === 200) {
              setLoading(false);
              getMethodList().then((resp) => {
                toast.success("Default payment method changed !", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          });
        } else {
          // console.log(resp);
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const methodDelete = async (id) => {
    setLoading(true);
    // console.log(id);
    try {
      await apiRequest(`/customer/a/method/delete/${id}`, "delete").then(async (resp) => {
        if (resp.code === 200) {
          toast.success("Default payment method changed !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          getMethodList();
        } else {
          // console.log(resp);
          setLoading(false);
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const paymentMethodColumns = [
    {
      name: "Type",
      selector: (row) => row.paycardtype,
      cell: (row) => (
        <div>
          <img
            style={{ height: "50px" }}
            className="m-1"
            alt={row.paycardtype}
            // src="/images/creditCards/visas.png"
            // src = {row.paycardtype === "VISA card" && "/images/creditCards/"}
            src={
              row.paycardtype === "VISA card"
                ? "/images/creditCards/visa.png"
                : row.paycardtype === "MasterCard"
                ? "/images/creditCards/MasterCard.png"
                : row.paycardtype === "Discover card"
                ? "/images/creditCards/discover.png"
                : row.paycardtype === "American Express card"
                ? "/images/creditCards/AmericanExpresscard.png"
                : row.paycardtype === "Diner's Club/Carte Blanche"
                ? "/images/creditCards/diners.png"
                : row.paycardtype === "enRoute"
                ? "/images/creditCards/.png"
                : row.paycardtype === "JCB"
                ? "/images/creditCards/JCB.png"
                : row.paycardtype === "BankCard"
                ? "/images/creditCards/.png"
                : row.paycardtype === "Switch"
                ? "/images/creditCards/.png"
                : row.paycardtype === "Solo"
                ? "/images/creditCards/.png"
                : ""
            }
          ></img>
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.payname,
      hide: 370,
      grow: 2,
    },
    {
      name: "Number",
      selector: (row) => row.paymask,
      hide: "sm",
      grow: 2,
    },
    {
      name: "Expiry",
      selector: (row) => row.paydate,
      hide: "sm",
    },
    {
      name: "",
      selector: (row) => row.weight === "1" && "Default",
      hide: "md",
    },

    {
      name: "",
      grow: 0,
      selector: (row) => row.name,
      compact: true,
      cell: (row) => (
        <div className="row">
          <div className="col-6 ">
            <UncontrolledDropdown className="ps-5">
              <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
                <Icon name="more-v"></Icon>
              </DropdownToggle>
              <DropdownMenu>
                <ul className="link-list-opt no-bdr ">
                  {row.weight !== "1" && (
                    <li onClick={() => methodDefault(row.custpaybynum)}>
                      <a href="#default" onClick={(e) => e.preventDefault()}>
                        <Icon name="check-circle"></Icon>
                        <span>Default</span>
                      </a>
                    </li>
                  )}
                  {/* <li onClick={() => methodEdit(row.custpaybynum)}>
                  <a
                    href="#edit"
                    onClick={(e)=> e.preventDefault()}
                  >
                    <Icon name="pen"></Icon>
                    <span>Edit</span>
                  </a>
                </li> */}
                  <li onClick={() => methodDelete(row.custpaybynum)}>
                    <a href="#delete" onClick={(e) => e.preventDefault()}>
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </a>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Payment Methods"}
            nav="Admin / Billing"
            title={"Payment Methods"}
            description={"View all your payment methods."}
            buttons={[
              {
                // linkTo : '/customer/admin/billing/payment-methods/add',
                text: "",
                icon: "plus",
                linkTo: "/customer/admin/billing/payment-methods/add",
                tooltip: "Add Payment Method",
                id: "add-payment",
                className: "btn-icon",
              },
            ]}
          />
          <Block size="lg">
            {methodData && (
              <PreviewCard>
                <ClientListTable data={methodData} columns={paymentMethodColumns} pagination className="nk-tb-list" />
              </PreviewCard>
            )}
          </Block>
        </Content>
      )}
      <ToastContainer autoClose={2000} />
    </React.Fragment>
  );
};

export default PaymentMethods;

import { Row, Col } from "reactstrap";
import { Block, Icon, Input } from "../../Component";
import useFilePreview from "../filePreview/FilePreview";
const DocumentScan = ({
  title,
  height,
  buttonText,
  value,
  capture,
  setValue,
  accept,
  name,
  required,
  register = function () {},
  errors,
  requiredMessage,
  id,
  view,
  trans,
  pri,
  sec,
  ter,
  showButtun = true,
}) => {
  const [filePreview] = useFilePreview(value);
  return (
    <div id={"document_scan_" + id} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
      <Row>
        <h6 id={"document_title_" + id}>
          {title} {required && <span className="text-danger">*</span>}
        </h6>
      </Row>
      <Row style={{ margin: "auto", textAlign: "center" }}>
        <Col
          className="mt-2"
          style={{
            verticalAlign: "center",
            display: "inline-block",
            margin: "auto",
            padding: "5px",
          }}
        >
          {showButtun && (
            <label className="btn btn-block btn-primary">
              <Icon name="camera"></Icon> &nbsp; {buttonText ? buttonText : "Scan Document"}
              <Input
                maxNumberOfFiles={0}
                errors={errors}
                required
                requiredMessage={requiredMessage}
                register={register}
                // onChange={setValue}
                capture={capture}
                type="file"
                accept="image/*"
                id={id}
                name={name}
                className="form-control-lg form-control"
                display="none"
              ></Input>
            </label>
          )}
          <Block>
            <div className="pt-1">
              {filePreview ? (
                <img
                  style={{ height: height ? `${height}px` : "150px" }}
                  className="pt-1 img-thumbnail rounded"
                  src={filePreview}
                  alt="preview"
                />
              ) : null}
            </div>
          </Block>
        </Col>
        {view && (
          <Col className="center d-none d-md-block">
            <div style={{ float: "right" }} className="col-md-7 d-none d-sm-block">
              <div id="image_id_front_1" className="mx-md-4">
                <svg id="svg_id_front_1" xmlns="http://www.w3.org/2000/svg" viewBox={view}>
                  <g id="transform_id_front_1" transform={trans}>
                    <path id="primary_id_front_1" style={{ fill: "#4ba7bc" }} d={pri}></path>
                    <path id="secondary_id_front_1" style={{ fill: "#3175ae" }} d={sec}></path>
                    <path id="tertiary_id_front_1" style={{ fill: "#55b4c1" }} d={ter}></path>
                  </g>
                </svg>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DocumentScan;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { apiRequest } from "../../utils/Api";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onFormSubmit = async (formData) => {
    setError("");
    // console.log(formData.email.split('@'))
    try {
      setLoading(true);
      const fetchResponse = await apiRequest("/customer/signup", "POST", {
        signup_full_name: formData.full_name,
        signup_company: formData.company_name,
        signup_email: formData.email,
      });
      // console.log(fetchResponse);
      if (fetchResponse.code === 200) {
        // console.log(fetchResponse.message)
        navigate("/auth-success", {
          state: {
            text: "A confirmation email with an accoutn setup link has been sent.",
            buttonText: "Confirm Your Email",
            emailVerify: `https://${formData.email.split("@")[1]}`,
          },
        });
        // localStorage.setItem("token", fetchResponse.message);
        // navigate('/customer')
        // setLoading(false);
      } else {
        console.log("error");
        setError(fetchResponse.message);
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Head title="Register" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Sign Up</BlockTitle>
              <BlockDes>
                <p>Transact and protect, safely and securely.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Full Name<span className="text-danger">*</span>
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="name"
                  {...register("full_name", {
                    required: "This field is required",
                    pattern: {
                      value: /^([\w]{3,})+\s+([\w\s]{3,})+$/i,
                      message: "Please input your full name",
                    },
                  })}
                  placeholder="Enter your full name"
                  className="form-control-lg form-control"
                />
                {errors.full_name && <p className="invalid">{errors.full_name.message}</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  {/* Company Name<span className="text-danger">*</span> */}
                  Name of Organization<span className="text-danger">*</span>
                  <TooltipComponent
                    containerClassName="btn btn-trigger btn-icon"
                    icon="info-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="name-of-organization"
                    text="This will help in setting up your organization or adding you to your existing one."
                  />
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  bssize="lg"
                  id="default-01"
                  {...register("company_name", { required: true })}
                  className="form-control-lg form-control"
                  placeholder="Enter your company name"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Email<span className="text-danger">*</span>
                </label>
                {errors.email && <span className="invalid">{errors.chek1.message}</span>}
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  bssize="lg"
                  id="default-01"
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm,
                      message: "Please input correct email address",
                    },
                  })}
                  className="form-control-lg form-control"
                  placeholder="Enter your email address"
                />
                {errors.email && <p className="invalid">{errors.email.message}</p>}
              </div>
            </div>
            {/* <ul className="list list-sm list-checked text-start">
			        <li>People who use our service may have authenticated your contact information through Athenty. <a style={{color :'#55b4c1'}} href="learnmorepageathenty">Learn more</a>.
			        </li>
			        <li>By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy. You may receive SMS notifications from us and can opt out any time.
			        </li>
		        </ul> */}
            <Row className="gy-4">
              <Col xl="12">
                <div className="preview-block">
                  <div className="g-3 align-center flex-wrap">
                    <div className="g">
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check1"
                          {...register("check1", { required: "You Need To Accept" })}
                        />
                        {errors.check1 && <span className="invalid">{errors.check1.message}</span>}
                        <label className="custom-control-label" htmlFor="check1">
                          People who use our service may have authenticated your contact information through Athenty.{" "}
                          <a style={{ color: "#55b4c1" }} href="learnmorepageathenty">
                            Learn more
                          </a>
                          .
                        </label>
                        {errors.check1 && <p className="invalid">This field is required</p>}
                      </div>
                    </div>
                    <div className="g"></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="gy-4">
              <Col xl="12">
                <div className="preview-block">
                  <div className="g-3 align-center flex-wrap">
                    <div className="g">
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check2"
                          {...register("check2", { required: "You Need To Accept" })}
                        />
                        {errors.check2 && <span className="invalid">{errors.check2.message}</span>}
                        <label className="custom-control-label" htmlFor="check2">
                          By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy. You may
                          receive SMS Notifications from us and can opt out any time.
                        </label>
                        {errors.check2 && <p className="invalid">This field is required</p>}
                      </div>
                    </div>
                    <div className="g"></div>
                  </div>
                </div>
              </Col>
            </Row>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <div className="form-group">
              <Button type="submit" color="primary" size="lg" className="btn-block">
                {/* {loading ? <Spinner size="sm" color="light" /> : "Register"} */}
                {loading ? <Spinner size="sm" color="light" /> : "Sign up"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            {" "}
            Already have an account?{" "}
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
    </>
  );
};
export default Register;

import React from "react";
import { Link } from "react-router-dom";
import packageJson from "../../../package.json";

const Footer = () => {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="d-flex ">
            <div className="nk-footer-copyright"> &copy; Copyright {new Date().getFullYear()}. Athenty Inc. </div>
            <div className="small ps-1" style={{ color: "#a6b4c6", alignSelf: "center" }}>
              {" "}
              (v{packageJson.version})
            </div>
          </div>
          <div className="nk-footer-links text-primary">
            <ul className="nav nav-sm">
              <li className="nav-item ">
                <Link to={"https://www.athenty.com/legal/terms"} target="_blank" className="text-primary nav-link">
                  Terms
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"https://www.athenty.com/legal/privacy"} target="_blank" className="text-primary nav-link">
                  Privacy
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"https://www.athenty.com/legal/cookie"} target="_blank" className="text-primary nav-link">
                  Cookies
                </Link>
              </li>
              {/* <li className="nav-item">
              <Link to={`${process.env.PUBLIC_URL}/pages/terms-policy`} className="text-primary nav-link">
                Disclaimer
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`${process.env.PUBLIC_URL}/pages/terms-policy`} className="text-primary nav-link">
                Refund
              </Link>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

import PlanS1 from "../../images/icons/plan-s1.svg";
import PlanS2 from "../../images/icons/plan-s2.svg";
import PlanS3 from "../../images/icons/plan-s3.svg";

const pricingTableData = [
  {
    id: 1,
    title: "Free",
    logo: PlanS1,
    desc: "If you just want to send ID verifications",
    amount_year: 0,
    amount_month: 0,
    userNumber: "Max 5 Agents",
    tags: false,
    icon: "plan-1",
    agentsNum: 5,
    package_id: 18,
    package_service: 16,
  },
  {
    id: 2,
    title: "Starter",
    logo: PlanS2,
    desc: (
      <div>
        <div>10 Agents</div>
        <br />
      </div>
    ),
    amount_year: 480,
    amount_month: 50,
    userNumber: "10 Agents",
    tags: true,
    icon: "plan-2",
    agentsNum: 10,
    package_id: 21,
    package_service: 16,
  },
  {
    id: 3,
    title: "Pro",
    logo: PlanS3,
    desc: (
      <div>
        <div>If you want the kitchen sink</div>
        <br />
      </div>
    ),
    amount_year: 960,
    amount_month: 90,
    userNumber: "10 Agents",
    tags: false,
    icon: "plan-3",
    agentsNum: 10,
    package_id: 22,
    package_service: 16,
  },
];

export default pricingTableData;

import React, { useEffect, useState } from "react";
import Content from "../../../../../../layout/content/Content";
import Head from "../../../../../../layout/head/Head";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon, Loading } from "../../../../../../components/Component";
import { useForm } from "react-hook-form";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../../../components/Component";
import { apiRequest } from "../../../../../../utils/Api";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router";
import { dateConverter } from "../../../../../../utils/Utils";
import moment from "moment";

const ClientNote = (props) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const params = useParams();
  console.log(params);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  // const [verifyData, setVerifyData] = useState(
  //   props.data.sort(function (a, b) {
  //     return -(a.id - b.id || a.name.localeCompare(b.name));
  //   }),
  // );

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = async () => {
    try {
      setLoading(true);
      await apiRequest(`/customer/a/client/${params.request_id}/note`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const onDelete = async (id) => {
    try {
      setLoading(true);
      console.log(id);
      await apiRequest(`/customer/a/client/${id}/note`, "PUT").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          setLoading(false);
          getNotes();
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const submitForm = async () => {
    console.log(getValues());
    setLoading(true);
    try {
      setLoading(true);
      await apiRequest(`/customer/a/client/${params.request_id}/note`, "POST", getValues()).then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          toggleModal();
          reset()
          getNotes();
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    // let highestId = Math.max.apply(
    //   null,
    //   verifyData.map(function (o) {
    //     return o.id;
    //   }),
    // );
    // toggleModal();
    // var verifyDataCopy = verifyData;
    // verifyDataCopy.push({
    //   id: highestId + 1,
    //   title: formData.title,
    //   text: formData.text,
    //   date: "October 4, 2022 at 5:30 AM",
    //   by: "Carter Robinson",
    // });
    // setVerifyData(verifyDataCopy);
  };

  // console.log(verifyData)
  return (
    <React.Fragment>
      <Content>
        <Head title="Client Details"></Head>
        <BlockHead size="md">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Notes</BlockTitle>
              <BlockDes>
                <p>Internal notes shared with members of your organization.</p>
              </BlockDes>
            </BlockHeadContent>
            <div onClick={toggleModal} className="nk-block-head nk-block-head-sm nk-block-between">
              <span>
                <a
                  href="/#"
                  type="button"
                  id="cancel_note"
                  style={{ display: "none" }}
                  className="cancel_note btn btn-dim btn-sm btn-secondary"
                >
                  <i className="ni ni-cross"></i>Cancel
                </a>
                <a
                  href="/#"
                  type="button"
                  id="save_note"
                  style={{ display: "none" }}
                  className="text-white btn btn-sm btn-primary"
                >
                  <i className="ni ni-save"></i>Save
                </a>
                <div type="button" id="add_note" className="cancel_note text-white btn btn-primary">
                  <i className="ni ni-plus"></i> Add Note
                </div>
              </span>
            </div>
          </BlockBetween>
        </BlockHead>
        {loading && <Loading />}
        {!loading && (
          <div id="notes">
            <div className="nk-block">
              <div id="note_section" className="mt-2 bq-note">
                {data && data.length > 0
                  ? data.map((note, index) => {
                    return (
                      <div key={index} id="note_1" className="bq-note-item">
                        <div className="bq-note-text">
                          <h6>{note.subject}</h6>
                          <p>{note.note}</p>
                        </div>
                        <div className="bq-note-meta">
                          <span className="bq-note-added">
                            Added on <span className="date">{moment(note.created_at).fromNow()}</span>
                          </span>
                          <span className="bq-note-sep sep">|</span>
                          <span className="bq-note-by">
                            By <span>{note.created_by}</span>
                          </span>
                          <button
                            type="button"
                            id="delete_1"
                            defaultValue="1"
                            className="delete_note link link-sm link-danger"
                            onClick={() => onDelete(note.id)}
                          >
                            Delete Note
                          </button>
                        </div>
                      </div>
                    );
                  })
                  : "No Notes"}
              </div>
            </div>
          </div>
        )}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader
            toggle={toggleModal}
            close={
              <button className="close" onClick={toggleModal}>
                <Icon name="cross" />
              </button>
            }
          >
            CONTACT US
          </ModalHeader>
          <ModalBody>
            <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Subject<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="client_name_last"
                    {...register("subject", { required: true })}
                    className="form-control-l form-control"
                  />
                  {errors.title && <p className="invalid">This field is required</p>}
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Note<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-control-wrap">
                  <textarea
                    id="text"
                    name="request_reason"
                    type="text"
                    className="  form-control form-control-lg "
                    {...register("text", { required: true })}
                  ></textarea>
                  {errors.text && <p className="invalid">This field is required</p>}
                </div>
              </div>

              <div className="center mt-2">
                <Button onClick={toggleModal} className="center btn border me-2 m-1 closer" style={{ width: "90px" }}>
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </Content>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ClientNote;

import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import AWS from 'aws-sdk';
import { Col, Container, Row } from 'react-bootstrap';
// Configure AWS SDK
AWS.config.update({
    region: 'us-east-1',
    accessKeyId: process.env.AWS_ACCESS_KEY,
    secretAccessKey: process.env.AWS_SECRET_KEY
});

const rekognition = new AWS.Rekognition();

const VideoStream = () => {
    const webcamRef = useRef(null);
    const [results, setResults] = useState(null);

    const captureAndAnalyze = async () => {
        const screenshot = webcamRef.current.getScreenshot();

        if (screenshot) {
            // Decode base64 image
            const base64Image = screenshot.split(',')[1];
            const binaryImg = atob(base64Image);
            const length = binaryImg.length;
            const imageBytes = new Uint8Array(new ArrayBuffer(length));

            for (let i = 0; i < length; i++) {
                imageBytes[i] = binaryImg.charCodeAt(i);
            }

            const params = {
                Image: {
                    Bytes: imageBytes
                },
                MaxLabels: 10  // Adjust based on the number of labels you want to detect
            };

            try {
                const response = await rekognition.detectLabels(params).promise();
                console.log(response)
                setResults(response.Labels);
            } catch (err) {
                console.error('Error analyzing image:', err);
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            captureAndAnalyze();
        }, 1000); // Capture and analyze every second

        return () => clearInterval(interval);
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <div style={{ position: 'relative' }}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            style={{ width: '100%', height: 'auto' }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80%', // Adjust width as needed
                                height: '50%', // Adjust height as needed to maintain the aspect ratio
                                border: '2px solid red',
                                boxSizing: 'border-box',
                                pointerEvents: 'none' // Allows clicks to pass through to the webcam
                            }}
                        />
                    </div>
                </Col>
            </Row>
            {results && (
                <Row>
                    <Col>
                        <div>
                            <h3>Detected Objects:</h3>
                            <ul>
                                {results.map((label, index) => (
                                    <li key={index}>{label.Name} - {Math.round(label.Confidence)}%</li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default VideoStream;
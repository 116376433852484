import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Block, BlockContent, Button } from "../../components/Component";

const Error401Classic = ({ route }) => {
  const { state } = useLocation();
  // console.log(state)
  return (
    <>
      <Block className="nk-block-middle wide-xs mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <h1 className="nk-error-head">401</h1>
          <h3 className="nk-error-title">Oops! Why you’re here?</h3>
          <p className="nk-error-text">
            {state.message
              ? state.message
              : "We are very sorry for inconvenience. It looks like you’re try to access a page that either has been deleted            or never existed."}
          </p>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <Button color="primary" size="lg" className="mt-2">
              Back To Home
            </Button>
          </Link>
        </BlockContent>
      </Block>
    </>
  );
};
export default Error401Classic;

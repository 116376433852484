import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { apiRequest } from "../../../../utils/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const notificationRead = async (id) => {
  try {
    await apiRequest(`/customer/a/notifications/${id}`, "PUT").then(async (resp) => {
      console.log(resp);
      if (resp.code === 200) {
        console.log(resp.data);
      } else {
        toast.error(resp.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  } catch (error) {
    console.log(error);
    toast.error(error, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

const NotificationItem = (props) => {
  const { icon, iconStyle, text, time, id, link } = props;
  // console.log(props);
  if (props.text && props.text === "No new notification") {
    return (
      <div className="nk-notification-item" key={id} id={id}>
        {icon && (
          <div className="nk-notification-icon">
            <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
          </div>
        )}
        <div className="nk-notification-content">
          <div className="nk-notification-text">{text}</div>
          <div className="nk-notification-time">{time}</div>
        </div>
      </div>
    );
  }
  return (
    <Link to={link}>
      <div
        className="nk-notification-item"
        key={id}
        id={id}
        onClick={() => {
          props.setDropdownOpen(!props.dropdownOpen);
          props.getNotifications();
          notificationRead(id);
        }}
      >
        {icon && (
          <div className="nk-notification-icon">
            <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
          </div>
        )}
        <div className="nk-notification-content">
          <div className="nk-notification-text">{text}</div>
          <div className="nk-notification-time">{time}</div>
        </div>
      </div>
    </Link>
  );
};

const Notification = () => {
  const [data, setData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const getNotifications = async () => {
    try {
      await apiRequest(`/customer/a/notifications/new`, "GET").then(async (resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.data);
          setData(resp.data);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const NotificationSetReadAll = async () => {
    try {
      await apiRequest(`/customer/a/notifications/all`, "PUT").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.data);
          getNotifications();
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <UncontrolledDropdown className="user-dropdown" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        {data && data.length ? (
          <div className="icon-status icon-status-info">
            <Icon name="bell" />
          </div>
        ) : (
          <Icon name="bell" />
        )}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          {data && data.length > 0 && (
            <a
              href="#markasread"
              onClick={(ev) => {
                ev.preventDefault();
                NotificationSetReadAll();
              }}
            >
              Mark All as Read
            </a>
          )}
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {((data && data.length === 0) || !data) && <NotificationItem text="No new notification" />}
            {data &&
              data.map((item) => {
                return (
                  <NotificationItem
                    getNotifications={getNotifications}
                    setDropdownOpen={setDropdownOpen}
                    dropdownOpen={dropdownOpen}
                    key={item.id}
                    id={item.id}
                    icon={item.icon}
                    // iconStyle={item.iconStyle}
                    text={item.description}
                    time={item.time}
                    link={item.link1_url}
                  />
                );
              })}
          </div>
        </div>
        <div className="dropdown-foot center">
          <Link
            to="/customer/notifications"
            onClick={() => {
              setDropdownOpen(false);
            }}
          >
            View Notification history
          </Link>
          {/* <a href="/customer/notifications" onClick={(ev) => ev.preventDefault()}>
            View All
          </a> */}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Notification;

import React from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link, useLocation } from "react-router-dom";
import {Button} from "../../components/Component";
import { Route } from "react-router-dom";
const Success = () => {
  const  state = useLocation().state
  // console.log(state)
  return (
    <>
      <Head title="Success" />
        <Block className="nk-block-middle nk-auth-body text-center">
          <div className="brand-logo pb-5">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Sign Up Confirmation</BlockTitle>
              <BlockDes className="text-success">
                <p>{state.text}</p>
                <Link to={state.emailVerify ? state.emailVerify : `${process.env.PUBLIC_URL}/${state.buttonTo}`}>
                  {state.buttonText &&<Button color="primary" size="lg">
                    {state.buttonText}
                  </Button>}
                </Link>
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
    </>
  );
};
export default Success;

import React, { useState } from "react";
import { Block, BlockHead, BlockHeadContent } from "../Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row, Spinner } from "reactstrap";
const Loading = () => {
  return (
    <Block className="nk-block-middle nk-auth-body  wide-xl">
      <Card className="">
        <CardBody className="card-inner text-center">
          <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          <CardTitle tag="h5">
            <Spinner></Spinner>
          </CardTitle>
        </CardBody>
      </Card>
      {/* <Block size="lg">
        <BlockHead>
          <BlockHeadContent>
            <Row>
              <Col>
                <Row>
                  <div className="brand-logo pb-4 text-center">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={Logo}
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={LogoDark}
                    alt="logo-dark"
                  />
                </div>
                </Row>
                <Row>
                  <div className="text-center">
                  <Spinner></Spinner>
                </div>
                </Row>
                
                
              </Col>
            </Row>
          </BlockHeadContent>
        </BlockHead>
      </Block> */}
    </Block>
  );
};

export default Loading;

import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockAthenty, Col, Icon, Input, Loading, Row } from "../../components/Component";
import { Button, Card, Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { svgData } from "../components/crafted-icons/NioIconData";
import introCard from "../data/home";
import { useForm } from "react-hook-form";
import RSelect2 from "../../components/select/ReactSelect2";
import { apiRequest } from "../../utils/Api";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../../layout/Index";

const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0];

const CustomerDashboard = (props) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(UserContext)
  // console.log(userData);
  const toggleModal = () => {
    // getUserDetail();
    modal && reset();
    setModal(!modal);
  };
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("customer_name", userData.name);
    setValue("customer_email", userData.email);
  }, []);

  const supportTypeOptions = [
    { value: "smart_verify", label: "Smart Verify Login Issues" },
    { value: "unrecognized", label: "Unrecognized Activity" },
    { value: "changing_personal", label: "Changing Personal Information" },
    { value: "feedback", label: "Feedback" },
    { value: "feature", label: "Feature Request" },
    { value: "changing_type", label: "Changes to Organization" },
  ];

  const onFormSubmit = async () => {
    console.log("submit");
    setLoading(true);
    try {
      await apiRequest("/customer/a/support", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setLoading(false);
          toggleModal();
          toast.success("You message has been sent", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const onselect = (name, value) => {
    // console.log(name);
    // console.log(value);
    // console.log(errors);
    if (name === "is_business" && value === "n") {
      reset();
    }
    if (name === "country") {
      setValue("region", "");
    }
    if (name === "residence_country") {
      setValue("residence_region", "");
    }
    if (getValues(name)) {
      delete errors[name];
      // console.log(errors);
    }
  };

  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockAthenty head={"Dashboard"} nav="Menu" title={"Dashboard"} size="lg" />
        <Block>
          <Row className="g-gs">
            {introCard.map((item, index) => {
              const icon = svgData.filter((icon) => icon.slug === item.icon)[0];
              return (
                <Col md="6" key={index}>
                  <Card className="card-bordered card-full">
                    <div className="nk-wg1">
                      <div className="nk-wg1-block">
                        <div className="nk-wg1-img">{icon.svg}</div>
                        <div className="nk-wg1-text">
                          <h5 className="title">{item.title}</h5>
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <div className="nk-wg1-action">
                        <Link to={item.linkTo} className="link">
                          <span>{item.linkText}</span> <Icon name="chevron-right"></Icon>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Block>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner">
              <div className="nk-help">
                <div className="nk-help-img">{helpSupportIcon.svg}</div>
                <div className="nk-help-text">
                  <h5>We’re here to help you!</h5>
                  <p className="text-soft">
                    Ask a question, suggest an improvement, file a support ticket or report an issue. Our support team
                    will get back to you.
                  </p>
                </div>
                <div className="nk-help-action ">
                  <button onClick={() => toggleModal()} className="btn btn-lg btn-outline-primary">
                    Get Support Now
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </Block>

        {userData && (
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader
              toggle={toggleModal}
              close={
                <button className="close" onClick={toggleModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              CONTACT US
            </ModalHeader>
            {loading && <Loading />}

            {!loading && userData && (
              <ModalBody>
                <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="form-group">
                    <Input
                      disabled
                      type="text"
                      label="Your Name"
                      value={watch("customer_name")}
                      id="customer_name"
                      className="form-control-lg form-control"
                      name="customer_name"
                      required
                      requiredMessage="This field is required"
                      errors={errors}
                      register={register}
                    />
                    <Input
                      disabled
                      type="text"
                      label="Email"
                      id="customer_email"
                      className="form-control-lg form-control"
                      name="customer_email"
                      required
                      value={watch("customer_email")}
                      requiredMessage="This field is required"
                      errors={errors}
                      register={register}
                    />
                    <RSelect2
                      onselect={onselect}
                      setValue={setValue}
                      selectedTitle={watch("type")}
                      label="Type of support"
                      requiredMessage="This field is required"
                      errors={errors}
                      register={register}
                      options={supportTypeOptions}
                      name="type"
                      color="#f5f5f5"
                      id="type"
                      className="form-control-lg p-0 border-0 form-control"
                    />
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Message<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <textarea
                        id="request_reason"
                        name="request_reason"
                        type="text"
                        className="  form-control form-control-lg "
                        {...register("request_reason", { required: true })}
                      ></textarea>
                      {errors.request_reason && <p className="invalid">This field is required</p>}
                    </div>
                  </div>
                  <div className="center mt-2">
                    <button
                      onClick={toggleModal}
                      className={`${loading ? "disabled" : ""} center btn border me-2 m-1 closer`}
                      style={{ width: "90px" }}
                    >
                      Cancel
                    </button>
                    <Button color="primary" size="" className="" outline="">
                      {!loading ? "Send" : <Spinner />}
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            )}
          </Modal>
        )}
      </Content>
      <ToastContainer />
    </React.Fragment>
  );
};

export default CustomerDashboard;

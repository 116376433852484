import React, { useState } from "react";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, Loading } from "../Component";
import { Button, Spinner, Tooltip } from "reactstrap";
import { Link, useNavigate, useHistory } from "react-router-dom";
import Head from "../../layout/head/Head";
import { capitalizeWords } from "../../utils/Utils";

export const BlockAthenty = ({
  className,
  size,
  head,
  nav,
  title,
  description,
  back,
  buttons,
  id,
  extra,
  extraButtons,
  newTab,
  loading
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const navigate = useNavigate();
  const toggle = (id) => {
    setTooltipOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  // console.log(loading);
  return (
    <React.Fragment>
      <BlockHead size={size ? { size } : "lg"}>
        {head && <Head title={head} />}
        {nav && (
          <div className="nk-block-head-sub">
            <span>{nav}</span>
          </div>
        )}

        <BlockBetween size="md" className="g-4">
          <BlockHeadContent>
            {title && (
              <BlockTitle tag="h2" className="fw-normal">
                {capitalizeWords(title)}
              </BlockTitle>
            )}
            {description && (
              <BlockDes>
                <p className="lead">{description}</p>
              </BlockDes>
            )}
          </BlockHeadContent>
          <BlockHeadContent>
            <ul className="nk-block-tools gx-3">
              {buttons &&
                buttons.map((button, index) => {
                  return (
                    !button.hidden && (
                      <div key={index}>
                        <div onMouseEnter={() => toggle(button.id)} onMouseLeave={() => toggle(button.id)}>
                          <li className="nk-block-tools-opt">
                            <Button
                              onClick={
                                button.onclick
                                  ? button.onclick
                                  : button.linkTo
                                    ? () =>
                                      button.newTab ? window.open(button.linkTo, "_blank") : navigate(button.linkTo)
                                    : ""
                              }
                              icon={button.icon}
                              color={button.color ? button.color : "primary"}
                              id={"Tooltip-" + button.id}
                              className={!button.text ? "btn-icon" : ""}
                              disabled={button.disabled}
                              onMouseEnter={() => toggle(button.id)}
                              onMouseLeave={() => toggle(button.id)}
                            >
                              {!loading ? (
                                <>
                                  {button.icon && <Icon name={button.icon} className={button.text ? "pe-2" : ""} />}
                                  {button.text}
                                </>
                              ) : (
                                <Spinner size={'sm'} />
                              )}

                            </Button>
                          </li>
                          {button.tooltip && (
                            <Tooltip
                              isOpen={tooltipOpen[button.id]}
                              target={"Tooltip-" + button.id}
                              toggle={() => toggle(button.id)}
                            >
                              {button.tooltip}
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              {back && (
                <Button onClick={() => navigate(-1)} color="light" outline className="bg-white d-none d-sm-inline-flex">
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              )}
            </ul>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className="mb-3">
        <ul className="list-inline ">
          {extra &&
            extra.map((item, index) => {
              return (
                <li key={index}>
                  {item.type === "badge" ? (
                    <div>
                      {item.title}
                      {item.title && ": "}
                      <span
                        className={`text-${item.textColor || "success"} badge badge-dim badge-${item.size || "sm"
                          } bg-outline-${item.outline || "success"}`}
                      >
                        {item.value}
                      </span>
                    </div>
                  ) : (
                    <div>
                      {item.title}
                      {item.title && ": "} <span>{item.value}</span>
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
        <ul className="nk-block-tools">
          {extraButtons &&
            extraButtons.map((button, index) => {
              return (
                !button.hidden && (
                  <div key={index}>
                    <div onMouseEnter={() => toggle(button.id)} onMouseLeave={() => toggle(button.id)}>
                      <li className="nk-block-tools-opt ps-1">
                        <Button
                          onClick={button.onclick ? button.onclick : button.linkTo ? () => navigate(button.linkTo) : ""}
                          icon={button.icon}
                          color={button.color}
                          id={"Tooltip-" + button.id}
                          className={!button.text ? "btn-icon btn-sm" : "btn-sm"}
                          disabled={button.disabled}
                          outline={button.outline}
                        >
                          {button.icon && <Icon name={button.icon} className={button.text ? "pe-2" : ""} />}
                          {button.text}
                        </Button>
                      </li>
                      {button.tooltip && (
                        <Tooltip
                          isOpen={tooltipOpen[button.id]}
                          target={"Tooltip-" + button.id}
                          toggle={() => toggle(button.id)}
                        >
                          {button.tooltip}
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )
              );
            })}
        </ul>
      </div>
      {/*--------------------- dropdown -----------------*/}
      {/* <li style={{ float: "right" }}>
        <div className="dropdown">
          <button
            href=""
            className="btn dropdown-toggle "
            style={{ width: "20px", paddingLeft: "0px" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <em className="icon ni ni-more-v"></em>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px 0px auto auto",
              margin: "0px",
              transform: "translate(-16px, 57px)",
            }}
            data-popper-placement="bottom-end"
          >
            <ul className="link-list-opt no-bdr">
              <li>
                <a href="mailto:email@example.com">
                  <em className="icon ni ni-mail"></em>
                  <span>Send Email</span>
                </a>
              </li>

              <li>
                <a href="/customer/menu/client-list/verify">
                  <em className="icon ni ni-check-circle"></em>
                  <span>Send Verify</span>
                </a>
              </li>

              <li>
                <a href="/customer/menu/client-list/delete">
                  <em className="icon ni ni-trash"></em>
                  <span>Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li> */}
    </React.Fragment>
  );
};

export default BlockAthenty;

import React from "react";
import Content from "../../../../../layout/content/Content";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../../utils/Api";
import { Block, PreviewCard, ClientListTable, BlockAthenty, Loading } from "../../../../../components/Component";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useParams } from "react-router-dom";
// table imports
import { UserAvatar, Icon } from "../../../../../components/Component";
import { capitalizeWords, dateConverter, findUpper } from "../../../../../utils/Utils";
import { Badge } from "reactstrap";
import MainTable from "../../../../components/athenty/table/Main.Table";
import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";

const MatterClientList = (props) => {
  const [tableData, setTableData] = useState();
  const [loadin, setLoading] = useState(false);
  const [data, setData] = useState();
  const params = useParams();
  const location = useLocation();
  console.log(location)
  const matter_id = location.state?.matter_id;

  useEffect(() => {
    // getClientList();

  }, []);

  const getClientList = async () => {
    try {
      await apiRequest(`/customer/a/matter/${params.matter_id}`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          setData(resp);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  };

  // table ------------------------------------------------------
  const tableCols = [
    {
      name: "Client /Client ID",
      selector: (row) => row.name,
      compact: true,
      grow: 6,

      cell: (row) => (
        <Link to={`/customer/menu/client-list/${row.uuid}`}>
          <div className="user-card position-relative">
            <div className="user-info">
              <span className="tb-lead">{capitalizeWords(row.name_first + " " + row.name_last)} </span>
              <span>{row.external_id}</span>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
    },
    // {
    //   name: "Client",
    //   selector: (row) => row.name,
    //   compact: true,
    //   grow: 6,

    //   cell: (row) => (
    //     <Link to={`/customer/menu/client-list/${row.id}`}>
    //       <div className="user-card position-relative">
    //         <UserAvatar theme={row.avatarBg} text={findUpper(row.name.replace(" (R)"))} image={row.facial} className="">
    //           {/* <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
    //           <div className={`status dot dot-lg ${row.status === "attestation" ? "dot-success" : "dot-warning"} `}>
    //             {/* <Icon name="shield-check" /> */}
    //           </div>
    //         </UserAvatar>
    //         <div className="user-info">
    //           <span className="tb-lead">{row.name} </span>
    //           <span>{row.external_id}</span>
    //         </div>
    //       </div>
    //     </Link>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Contact",
      selector: (row) => row.location,
      grow: 5,
      cell: (row) => (
        <div className="user-card">
          <div className="user-info">
            <span className="tb-lead"></span>
            <span>
              <Icon name="call-fill" className={"pe-1"} />
              <a href={`tel:${row.phone}`}>{parsePhoneNumber("+1" + row.phone).formatNational()}</a>
              <br />
              <Icon name="mail" className={"pe-1"} />
              <a href={`mailto://${row.email}`}>{row.email}</a>
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "IDV Requests",
      center: true,
      selector: (row) => row.request_count,
      grow: 2,
      hide: "lg",
      cell: (row) => (
        <div
          className="nk-menu-icon"
          data-toggle="tooltip"
        // title={row.status.description.charAt(0).toUpperCase() + row.status.description.slice(1)}
        >
          <Badge pill color="gray">
            {row.request_count}
          </Badge>
        </div>
      ),
    },
    {
      name: "Last Verified",
      selector: (row) => row.created_at,
      grow: 4.5,
      sortable: true,
      cell: (row) => (
        <div className="">
          {row.created_at && (
            <span>
              {" "}
              {new Date(row.created_at).toLocaleDateString("default", { weekday: "long" }) +
                " " +
                new Date(row.created_at).toLocaleDateString("default", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
            </span>
          )}
          {row.last_verified && (
            <>
              <span className="tb-lead">
                {dateConverter(row.last_verified, true)[0]} <span className={"dot"}></span>
              </span>
              <span>{dateConverter(row.last_verified, true)[1]}</span>
            </>


          )}
          {!row.last_verified && <span>Never Verified</span>}
        </div>
      ),
    },
  ];

  // display ---------------------------------------------------------------------
  return (
    <React.Fragment>
      {loadin && <Loading />}
      {!loadin && (
        <Content page="component">
          <BlockAthenty
            head={"Matter Client List"}
            nav="Menu / Matter List"
            title={"Matter Client List"}
            description={<>Client list based on MATTER ID: {matter_id}</>}
            back={"http://localhost:3000/customer/menu/matter-list"}
            className={'text-danger'}
          />

          <Block>
            {/* {tableData && (
              <PreviewCard>
                <ClientListTable
                  data={tableData}
                  columns={tableCols}
                  pagination
                  className="nk-tb-list"
                  selectableRows={true}
                />
              </PreviewCard>
            )} */}
            {/* ----------------------new table--------------------------------- */}
            <MainTable columns={tableCols} route={`/customer/a/matter/${params.matter_id}`} compact />
            {/* <MainTable columns={tableCols} data={data} compact /> */}
          </Block>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};
export default MatterClientList;

import React, { useState } from "react";

const DocRadio2 = ({ onclick,register=function(){}, onchange, label, id, checked, name, data, view, trans, value, disabled, onDoctypeChange }) => {
  

  return (
    <React.Fragment>
    <li className="nk-kycfm-control-item col-sm-6">
        <div className={disabled ? "bg-light" : ""}>
            <input className="nk-kycfm-control at_document_selected_1 at-document-selected-passport" 
              disabled={disabled}
              defaultChecked={checked}
              onChange={onchange}
              onClick={()=> onclick(name,value)}
              name={name}
              {...register(name)}
              type="radio" 
              id={id} 
              value={value}
            ></input>
            <label className={"nk-kycfm-label"} htmlFor={id} style={{borderRadius:disabled ? "4px" : '', border: disabled ? "2px solid #8c8c8c" : ''}}>
            <span className="nk-kycfm-label-icon">
                <span className="label-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={view}>
                    <path id={"icon_radio_"+id} d={data} transform={trans} style={{fill:disabled ? "#bdbdbd" : '#4ba7bc'}}></path>
                </svg>
                </span>
            </span>
           
            <span id={label+'_'+id} style={{color:disabled ? "#b4b7b8" : ''}} className={"nk-kycfm-label-text"}>{label}</span>
            </label>
        </div>
    </li>
    </React.Fragment>
  );
};

export default DocRadio2;

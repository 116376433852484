import React, { useState } from "react";

const InputCheckbox = ({ label, onChange, id, checked, required }) => {
  const [inputCheck, setCheck] = useState(checked ? true : false);

  return (
    <React.Fragment>
      <div style={{ paddingLeft: "45px", paddingBottom: "10px" }} className="custom-control-group ">
        <div className="custom-control custom-checkbox">
          <input
            required={required}
            onChange={onChange}
            type="checkbox"
            className="form-control custom-control-input"
            defaultChecked={inputCheck}
            checked={checked}
            onClick={() => setCheck(!inputCheck)}
            id={id}
            name={id}
          />
          <label className="custom-control-label" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
      <br></br>
    </React.Fragment>
  );
};

export default InputCheckbox;

import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Block, Icon, Input, InputCheckbox, Loading, UserAvatar } from "../../../../../../components/Component";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../../../../utils/Api";
import { useNavigate } from "react-router-dom";
import RSelect2 from "../../../../../../components/select/ReactSelect2";
const packageList = [
  {
    id: 1,
    name: "free",
    title: "Free (Identification)",
    sub: "Self Verify & Authenticate",
    price: 0,
  },
  {
    id: 2,
    name: "Basic",
    title: "Basic (Verification)",
    sub: "A.I. Verification",
    price: 7,
    disabled: true,
  },
  {
    id: 3,
    name: "Enhanced",
    title: "Enhanced (Authentication)",
    sub: "A.I. Verification & Authentication",
    price: 15,
    disabled: true,
  },
];

const VerifyAddNew = (props) => {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState("");
  const [featuresModal, setFeaturesModal] = useState(false);
  const [tierData, setTierData] = useState("free");
  const [matterList, setMatterList] = useState();
  const [activeTier, setActiveTier] = useState(packageList[0]);
  const [loading, setLoading] = useState();
  const toggleFeaturesModal = () => setFeaturesModal(!featuresModal);
  const navigate = useNavigate();

  const toggleModal = () => setModal(!modal);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    watch,
    setValue
  } = useForm();

  useEffect(() => {
    getMatterListShort()
  }, [])

  const checkboxChange = (e) => {
    setTierData(e.name);
    var result = packageList.filter((obj) => {
      return obj.name === e.name;
    });
    setActiveTier(result[0]);
  };

  const openModal = (formData) => {
    // console.log(formData);
    setFormData(formData);
    toggleModal();
  };

  const submitForm = async () => {
    console.log(getValues())

    await apiRequest("/customer/a/solution/smart-verify/", "POST", getValues()).then((resp) => {
      console.log(resp)
      if (resp.code === 200) {
        // getMatterListShort()
        navigate("/customer/solutions/verify", { state: "clientAdded" });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const getMatterListShort = async () => {
    await apiRequest("/customer/a//matter/matter_short", "GET",).then((resp) => {
      setLoading(true)

      if (resp.code === 200) {
        // navigate("/customer/solutions/verify", { state: "clientAdded" });
        setMatterList(resp.data)
        console.log(resp.data)
        setLoading(false)
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const checkExistingClient = async () => {
    setLoading(true);
    await apiRequest("/customer/a/solution/smart-verify/check-existing", "POST", getValues()).then((resp) => {
      console.log(resp);
      if (resp.code === 200) {
        if (resp.data) {
          setError("matter", { type: "custom", message: "Matter id exist" });
        } else {
          toggleModal();
        }
        setLoading(false);
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  //   const onInputChange = (e, location) => {
  //     if (location === "location") {
  //       let formDataCopy = { ...formData };
  //       console.log(formDataCopy);
  //       formDataCopy.location[e.target.name] = e.target.value;
  //       setFormData(formDataCopy);
  //       return;
  //     }
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   };

  const onselect = (name, value) => {
    if (name === "matter") {
      console.log(matterList)
      console.log(matterList.filter(obj => obj.value === value))
      setValue("matter", isNaN(value) ? value : matterList.filter(obj => obj.value === value)[0].label);
      console.log(getValues('matter'))
    }
    if (getValues(name)) {
      delete errors[name];
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && matterList && (
        <Block>
          {/* <form id='verify-form' className="content clearfix" onSubmit={handleSubmit(submitForm)}> */}
          <form id="verify-form" className=" is-alter content clearfix" onSubmit={handleSubmit(toggleModal)}>
            <Container>
              <Row>
                <Row>
                  {/* {packageList.map((p, index) => {
                  return (
                    <Col xl="4" sm="6" xs="12" className="mb-2">
                      <Card key={p.index} className={`mb-2 p-2 card-bordered ${p.disabled && "disabled"}`}>
                        <div className="project-title">
                          <div onChange={(e) => checkboxChange(e.target)}>
                            <InputCheckbox checked={tierData === p.name} id={p.name}></InputCheckbox>
                          </div>
                          <UserAvatar text={"$" + p.price} className="sq" />
                          <div className="project-info">
                            <h6 className="title">{p.title}</h6>
                            <div className="project-info">
                              <span className="">{p.sub}</span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                })} */}
                  <Col xl="4" sm="6" xs="12" className="mb-2">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <Input
                          errors={errors}
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,40}$/,
                              message: "Max 40 characters",
                            },
                          }}
                          register={register}
                          type="text"
                          label="Client First Name"
                          id="client_name_first"
                          name="client_name_first"
                          className="form-control-l form-control"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" sm="6" xs="12" className="mb-2">
                    <div className="form-control-wrap">
                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^.{1,40}$/,
                            message: "Max 40 characters",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Client Last Name"
                        id="client_name_last"
                        name="client_name_last"
                        className="form-control-l form-control"
                      />
                    </div>
                  </Col>
                  <Col xl="4" sm="6" xs="12" className="mb-2">
                    <div className="form-control-wrap">
                      <Input
                        errors={errors}
                        register={register}
                        type="text"
                        label="Client ID"
                        id="client_id"
                        name="client_id"
                        className="form-control-l form-control"
                        requiredMessage={{
                          pattern: {
                            value: /^.{1,80}$/,
                            message: "Max 80 characters",
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6" sm="6" xs="12" className="mb-2">
                    <div className="form-control-wrap">
                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter valid email address",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Client Email"
                        id="client_email"
                        name="client_email"
                        className="form-control-l form-control"
                      />
                    </div>
                  </Col>
                  <Col xl="6" sm="6" xs="12" className="mb-2">
                    <div className="form-control-wrap">
                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^(?:\+?1[-.\s]?)?\(?[2-9][0-8][0-9]\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                            message: "Please input valid phone number",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Client Phone Number"
                        id="client_phone"
                        name="client_phone"
                        className="form-control-l form-control"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" sm="6" xs="12" className="mb-2">
                    {/* <Row className="form-control-wrap">
                      <Input
                        errors={errors}
                        required
                        requiredMessage={{
                          required: "This field is required",
                          pattern: {
                            value: /^.{1,30}$/,
                            message: "Max 30 characters",
                          },
                        }}
                        register={register}
                        type="text"
                        label="Matter ID"
                        id="matter"
                        name="matter"
                        className="form-control-l form-control"
                      />
                    </Row> */}
                    <Row>
                      <RSelect2
                        onselect={onselect}
                        setValue={setValue}
                        // selectedCountry={watch("region")}
                        label="Matter"
                        register={register}
                        requiredMessage="This field is required"
                        id="matter"
                        name="matter"
                        errors={errors}
                        createable={true}
                        options={matterList}
                        placeholder="Select or type new matter"
                        className="form-control-lg p-0 border-0 form-control"
                      />
                    </Row>
                  </Col>
                  <Col xl="8" sm="6" xs="12" className="mb-2">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Matter Description or Reason<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <textarea
                        id="request_reason"
                        name="request_reason"
                        type="text"
                        className="  form-control form-control-lg "
                        {...register("request_reason", {
                          required: "This field is required",
                          pattern: {
                            value: /^.{1,128}$/,
                            message: "Max 128 characters",
                          },
                        })}
                      ></textarea>
                      {errors.request_reason && <p className="invalid">{errors.request_reason.message}</p>}
                    </div>
                  </Col>
                </Row>

                <div className="card-inner-group">
                  {/* <div className="sp-plan-head">
                      <h5 className="mt-2 mb-4 text-center title">Request Details</h5>
                    </div> */}

                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>
                  <div className="form-group"></div>

                  {/* <div className=" mt-1">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Matter Description<span className="text-danger">*</span>
                        </label>
                      </div>
                     
                    </div>
                  </div> */}
                  <div className="mt-2 text-muted small">
                    Please make sure the request details are correct and accurate, you cannot change them once sent, and
                    there are no refunds.
                  </div>
                </div>
              </Row>
            </Container>
            <Row></Row>
            {/* <button ref={props.submitRef} type="submit" id='add-new-verify' style={{ display: 'none' }} /> */}

            <button ref={props.submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
          </form>
        </Block>
      )}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader
          toggle={toggleModal}
          close={
            <button className="close" onClick={toggleModal}>
              <Icon name="cross" />
            </button>
          }
        >
          Confirm Purchase
        </ModalHeader>
        <ModalBody>
          <Card className="card-bordered">
            <div className="card-inner">
              <div className="sp-plan-head">
                <h5 className="mt-2 text-center title">Request Details</h5>
              </div>
            </div>
            <hr style={{ margin: "0 15px" }}></hr>
            <div className="pricing-body m-2">
              <ul className="pricing-features">
                <li>
                  <span className="w-50">
                    <div>{activeTier && activeTier.name}</div>
                    <div className="form-note">{activeTier && activeTier.sub}</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && activeTier.price}</span>
                </li>
                <hr></hr>
                <li>
                  <span className="w-50">
                    <div>Subtotal</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && activeTier.price}</span>
                </li>
                <li>
                  <span className="w-50">
                    <div>Tax</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && (activeTier.price * 0.1).toFixed(2)}</span>
                </li>
                <li>
                  <span className="w-50">
                    <div>Processing Fees</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && (activeTier.price * 0.01).toFixed(2)}</span>
                </li>
                <hr></hr>
                <hr style={{ marginTop: "-12px" }}></hr>
                <li>
                  <span className="w-50">
                    <div className="lead-text">Total</div>
                  </span>
                  <span className="ms-auto lead-text">
                    ${activeTier && (activeTier.price + activeTier.price * 0.01 + activeTier.price * 0.1).toFixed(2)}
                  </span>
                </li>
              </ul>
            </div>
          </Card>

          <p className="mt-2">
            <b>NOTE:</b> Please make sure the request details are accurate, you cannot change them once sent and there
            are no refunds. Your primary payment method will be charged the total amount.
          </p>
          <div className="center mt-2">
            <button onClick={toggleModal} className="center btn border me-2 m-1 closer" style={{ width: "90px" }}>
              Cancel
            </button>
            <button form="verify-form" className="btn btn-primary " onClick={() => submitForm()}>
              Purchase
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default VerifyAddNew;

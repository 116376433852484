import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import Head from "../../../../../../layout/head/Head";
import Content from "../../../../../../layout/content/Content";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Input,
} from "../../../../../../components/Component";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../../../../utils/Api";
import { UserContext } from "../../../../../../layout/Index";

const UserProfileRegularPage = ({ submitRef }) => {
  const [loading, setLoading] = useState();
  const [userInfo, setUserInfo] = useState("");
  const [formData, setFormData] = useState("");
  const { getUserDetail } = useContext(UserContext);
  // const [userInfo, setUserInfo] = useState({
  //   firstName: "Abu",
  //   lastName: "Ishtiak",
  //   title: "Partner",
  //   email: "abu@email.com",
  //   workNumber: "123456789",
  //   mobileNumber: "123456789",
  //   role: "Lawyer",
  // });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  const getUserInfo = async () => {
    setLoading(true);
    const userInfo = await apiRequest("/customer/a/profile", "GET");
    console.log(userInfo);
    setUserInfo(userInfo.data);
    for (const key in userInfo.data) {
      setValue(key, userInfo.data[key]);
    }
    console.log("values");
    console.log(getValues());
    // console.log(userInfo);
    setLoading(false);
  };

  useEffect(() => {
    getUserInfo(userInfo.data);
  }, []);

  // const submitForm = async (formData) => {
  //   // console.log(formData);
  //   setUserInfo(formData);
  //   console.log(userInfo);
  //   await apiRequest("/customer/a/profile/edit", "PUT", userInfo).then((resp) => {
  //     console.log(userInfo);
  //     if (resp && resp.code === 200) {
  //       toast.success("Your information is saved", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //       });
  //     } else {
  //       toast.error("Something went wrong", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //       });
  //     }
  //   });
  // };

  const submitForm = async (formData) => {
    try {
      const updatedUserInfo = { ...userInfo, ...formData };
      setUserInfo(updatedUserInfo);
      var data = new FormData();
      console.log(getValues())
      data.append('email', getValues('email'))
      data.append('name_first', getValues('name_first'))
      data.append('name_last', getValues('name_last'))
      data.append('phone_mobile', getValues('phone_mobile'))
      data.append('role', getValues('role'))
      data.append('title', getValues('title'))
      data.append('avatar', getValues('file'))
      let resp = await apiRequest("/customer/a/profile/edit", "PUT", data, "file");
      resp = JSON.parse(resp)
      if (resp && resp.code === 200) {
        await getUserDetail()
        toast.success("Your information is saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        console.log(resp)
        toast.error(resp.message || "Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const [files, setFiles] = useState([]);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="md">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>Some of your information below may be restricted based on your organization's security settings.</p>
              {/* <p>[Description to be added here]</p> */}
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Content>
        <form className="is-alter" onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="col">
              <label htmlFor="avatar" className="form-label">
                Profile Image
              </label>
              <span className="form-note">Image that will appear on verification requests</span>
            </div>
            <div className="col">
              <div className="form-control-wrap">
                <div className="input-group ">
                  <div className="input-group">
                    {/* {watch('avatar')} */}
                    {/* <input type="file" className="form-control" style={{minHeight: '38px'}} aria-describedby="basic-addon2" onChange={(e)=> setFiles(e.target.files[0].name)}
                />
                <div className="input-group-append">
                  <Button className="btn-dim" outline color="primary">Upload</Button>
                </div> */}
                    <input
                      {...register("file")}
                      style={{ height: "38px" }}
                      type="text"
                      className="form-control-lg form-control "
                      value={watch("file") && watch("file").name}
                      placeholder="Choose file"
                      aria-describedby="fileHelp"
                      readOnly
                    />
                    <label className="input-group-append mb-0">
                      <span
                        className="btn btn-primary input-file-btn btn-dim"
                        onChange={(e) => setValue("file", e.target.files[0])}
                      >
                        UPLOAD <input type="file" hidden />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <label htmlFor="first-name" className="form-label">
                First Name
              </label>
              <span className="form-note">Can only be changed by your admin</span>
            </div>
            <div className="col">
              <Input
                disabled
                id="first-name"
                type="text"
                placeholder={userInfo.name_first}
                className="form-control-lg form-control"
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <label htmlFor="last-name" className="form-label">
                Last Name
              </label>
              <span className="form-note">Can only be changed by your admin</span>
            </div>
            <div className="col">
              <Input
                disabled
                id="last-name"
                type="text"
                placeholder={userInfo.name_last}
                className="form-control-lg form-control"
              />
            </div>
          </div>

          {/* <div className="row mt-2">
            <div className="col">
              <label htmlFor="title" className="form-label">
                Title or Position
              </label>
              <span className="form-note">Can only be changed by your admin</span>
            </div>
            <div className="col">
              <Input
                disabled
                id="title"
                type="text"
                placeholder={userInfo.title}
                className="form-control-lg form-control"
              />
            </div>
          </div> */}

          <div className="row mt-2">
            <div className="col">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <span className="form-note">Can only be changed by your admin</span>
            </div>
            <div className="col">
              <Input
                disabled
                id="email"
                type="text"
                className="form-control-lg form-control"
                placeholder={userInfo.email}
              />
            </div>
          </div>

          {/* <div className="row mt-2">
            <div className="col">
              <label htmlFor="phone_work" className="form-label">
                Work Number
              </label>
              <span className="form-note">Can only be changed by your admin</span>
            </div>
            <div className="col">
              <Input
                disabled
                id="phone_work"
                type="text"
                className="form-control-lg form-control"
                placeholder={userInfo.phone_work}
              />
            </div>
          </div> */}

          <div className="row mt-2">
            <div className="col">
              <label htmlFor="phone_mobile" className="form-label">
                Mobile Number<span className="text-danger"> *</span>
              </label>
            </div>
            <div className="col">
              <Input
                id="phone_mobile"
                name="phone_mobile"
                type="text"
                register={register}
                className="form-control-lg form-control"
                // defaultValue={userInfo.phone_mobile}
                required
                requiredMessage="This field is required"
                errors={errors}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <label htmlFor="role" className="form-label">
                Role or Profession<span className="text-danger"> *</span>
              </label>
            </div>
            <div className="col">
              <Input
                id="role"
                type="text"
                name="role"
                register={register}
                className="form-control-lg form-control"
                // defaultValue={userInfo.role}
                required
                requiredMessage="This field is required"
                errors={errors}
              />
            </div>
          </div>

          <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
        </form>
        <ToastContainer />
      </Content>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;

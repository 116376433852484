import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, Loading, ClientListTable, PreviewAltCard } from "../../../../../components/Component";
import { Card } from "reactstrap";
// import { invoiceData } from "../../../../../components/table/TableData";
import { apiRequest } from "../../../../../utils/Api";
import { invoiceTableColumns } from "../../../../components/table/TableData";
import { toast } from "react-toastify";

const InvoiceHistory = () => {
  const [invoiceData, setInvoiceData] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getInvoiceList();
  }, []);

  const getInvoiceList = async () => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/invoice/list", "GET").then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setInvoiceData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            // head={"Invoices"}
            head={"Invoice History"}
            nav="Admin / Billing"
            title={"Invoice History"}
            description={"View and print your invoices."}
            // description={"View your invoices."}
          />
          <Block>
            <Card>
              {invoiceData && (
                <PreviewAltCard>
                  <ClientListTable data={invoiceData} columns={invoiceTableColumns} pagination className="nk-tb-list" />
                </PreviewAltCard>
              )}
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default InvoiceHistory;

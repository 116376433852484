import React, { useEffect, useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import countryList from "react-select-country-list";
import { regions } from "../../data/country";
import { Block, Input, Button, Textarea, Loading } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import { useForm } from "react-hook-form";
import RSelect2 from "../../../components/select/ReactSelect2";
import { Spinner } from "reactstrap";

const Location = ({ ...props }) => {
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState();

  const y_n_options = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    setLoading(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setValue('legal_address1', props.data.address_1)
    setValue('legal_address2', props.data.address_2)

    setValue('legal_country', props.data.country)
    setValue('legal_region', props.data.region)
    setValue('legal_city', props.data.city)
    setValue('legal_postal', props.data.postal)
    // setValue('request_id',JSON.parse(localStorage.getItem("at_requestor")).id)
  }, []);

  // const [load, setLoad] = useState(0);
  // if (load < 1) {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setLoad(2);
  // }

  const onselect = (name, value) => {
    // console.log(name);
    // console.log(value);
    // console.log(errors);
    if (name === "legal_country") {
      setValue("legal_region", "");
    }
    if (name === "residence_country") {
      setValue("residence_region", "");
    }
    if (getValues(name)) {
      delete errors[name];
      // console.log(errors);
    }
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async (e) => {
    // console.log("form submit");
    // console.log(getValues())
    setSubmitButtonLoading(true);
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    try {
      await apiRequest("/ccr/verify/step/4", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          props.jump(resp.data.step);
        } else {
          console.log("error response: ", resp.message);
          setSubmitButtonLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Location" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Location</h5>
              <p>Please provide your address associated with at least one of your ID documents.</p>

              <form className="content clearfix" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="gy-4 row">
                  <div className="entry">
                    <Row className="">
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          type="text"
                          label="Address Line 1"
                          id="legal_address1"
                          className="form-control-lg form-control"
                          name="legal_address1"
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          errors={errors}
                          register={register}
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          type="text"
                          label="Address Line 2"
                          id="legal_address2"
                          className="form-control-lg form-control"
                          name="legal_address2"
                          errors={errors}
                          register={register}
                          requiredMessage={{
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          defaultValue={watch("legal_country")}
                          label="Country"
                          register={register}
                          requiredMessage={{
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          value={countryOptions.find(country => country.value === watch('legal_country'))}
                          id="legal_country"
                          name="legal_country"
                          errors={errors}
                          options={countryOptions}
                          placeholder="Select country"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        {watch("legal_country") !== "CA" && watch("legal_country") !== "US" && (
                          <Input
                            type="text"
                            label="Region"
                            id="legal_region"
                            className="form-control-lg form-control"
                            name="legal_region"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                            value={watch('legal_region')}
                          />
                        )}
                        {(watch("legal_country") === "CA" || watch("legal_country") === "US") && (
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("legal_region")}
                            label="Region"
                            register={register}
                            requiredMessage="This field is required"
                            id="legal_region"
                            name="legal_region"
                            errors={errors}
                            options={regions[watch("legal_country")]}
                            placeholder="Select Region"
                            className="form-control-lg p-0 border-0 form-control"
                            value={regions[watch("legal_country")].find(region => region.value === watch('legal_region'))}
                          />
                        )}
                        {/* {watch("legal_country") === "CA" && (
                          <RSelect2
                            onselect={onselect}
                            setValue={setValue}
                            selectedCountry={watch("legal_region")}
                            label="Region"
                            register={register}
                            requiredMessage="This field is required"
                            id="legal_region"
                            name="legal_region"
                            errors={errors}
                            options={regions[watch("legal_country")]}
                            placeholder="Select Region"
                            className="form-control-lg p-0 border-0 form-control"
                          />
                        )} */}
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          type="text"
                          label="City"
                          id="legal_city"
                          className="form-control-lg form-control"
                          name="legal_city"
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          errors={errors}
                          register={register}
                        />
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          type="text"
                          label="Postal/Zip Code"
                          id="legal_postal"
                          className="form-control-lg form-control"
                          name="legal_postal"
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          errors={errors}
                          register={register}
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedCountry={watch("location_current")}
                          label="Is this your current location?"
                          register={register}
                          requiredMessage="This field is required"
                          id="location_current"
                          name="location_current"
                          errors={errors}
                          options={y_n_options}
                          placeholder="Select country"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>
                      <Col>
                        {watch("location_current") === "n" && (
                          // <Textarea
                          //   className="form-control-lg p-0  form-control"
                          //   required={true}
                          //   disabled={false}
                          //   register={register}
                          //   errors={errors}
                          //   type="text"
                          //   label="Please explain why you are not currently at this location"
                          //   id="location_current_explaination"
                          //   name="location_current_explaination"
                          // ></Textarea>
                          <Textarea
                            type="text"
                            className="form-control-lg form-control"
                            required
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,512}$/,
                                message: "Max 512 characters",
                              },
                            }}
                            errors={errors}
                            register={register}
                            label="Please explain why you are not currently at this location"
                            id="location_current_explaination"
                            name="location_current_explaination"
                          ></Textarea>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedCountry={watch("location_residence")}
                          label="Is this your primary residence?"
                          register={register}
                          requiredMessage="This field is required"
                          id="location_residence"
                          name="location_residence"
                          errors={errors}
                          options={y_n_options}
                          placeholder="Select country"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>
                    </Row>
                    {watch("location_residence") === "n" && (
                      <div className="mt-4">
                        <p className="mb-0">
                          Provide the address of your primary home where you live or designate for most of the year.
                        </p>

                        <Row className="pt-4">
                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            <Input
                              type="text"
                              label="Address Line 1"
                              id="residence_address1"
                              className="form-control-lg form-control"
                              name="residence_address1"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                            />
                          </Col>
                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            <Input
                              type="text"
                              label="Address Line 2"
                              id="residence_address2"
                              className="form-control-lg form-control"
                              name="residence_address2"
                              errors={errors}
                              requiredMessage={{
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              register={register}
                            />
                          </Col>

                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            <RSelect2
                              onselect={onselect}
                              setValue={setValue}
                              selectedCountry={watch("location_residence")}
                              register={register}
                              requiredMessage="This field is required"
                              errors={errors}
                              options={countryOptions}
                              placeholder="Select country"
                              label="Country"
                              id="residence_country"
                              name="residence_country"
                              className="form-control-lg p-0 border-0 form-control"
                            />
                          </Col>
                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            {watch("residence_country") !== "CA" && watch("residence_country") !== "US" && (
                              <Input
                                type="text"
                                className="form-control-lg form-control"
                                required
                                requiredMessage={{
                                  required: "This field is required",
                                  pattern: {
                                    value: /^.{1,20}$/,
                                    message: "Max 20 characters",
                                  },
                                }}
                                errors={errors}
                                register={register}
                                label="Region"
                                id="residence_region"
                                name="residence_region"
                              />
                            )}
                            {watch("residence_country") === "US" && (
                              <RSelect2
                                onselect={onselect}
                                setValue={setValue}
                                selectedCountry={watch("residence_region")}
                                register={register}
                                requiredMessage="This field is required"
                                errors={errors}
                                options={regions[watch("residence_country")]}
                                placeholder="Select Region"
                                className="form-control-lg p-0 border-0 form-control"
                                label="Region"
                                id="residence_region"
                                name="residence_region"
                              />
                            )}
                            {watch("residence_country") === "CA" && (
                              <RSelect2
                                onselect={onselect}
                                setValue={setValue}
                                selectedCountry={watch("residence_region")}
                                register={register}
                                requiredMessage="This field is required"
                                errors={errors}
                                options={regions[watch("residence_country")]}
                                placeholder="Select Region"
                                className="form-control-lg p-0 border-0 form-control"
                                label="Region"
                                id="residence_region"
                                name="residence_region"
                              />
                            )}
                          </Col>

                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            <Input
                              type="text"
                              className="form-control-lg form-control"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                              label="City"
                              id="residence_city"
                              name="residence_city"
                            />
                          </Col>
                          <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                            <Input
                              type="text"
                              className="form-control-lg form-control"
                              required
                              requiredMessage={{
                                required: "This field is required",
                                pattern: {
                                  value: /^.{1,20}$/,
                                  message: "Max 20 characters",
                                },
                              }}
                              errors={errors}
                              register={register}
                              label="Postal/Zip Code"
                              id="residence_postal"
                              name="residence_postal"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Row className="pt-4">
                      <span className="nk-kycfm-label-text">
                        <b>Tax Country</b>
                      </span>
                      <p className="mb-0">Primary country in which you are a resident for income tax purposes.</p>
                      <RSelect2
                        onselect={onselect}
                        setValue={setValue}
                        selectedCountry={watch("tax_country")}
                        register={register}
                        requiredMessage="This field is required"
                        errors={errors}
                        options={countryOptions}
                        placeholder="Select country"
                        className="form-control-lg p-0 border-0 form-control"
                        label="Country"
                        id="tax_country"
                        name="tax_country"
                      />
                    </Row>

                    <div className="mt-3 mb-5 nk-kycfm-note">
                      <em className=" icon ni ni-info-fill"></em>
                      <p>
                        We use your current location to verify that it matches your address on at least one of your ID
                        documents.
                      </p>
                    </div>

                    <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                      <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                        <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                          {" "}
                          <Button color="primary" size="" className="" outline="">
                            {submitButtonLoading && <Spinner size="sm" />} Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
              </form>
            </Block>
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default Location;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/Api";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { checkJWTExpiration } from "../../utils/Utils";
import { Button, Modal, ModalBody } from "reactstrap";

import LogoDark from "../../images/logo-dark.png";
import { Loading } from "../Component";

const DownloadPDF = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const location = useParams();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkTokenInterval = setInterval(async () => {
      if (!(await checkJWTExpiration())) {
        setModal(true);
        clearInterval(checkTokenInterval); // Stop the interval when the condition is met
      }
    }, 1000);
    return () => clearInterval(checkTokenInterval); // Cleanup on component unmount
  }, [modal]);

  useEffect(() => {
    !modal && getPdf();
  }, [modal]);

  const downloadPdf = (base64String) => {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64String}`;
    link.download = `ASV_${location.id}.pdf`; // Set the download filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getPdf = async () => {
    setLoading(true);
    console.log("get pdf");
    if (state) {
      downloadPdf(state);
      setLoading(false);
    } else {
      try {
        const response = await apiRequest(`/customer/a/pdf/${location.id}`, "get");
        if (response.data) {
          downloadPdf(response.data);
        } else {
          toast.error("Failed to load PDF data.");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        toast.error("Error fetching PDF.");
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <>
          <Head title={"Verification Report"} />
          <Content>
            {/* Data handling logic has moved to downloadPdf function */}
          </Content>
          <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
            <ModalBody className="text-center m-2">
              <img className="logo-dark logo-img logo-img-lg mb-5" src={LogoDark} alt="logo-dark" />
              <h5>Your Session Has Been Expired</h5>
              <div className="p-3 bg-light">
                <div className="text-center mb-2">Please login again</div>
                <Button onClick={() => navigate("/")}>Login</Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default DownloadPDF;

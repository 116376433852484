import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Block } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { IoMicCircleSharp, IoStopCircle, IoPlayCircleSharp } from "react-icons/io5";
import { Col, Row, Container } from "reactstrap";

const VoiceRec = ({ number, disabled, ...props }) => {
    const [recording, setRecording] = useState(false);
    const [recordedMessage, setRecordedMessage] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [playbackTime, setPlaybackTime] = useState(0); // New state for playback time
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState(null);
    const [audio, setAudio] = useState(null);
    const [audioDuration, setAudioDuration] = useState(0);

    useEffect(() => {
        let timer;
        if (recording) {
            timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [recording]);

    const formatTime = (seconds) => {
        const roundedSeconds = Math.floor(seconds); // Round to the nearest second
        const minutes = Math.floor(roundedSeconds / 60);
        const remainingSeconds = roundedSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        getValues,
    } = useForm();

    const handleStartRecording = async () => {
        try {
            setRecording(true);
            setRecordedMessage(false);
            setElapsedTime(0); // Reset timer when starting new recording

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);

            const audioChunks = [];
            recorder.ondataavailable = event => {
                audioChunks.push(event.data);
            };

            recorder.onstop = () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                console.log("Audio Blob:", audioBlob); // Debugging: log the audio blob
                const audioUrl = URL.createObjectURL(audioBlob);
                console.log("Audio URL:", audioUrl); // Debugging: log the audio URL
                setAudioURL(audioUrl);
                const newAudio = new Audio(audioUrl);
                newAudio.onloadedmetadata = () => {
                    setAudioDuration(newAudio.duration);
                    console.log("Audio Duration:", newAudio.duration); // Debugging: log the audio duration
                };
                setAudio(newAudio); // Create Audio object for playback
            };

            recorder.start();
        } catch (error) {
            console.error("Error starting recording:", error);
        }
    };

    const handleStopRecording = () => {
        setRecording(false);
        setRecordedMessage(true);
        if (mediaRecorder) {
            mediaRecorder.stop();
        }
    };

    const handlePlayRecording = () => {
        if (audio) {
            setPlaying(true);
            setPlaybackTime(0); // Reset playback time

            const playbackTimer = setInterval(() => {
                setPlaybackTime(audio.currentTime);
            }, 1000);

            audio.play().then(() => {
                console.log("Playback started successfully");
            }).catch(error => {
                console.error("Error playing audio:", error);
                setPlaying(false);
                clearInterval(playbackTimer);
            });

            audio.onended = () => {
                setPlaying(false);
                clearInterval(playbackTimer);
            };
        } else {
            console.error("Audio object is not set.");
        }
    };

    const handleStopPlayback = () => {
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setPlaying(false);
            setPlaybackTime(0); // Reset playback time
        }
    };

    return (
        <React.Fragment>
            <Head title="Invoice Details"></Head>
            <Content>
                <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
                    <Block className="d-flex justify-content-center align-items-center mb-4">
                        <Row>
                            {!recording && !playing && recordedMessage && (
                                <Col className="text-center">
                                    <IoPlayCircleSharp
                                        size={150}
                                        color='blue'
                                        onClick={handlePlayRecording}
                                    />
                                    <div className="font-weight-bold h4">Playback</div>
                                </Col>
                            )}
                            {!recording && !playing && (
                                <Col className="text-center">
                                    <IoMicCircleSharp
                                        onClick={handleStartRecording}
                                        size={150}
                                        color='red'
                                    />
                                    <div className="font-weight-bold h4">{recordedMessage ? 'Record Again' : 'Record'}</div>
                                </Col>
                            )}
                            {recording && !playing && (
                                <Col className="text-center">
                                    <IoStopCircle
                                        onClick={handleStopRecording}
                                        size={150}
                                        color="red"
                                    />
                                </Col>
                            )}

                            {playing && (
                                <Col className="text-center">
                                    <IoStopCircle
                                        size={150}
                                        color="red"
                                        onClick={handleStopPlayback}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Block>
                    <div className="text-center">
                        {recording && !playing && (
                            <div className="font-weight-bold h4">Recording Time: {formatTime(elapsedTime)}</div>
                        )}
                        {playing && (
                            <div className="font-weight-bold h4">Playback Time: {formatTime(playbackTime)}</div>
                        )}
                    </div>
                </Container>
            </Content>
        </React.Fragment>
    );
};

export default VoiceRec;

import React, { useEffect, useRef, useState } from "react";
import Content from "../../../../layout/content/Content";
import classNames from "classnames";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Card } from "reactstrap";
import { Block, Icon, Row, Col, Button, RSelect, Loading } from "../../../../components/Component";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import { filterRole, filterStatus } from "./UserData";
import { bulkActionOptions } from "../../../../utils/Utils";
// import { UserContext } from "./UserContext";

import { apiRequest } from "../../../../utils/Api";
import { ToastContainer, toast } from "react-toastify";

export const DataTableHead = ({ ...props }) => {
  return (
    <div style={props.style} className="nk-tb-item nk-tb-head">
      {props.children}
    </div>
  );
};

export const DataTableBody = ({ compact, className, bodyclass, ...props }) => {
  return (
    <div className={`card-inner p-0 ${className ? className : ""}`}>
      <div className={`nk-tb-list nk-tb-ulist ${bodyclass ? bodyclass : ""} ${compact ? "is-compact" : ""}`}>
        {props.children}
      </div>
    </div>
  );
};

export const DataTable = ({ className, bodyClassName, title, ...props }) => {
  return (
    <Card className={`card-bordered ${className ? className : ""}`}>
      <div className="card-inner-group">{props.children}</div>
    </Card>
  );
};

export const DataTableCol = ({ className, size, ...props }) => {
  const rowClass = classNames({
    "nk-tb-col": true,
    [`${className}`]: className,
    [`tb-col-${size}`]: size,
  });
  if (props.cell) {
    return (
      <div key={props.key} className={`${rowClass}  ${props.item.disable && 'disabled'}`}>
        {props.cell(props.item)}
      </div>
    );
  }

  return <div className={rowClass}>{props.children}</div>;
};

export const DataTableRow = ({ className, ...props }) => {
  return (
    <div key={props.key} className={`nk-tb-item ${className ? className : ""}`}>
      {props.children}
    </div>
  );
};

const PaginationComponent = (props) => {
  const pageNumbers = [];
  for (let i = 1; i <= props.totalPages; i++) {
    pageNumbers.push(i);
  }
  const paginationNumber = () => {
    if (pageNumbers.length <= 5) {
      return pageNumbers;
    } else if (pageNumbers.length >= 5) {
      if (props.currentPage <= 2) {
        return [1, 2, 3, "...", pageNumbers[pageNumbers.length - 1]];
      } else if (props.currentPage > 2 && pageNumbers.length > props.currentPage + 2) {
        return ["...", props.currentPage - 1, props.currentPage, props.currentPage + 1, "...", pageNumbers.length];
      } else if (props.currentPage === pageNumbers.length - 2) {
        return ["...", props.currentPage - 1, props.currentPage, props.currentPage + 1, pageNumbers.length];
      } else if (props.currentPage === pageNumbers.length - 1) {
        return ["...", props.currentPage - 1, props.currentPage, pageNumbers.length];
      } else {
        return ["...", props.currentPage - 1, props.currentPage];
      }
    }
  };

  let paginationItms = paginationNumber();
  const firstPage = () => {
    props.paginate(1);
  };

  const lastPage = () => {
    props.paginate(pageNumbers[pageNumbers.length - 1]);
  };

  const nextPage = () => {
    props.paginate(props.currentPage + 1);
  };

  const prevPage = () => {
    props.paginate(props.currentPage - 1);
  };

  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={props.currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          className="page-link-first"
          onClick={(ev) => {
            ev.preventDefault();
            // firstPage();
            props.getList(1);
          }}
          href="#first"
        >
          <Icon name="chevrons-left" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={props.currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          className="page-link-prev"
          onClick={(ev) => {
            ev.preventDefault();
            props.getList(props.currentPage - 1);
          }}
          href="#prev"
        >
          <Icon name="chevron-left" />
        </PaginationLink>
      </PaginationItem>
      {paginationItms.map((page, index) => {
        return (
          <PaginationItem
            disabled={isNaN(page)}
            className={`d-none d-sm-block ${props.currentPage === page ? "active" : ""}`}
            key={index}
          >
            <PaginationLink
              tag="a"
              href="#pageitem"
              onClick={(ev) => {
                ev.preventDefault();
                props.paginate(page);
                props.getList(page);
              }}
            >
              {page}
            </PaginationLink>
          </PaginationItem>
        );
      })}
      <PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === props.currentPage}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            ev.preventDefault();
            props.getList(props.currentPage + 1);
          }}
          href="#next"
        >
          <Icon name="chevron-right" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === props.currentPage}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            ev.preventDefault();
            props.getList(props.totalPages);
          }}
          href="#last"
        >
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

const MainTable = (props) => {
  const [data, setData] = useState();
  //   setData(props.data)
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "Active",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState();
  const [loading, setLoading] = useState(false);
  const [indexOfLastItem, setIndexOfLastItem] = useState();
  const [currentItems, setCurrectIrem] = useState();
  const [totalPages, setTotalPages] = useState();
  const [entries, setEntries] = useState();
  const isInitialRender = useRef(true);

  // Sorting data

  useEffect(() => {
    // Run only if it's the initial load or sort or itemPerPage changes, and onSearchText is empty
    getList();
  }, [sort, itemPerPage]);

  const handleBackButton = () => {
    if (!onSearchText) {
      toggle();
      setSearchText("");
    } else {
      setSearchText(""); // Set the search text first
      getList(1, itemPerPage, false);
    }
  };

  useEffect(() => {
    // This effect will run whenever onSearchText changes, but only if onSearchText is empty and not on the initial render
    if (!isInitialRender.current && onSearchText === "") {
      // Wrap in an async function to use await
      const fetchData = async () => {
        toggle(); // Assuming toggle is a function to handle UI state, e.g., opening/closing a modal
      };

      fetchData();
    } else {
      // Set isInitialRender to false after the first render
      isInitialRender.current = false;
    }
  }, [onSearchText]);

  const getList = async (page = 1, perPage = itemPerPage, search = onSearchText) => {
    const uri = `${props.route}?page=${page}${perPage ? `&perPage=${perPage}` : ""}${search ? `&search=${onSearchText}` : ""
      }${sort ? `&sort=${sort}` : ""}`;
    // console.log(onSearchText)
    try {
      setLoading(true);
      if (props.data) {
        setLoading(true);
        setData(props.data.data);
        setCurrentPage(Number(props.data.meta.currentPage));
        setTotalPages(Number(props.data.meta.totalPages));
        setEntries(Number(props.data.meta.entries));
        setLoading(false);
        return;
      }

      await apiRequest(uri, "GET").then(async (resp) => {
        if (resp.code === 200) {
          if (resp.data) {
            // console.log(resp.data)
            setData(resp.data);
            setCurrentPage(Number(resp.meta.currentPage));
            setTotalPages(Number(resp.meta.totalPages));
            setEntries(Number(resp.meta.entries));
            setLoading(false);
          } else {
            setData();
            setLoading(false);
          }
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  };

  const onActionText = (e) => {
    setActionText(e.value);
  };

  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: 0,
      phone: "",
      status: "Active",
    });
  };

  // const closeModal = () => {
  //   setModal({ add: false });
  //   resetForm();
  // };

  // const closeEditModal = () => {
  //   setModal({ edit: false });
  //   resetForm();
  // };

  const onFormSubmit = (submitData) => {
    const { name, email, balance, phone } = submitData;
    let submittedData = {
      id: data.length + 1,
      avatarBg: "purple",
      name: name,
      role: "Customer",
      email: email,
      balance: balance,
      phone: phone,
      emailStatus: "success",
      kycStatus: "alert",
      lastLogin: "10 Feb 2020",
      status: formData.status,
      country: "Bangladesh",
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ edit: false }, { add: false });
  };

  const onEditSubmit = (submitData) => {
    const { name, email, phone } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item.id === editId) {
        submittedData = {
          id: item.id,
          avatarBg: item.avatarBg,
          name: name,
          image: item.image,
          role: item.role,
          email: email,
          balance: editFormData.balance,
          phone: phone,
          emailStatus: item.emailStatus,
          kycStatus: item.kycStatus,
          lastLogin: item.lastLogin,
          status: editFormData.status,
          country: item.country,
        };
      }
    });
    let index = newitems.findIndex((item) => item.id === editId);
    newitems[index] = submittedData;
    setModal({ edit: false });
  };

  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setEditFormData({
          name: item.name,
          email: item.email,
          status: item.status,
          phone: item.phone,
          balance: item.balance,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };

  const suspendUser = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Suspend";
    setData([...newData]);
  };

  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  const toggle = () => setonSearch(!onSearch);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <React.Fragment>
        <Content>
          <Block>
            <DataTable className="card-stretch is-compact">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      {props.checkbox && (
                        <>
                          <div className="form-wrap">
                            <RSelect
                              options={bulkActionOptions}
                              className="w-130px"
                              placeholder="Bulk Action"
                              onChange={(e) => e.preventDefault}
                            />
                          </div>

                          <div className="btn-wrap">
                            <span className="d-none d-md-block">
                              <Button
                                disabled={actionText !== "" ? false : true}
                                color="light"
                                outline
                                className="btn-dim"
                                onClick={(e) => e.preventDefault}
                              >
                                Apply
                              </Button>
                            </span>
                            <span className="d-md-none">
                              <Button
                                color="light"
                                outline
                                disabled={actionText !== "" ? false : true}
                                className="btn-dim btn-icon"
                                onClick={(e) => onActionClick(e)}
                              >
                                <Icon name="arrow-right"></Icon>
                              </Button>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <a
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <div className="toggle-wrap">
                          <Button
                            className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                            onClick={() => updateTableSm(true)}
                          >
                            <Icon name="menu-right"></Icon>
                          </Button>
                          <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                  <Icon name="arrow-left"></Icon>
                                </Button>
                              </li>
                              {props.filter && (
                                <li>
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                      <div className="dot dot-primary"></div>
                                      <Icon name="filter-alt"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      end
                                      className="filter-wg dropdown-menu-xl"
                                      style={{ overflow: "visible" }}
                                    >
                                      <div className="dropdown-head">
                                        <span className="sub-title dropdown-title">Filter Users</span>
                                        <div className="dropdown">
                                          <a
                                            href="#more"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                            className="btn btn-sm btn-icon"
                                          >
                                            <Icon name="more-h"></Icon>
                                          </a>
                                        </div>
                                      </div>
                                      <div className="dropdown-body dropdown-body-rg">
                                        <Row className="gx-6 gy-3">
                                          <Col size="6">
                                            <div className="custom-control custom-control-sm custom-checkbox">
                                              <input type="checkbox" className="custom-control-input" id="hasBalance" />
                                              <label className="custom-control-label" htmlFor="hasBalance">
                                                {" "}
                                                Have Balance
                                              </label>
                                            </div>
                                          </Col>
                                          <Col size="6">
                                            <div className="custom-control custom-control-sm custom-checkbox">
                                              <input type="checkbox" className="custom-control-input" id="hasKYC" />
                                              <label className="custom-control-label" htmlFor="hasKYC">
                                                {" "}
                                                KYC Verified
                                              </label>
                                            </div>
                                          </Col>
                                          <Col size="6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">Role</label>
                                              <RSelect options={filterRole} placeholder="Any Role" />
                                            </div>
                                          </Col>
                                          <Col size="6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">Status</label>
                                              <RSelect options={filterStatus} placeholder="Any Status" />
                                            </div>
                                          </Col>
                                          <Col size="12">
                                            <div className="form-group">
                                              <button type="button" className="btn btn-secondary">
                                                Filter
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="dropdown-foot between">
                                        <a
                                          href="#reset"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                          className="clickable"
                                        >
                                          Reset Filter
                                        </a>
                                        <a
                                          href="#save"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          Save Filter
                                        </a>
                                      </div>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              )}
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    color="tranparent"
                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                  >
                                    <Icon name="setting"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end className="dropdown-menu-s">
                                    <ul className="link-check">
                                      <li>
                                        <span>Show</span>
                                      </li>

                                      <li className={itemPerPage === 10 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(10);
                                          }}
                                        >
                                          10
                                        </DropdownItem>
                                      </li>
                                      <li className={itemPerPage === 15 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(15);
                                          }}
                                        >
                                          15
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                    <ul className="link-check">
                                      <li>
                                        <span>Order By Date</span>
                                      </li>
                                      <li className={sort === "ASC" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("ASC");
                                          }}
                                        >
                                          ASC
                                        </DropdownItem>
                                      </li>
                                      <li className={sort === "DESC" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("DESC");
                                          }}
                                        >
                                          DESC
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button className="search-back btn-icon toggle-search active" onClick={() => handleBackButton()}>
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Type 'Last name' and then press Enter."
                        value={onSearchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && getList()}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon onClick={() => getList()} name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {loading && (
                <DataTableBody>
                  <Loading />
                </DataTableBody>
              )}
              {!loading && (
                <>
                  <DataTableBody>
                    {/* <DataTableHead style={{ backgroundColor: "#efffff" }}> */}
                    <DataTableHead>
                      {props.checkbox && (
                        <DataTableCol className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={(e) => selectorCheck(e)}
                              id="uid"
                            />
                            <label className="custom-control-label" htmlFor="uid"></label>
                          </div>
                        </DataTableCol>
                      )}
                      {props.columns.map((item, index) => {
                        return (
                          <DataTableCol key={index} col={item.name}>
                            <div style={{ fontSize: "14px" }} className="tb-lead">
                              {item.name}
                            </div>
                          </DataTableCol>
                        );
                      })}
                      {props.tools && props.tools.length > 0 && props.tools && (
                        <DataTableCol className="nk-tb-col text-end"></DataTableCol>
                      )}
                    </DataTableHead>
                    {data && data.length > 0
                      ? data.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <DataTableRow key={index}>
                              {props.checkbox && (
                                <DataTableCol className="nk-tb-col-check">
                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      defaultChecked={item.checked}
                                      id={item.id + "uid1"}
                                      key={Math.random()}
                                      onChange={(e) => onSelectChange(e, item.id)}
                                    />
                                    <label className="custom-control-label" htmlFor={item.id + "uid1"}></label>
                                  </div>
                                </DataTableCol>
                              )}
                              {props.columns.map((cell, index) => {
                                return (
                                  <DataTableCol
                                    className={`${cell.center && " ps-4"}`}
                                    item={item}
                                    cell={cell.cell}
                                    key={index}
                                    disabled={cell.disabled}
                                  ></DataTableCol>
                                );
                              })}
                              {props.tools && props.tools.length > 0 && props.tools && (
                                <DataTableCol className="nk-tb-col text-end">
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                      <Icon name="more-v"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-opt no-bdr">
                                        {props.tools.map((dropdown, index) => {
                                          return (
                                            <li onClick={dropdown.onclick && dropdown.onclick}>
                                              <DropdownItem
                                                tag="a"
                                                href="#edit"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                <Icon name={dropdown.icon}></Icon>
                                                <span>{dropdown.text}</span>
                                              </DropdownItem>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </DataTableCol>
                              )}
                            </DataTableRow>
                          </React.Fragment>
                        );
                      })
                      : null}
                  </DataTableBody>
                  <div className="card-inner">
                    {data && data.length > 0 ? (
                      <div className="align-items-center row">
                        <div className="col-7 col-sm-12 col-md-9">
                          <PaginationComponent
                            getList={getList}
                            totalPages={totalPages}
                            paginate={paginate}
                            currentPage={currentPage}
                          />
                        </div>
                        <div className="col-5 text-start text-md-end col-sm-12 col-md-3">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_2_info"
                            role="status"
                            aria-live="polite"
                          >
                            {itemPerPage * (currentPage - 1) + 1} -{" "}
                            {entries > itemPerPage * currentPage ? itemPerPage * currentPage : entries} of {entries}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </DataTable>
          </Block>

          {/* <AddModal
              modal={modal.add}
              formData={formData}
              setFormData={setFormData}
              closeModal={closeModal}
              onSubmit={onFormSubmit}
              filterStatus={filterStatus}
            />
            <EditModal
              modal={modal.edit}
              formData={editFormData}
              setFormData={setEditFormData}
              closeModal={closeEditModal}
              onSubmit={onEditSubmit}
              filterStatus={filterStatus}
            /> */}
        </Content>
        <ToastContainer />
      </React.Fragment>
    </React.Fragment>
  );
};
export default MainTable;

import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, ClientListTable, PreviewAltCard, Loading } from "../../../../../components/Component";
import { toast } from "react-toastify";
import { Card } from "reactstrap";
import { apiRequest } from "../../../../../utils/Api";
import { PaymentTableColumns } from "../../../../components/table/TableData";
const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInvoiceList();
  }, []);

  const getInvoiceList = async () => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/payment/list", "GET").then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setPaymentData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Payment History"}
            nav="Admin / Billing"
            title={"Payment History"}
            description={"Your payment history."}
          />

          <Block>
            <Card>
              {/* {paymentData && <PaymentTable data={paymentData} />} */}
              {paymentData && (
                <PreviewAltCard>
                  <ClientListTable data={paymentData} columns={PaymentTableColumns} pagination className="nk-tb-list" />
                </PreviewAltCard>
              )}
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default PaymentHistory;

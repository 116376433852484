import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { checkJWTExpiration } from "../utils/Utils";
import { Button, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import LogoDark from "../images/logo-dark.png";
import { apiRequest } from "../utils/Api";
import { Loading } from "../components/Component";

export const UserContext = React.createContext();

const Layout = ({ title, ...props }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState();
  // console.log(userData);

  useEffect(() => {
    document.body.classList.add("has-aside");
    document.body.classList.remove("npc-apps", "apps-only");
    getUserDetail();
    const checkTokenInterval = setInterval(async () => {
      // console.log(await checkJWTExpiration());
      if (!(await checkJWTExpiration())) {
        setModal(true);
        clearInterval(checkTokenInterval); // Stop the interval when the condition is met
        // setTimeout(() => navigate("/"), 5000);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(checkTokenInterval);
  }, []);

  const getUserDetail = async () => {
    setLoading(true);
    await apiRequest("/customer/a/profile", "GET").then((resp) => {
      setUserData(resp.data);
      setLoading(false);
    });
  };
  return (
    <>
      {loading && (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
          <Row>
            <Col xs={12} className="text-center">
              <Loading />
            </Col>
          </Row>
        </Container>
      )}
      {!loading && userData && (
        <>
          <Head title={!title && "Loading"} />
          <UserContext.Provider value={{ userData, getUserDetail }}>
            <AppRoot>
              <AppMain>
                <AppWrap>
                  <Header userData={userData} fixed />
                  <div className="nk-content">
                    <div className="container wide-xl">
                      <div className="nk-content-inner">
                        <Sidebar />
                        <div className="nk-content-body">
                          <Outlet userData={userData} />
                          <Footer />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
                    <ModalBody className="text-center m-2">
                      <img className="logo-dark logo-img logo-img-lg mb-5" src={LogoDark} alt="logo-dark" />
                      <h5>Your Session Has Been Expired</h5>
                    </ModalBody>
                    <div className="p-3 bg-light">
                      <div className="text-center mb-2">Please login again</div>
                      <div>
                        <Button onClick={() => navigate("/")}>Login</Button>
                      </div>
                    </div>
                  </Modal>
                </AppWrap>
              </AppMain>
            </AppRoot>
          </UserContext.Provider>
        </>
      )}
    </>
  );
};
export default Layout;

import React, { useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../../../../components/Component";
import { useForm } from "react-hook-form";

const VerifytNote = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [verifyData, setVerifyData] = useState(
    props.data.sort(function (a, b) {
      return -(a.id - b.id || a.name.localeCompare(b.name));
    }),
  );
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const onDelete = (id) => {
    // console.log(id)
    setVerifyData(
      verifyData.filter(function (obj) {
        return obj.id !== id;
      }),
    );
  };

  const submitForm = (formData) => {
    let highestId = Math.max.apply(
      null,
      verifyData.map(function (o) {
        return o.id;
      }),
    );
    toggleModal();
    setVerifyData(
      ...verifyData.push({
        id: highestId + 1,
        title: formData.title,
        text: formData.text,
        date: "October 4, 2022 at 5:30 AM",
        by: "Carter Robinson",
      }),
    );
  };

  // console.log(verifyData)
  return (
    <React.Fragment>
      <Content>
        <div className="card-inner" id="notes">
          <div className="nk-block">
            <div onClick={toggleModal} className="nk-block-head nk-block-head-sm nk-block-between">
              <h5 className="title">Agents Note</h5>

              <span>
                <a
                  href="/#"
                  type="button"
                  id="cancel_note"
                  style={{ display: "none" }}
                  className="cancel_note btn btn-dim btn-sm btn-secondary"
                >
                  <i className="ni ni-cross"></i>Cancel
                </a>
                <a
                  href="/#"
                  type="button"
                  id="save_note"
                  style={{ display: "none" }}
                  className="text-white btn btn-sm btn-primary"
                >
                  <i className="ni ni-save"></i>Save
                </a>
                <div type="button" id="add_note" className="cancel_note text-white btn btn-sm btn-primary">
                  <i className="ni ni-plus"></i> Add Note
                </div>
              </span>
            </div>
            <div style={{ display: "none" }} id="new_note">
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label">Section</label>
                </div>
                <div className="form-control-group">
                  <select
                    name="sec"
                    id="id_sec"
                    className="  form-select  form-control form-control-lg "
                    data-search="off"
                  >
                    <option disabled="" selected=""></option>

                    <option value="Profile">Profile</option>

                    <option value="Verification">Verification</option>

                    <option value="Documents">Documents</option>

                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div id="other_section" style={{ display: "none" }} className="mb-4">
                <div className="form-group">
                  <div className="form-label-group ">
                    <label className="form-label">
                      Other Section
                      <span id="req_sec_other" className="text-danger"></span>
                    </label>
                  </div>

                  <div style={{ maxHeight: "36px" }} className="form-control-group">
                    <input
                      value=""
                      placeholder=""
                      style={{ maxHeight: "36px" }}
                      id="sec_other"
                      name="sec_other"
                      type="text"
                      className=" form-control form-control-lg "
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label">
                    Note
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-control-group">
                  <textarea
                    id="agent_note"
                    name="agent_note"
                    type="text"
                    className=" required_3  form-control form-control-lg "
                  ></textarea>
                </div>
              </div>
            </div>
            <div id="note_section" className="mt-2 bq-note">
              {verifyData &&
                verifyData.length > 0 &&
                verifyData.map((data, index) => {
                  return (
                    <div key={index} id="note_1" className="bq-note-item">
                      <div className="bq-note-text">
                        <h6>{data.title}</h6>
                        <p>{data.text}</p>
                      </div>
                      <div className="bq-note-meta">
                        <span className="bq-note-added">
                          Added on <span className="date">{data.date}</span> at <span className="time">2:30 AM</span>
                        </span>
                        <span className="bq-note-sep sep">|</span>
                        <span className="bq-note-by">
                          By <span>{data.by}</span>
                        </span>
                        <button
                          type="button"
                          id="delete_1"
                          value="1"
                          className="delete_note link link-sm link-danger"
                          onClick={() => onDelete(data.id)}
                        >
                          Delete Note
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader
            toggle={toggleModal}
            close={
              <button className="close" onClick={toggleModal}>
                <Icon name="cross" />
              </button>
            }
          >
            CONTACT US
          </ModalHeader>
          <ModalBody>
            <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Title<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="client_name_last"
                    {...register("title", { required: true })}
                    className="form-control-l form-control"
                  />
                  {errors.title && <p className="invalid">This field is required</p>}
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Message<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-control-wrap">
                  <textarea
                    id="text"
                    name="request_reason"
                    type="text"
                    className="  form-control form-control-lg "
                    {...register("text", { required: true })}
                  ></textarea>
                  {errors.text && <p className="invalid">This field is required</p>}
                </div>
              </div>

              <div className="center mt-2">
                <button onClick={toggleModal} className="center btn border me-2 m-1 closer" style={{ width: "90px" }}>
                  Cancel
                </button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default VerifytNote;

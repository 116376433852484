import React, { useState, useEffect } from "react";
import Content from "../../../../../../layout/content/Content";
import Head from "../../../../../../layout/head/Head";
import { Block, BlockAthenty, Icon } from "../../../../../../components/Component";
import { useParams, Link } from "react-router-dom";
// import { invoiceData } from "../../../../../../components/table/TableData";
import { apiRequest } from "../../../../../../utils/Api";
import { Button } from "reactstrap";
import LogoDark from "../../../../../../images/logo-dark.png";

const InvoiceDetail = (params) => {
  const [invoiceData, setInvoiceData] = useState("");
  const [user, setUser] = useState();
  const [data] = useState(invoiceData);
  let { invoiceId } = useParams();
  useEffect(() => {
    getInvoiceSingle();
  }, []);

  const getInvoiceSingle = async () => {
    await apiRequest(`/customer/a/invoice/${invoiceId}`, "GET").then((resp) => {
      const invoiceText = resp.data
        .replace(/&nbsp;/g, " ")
        .replace(/\r/g, "")
        .split("//////////")[1]
        .split("<split>");

      const invoiceDetails = {
        date: invoiceText[1],
        invoice_number: invoiceText[2],
        company_name: invoiceText[4],
        company_address1: `${invoiceText[7]}, ${invoiceText[6]}`,
        company_address2: `${invoiceText[8]}, ${invoiceText[9]}, ${invoiceText[11]}`,
        customer_name: invoiceText[5],
        customer_title: invoiceText[12],
        customer_address1: invoiceText[13],
        customer_address2: invoiceText[14],
        customer_address3: invoiceText[15],
      };
      console.log(resp.data);
      setInvoiceData(resp.data);
    });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      {
        <Content>
          <BlockAthenty
            head={"Smart Verify List"}
            nav="Admin / Billing / Invoice"
            title={`Invoice #${invoiceId}`}
            // description="Your invoice details are given bellow."
            description="Your invoice details are below."
            back="/customer/admin/billing/invoices"
            buttons={[
              {
                text: "Print",
                icon: "printer",
                link: "",
                onclick: "",
                tooltip: "Add Verification",
                id: "add-verification",
                form: "my-form",
                linkTo: "/customer/solutions/verify/add",
                color: "primary",
              },
            ]}
          />

          <Block>
            <section className="not-found-controller" dangerouslySetInnerHTML={{ __html: invoiceData }} />
            {/* <div className="invoice">
              <div className="invoice-action">
                <Link to={`${process.env.PUBLIC_URL}/print/invoice/${invoiceId}`} target="_blank">
                  <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                    <Icon name="printer-fill"></Icon>
                  </Button>
                </Link>
              </div>
              <div className="invoice-wrap">
                <div className="invoice-brand text-center">
                  <img src={LogoDark} alt="" />
                </div>

                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title">Invoice To</span>
                    <div className="invoice-contact-info">
                      <h4 className="title">{user.customer_name}</h4>
                      <ul className="list-plain">
                        <li>
                          <Icon name="map-pin-fill"></Icon>
                          <span>
                            {user.company_address1}
                            <br />
                            {user.company_address2}
                          </span>
                        </li>
                        <li>
                          <Icon name="call-fill"></Icon>
                          <span>{user.phone}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-desc">
                    <h3 className="title">Invoice</h3>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Invoice ID</span>:<span>{user.invoice_number}</span>
                      </li>
                      <li className="invoice-date">
                        <span>Date</span>:<span>{user.date}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-150px">Item ID</th>
                          <th className="w-60">Description</th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>24108054</td>
                          <td>Dashlite - Conceptual App Dashboard - Regular License</td>
                          <td>${user.invoiceItem1}</td>
                          <td>1</td>
                          <td>${user.invoiceItem1}</td>
                        </tr>
                        <tr>
                          <td>24108054</td>
                          <td>24 months premium support</td>
                          <td>${user.invoiceItem2}</td>
                          <td>1</td>
                          <td>${user.invoiceItem2}</td>
                        </tr>
                        <tr>
                          <td>23604094</td>
                          <td>Invest Management Dashboard - Regular License</td>
                          <td>${user.invoiceItem3}</td>
                          <td>1</td>
                          <td>${user.invoiceItem3}</td>
                        </tr>
                        <tr>
                          <td>23604094</td>
                          <td>6 months premium support</td>
                          <td>${user.invoiceItem4}</td>
                          <td>1</td>
                          <td>${user.invoiceItem4}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Subtotal</td>
                          <td>
                            $
                            {Number(user.invoiceItem1) +
                              Number(user.invoiceItem2) +
                              Number(user.invoiceItem3) +
                              Number(user.invoiceItem4) +
                              ".00"}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Processing fee</td>
                          <td>$10.00</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">TAX</td>
                          <td>$50.00</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Grand Total</td>
                          <td>${user.totalAmount}</td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="nk-notes ff-italic fs-12px text-soft">
                      Invoice was created on a computer and is valid without the signature and seal.
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </Block>
        </Content>
      }
    </React.Fragment>
  );
};

export default InvoiceDetail;

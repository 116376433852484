import React, { useState } from "react";
import Head from "../../../../../layout/head/Head";
import Content from "../../../../../layout/content/Content";
import { Link } from "react-router-dom";
import Logo from "../../../../../images/logo.png";
import LogoDark from "../../../../../images/logo-dark.png";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../../../../components/Component";

const OrganizationSecurity = () => {
  const [formData, setFormData] = useState({
    name_first: "",
    name_last: "",
    position: "",
    email: "roozbeh.karimi@gmail.com",
    password1: "",
    password2: "",
    phone_work: "",
    phone_mobile: "",
    company_name: "",
    company_phone: "",
    company_size: "",
    company_type: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    region: "",
    postal: "",
  });

  return (
    <React.Fragment>
      <Block className="nk-block-middle nk-auth-body  wide-xl">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <Head title="Customer Profile" />
          <Content page="component">
            <Block size="lg">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5" className="text-center">
                    Coming Soon(security)
                  </BlockTitle>
                </BlockHeadContent>
              </BlockHead>
            </Block>
          </Content>
        </PreviewCard>
      </Block>
    </React.Fragment>
  );
};

export default OrganizationSecurity;

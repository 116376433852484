import React, { useState } from "react";

const LocationMobile = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const getLocation = async () => {
    if ("geolocation" in navigator) {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };
      console.log(await navigator.permissions.query({ name: "geolocation" }));

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setError(null);
        },
        (err) => {
          setError(err.message);
          setLocation(null);
        },
        options,
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  };

  return (
    <div>
      <button onClick={getLocation}>Get Location</button>
      {location && (
        <p>
          Latitude: {location.latitude}, Longitude: {location.longitude}
        </p>
      )}
      {error && (
        <>
          <p>Error: {error}</p> <button onClick={() => window.location.reload()}>please try again</button>
        </>
      )}
    </div>
  );
};

export default LocationMobile;

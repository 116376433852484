import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Block, Button, DocumentScan } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Col, Row } from "reactstrap";
import { LuEqualNot, LuEqual } from "react-icons/lu";

const Facial = () => {
  const [croppedImageBase64, setCroppedImageBase64] = useState();
  const [croppedCompareImageBase64, setCroppedCompareImageBase64] = useState();
  const [facialDetail, setFacialDetail] = useState();
  const [faceMatches, setFaceMatches] = useState();
  const [unmatchedFaces, setUnmatchedFaces] = useState();
  const [text, setText] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onFormSubmit = async () => {
    setFaceMatches();
    setUnmatchedFaces();
    setCroppedCompareImageBase64();
    setFaceMatches();
    setCroppedImageBase64();
    setValue("document2", null);

    var data = new FormData();
    data.append("image1", getValues("document1")[0], getValues("document1")[0].name);
    // console.log(data);
    await apiRequest(`/ocr/face-rekognition`, "POST", data, "file")
      .then((resp) => JSON.parse(resp))
      .then((resp) => {
        // console.log(resp);
        // console.log(resp.data.facialDetail);
        setCroppedImageBase64(resp.data.croppedImageBase64);
        setFacialDetail(resp.data.facialDetail);
      });
  };

  const onCompare = async () => {
    setFaceMatches();
    setUnmatchedFaces();
    setCroppedCompareImageBase64();
    var data = new FormData();
    data.append("image1", getValues("document2")[0], getValues("document2")[0].name);
    data.append("image2", croppedImageBase64);
    await apiRequest(`/ocr/face-compare`, "POST", data, "file")
      .then((resp) => JSON.parse(resp))
      .then((resp) => {
        setCroppedCompareImageBase64(resp.data.croppedImageBase64);
        // console.log(resp);
        if (resp.data.faceMatches) {
          // console.log('Matched')
          setFaceMatches(resp.data.faceMatches);
          // console.log(faceMatches)
        }
        if (resp.data.unmatchedFaces) {
          // console.log('Not Matched')
          setUnmatchedFaces(resp.data.unmatchedFaces);
          // console.log(unmatchedFaces)
        }
      });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      {
        <Content>
          <Block>
            <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <DocumentScan
                    setValue={setValue}
                    height={400}
                    value={watch("document1")}
                    requiredMessage="This field is required"
                    required
                    errors={errors}
                    capture="environment"
                    title="Upload image to extract face"
                    id="1"
                    register={register}
                    name="document1"
                  // onChange={setValue}
                  />
                  <button className="text-center">Submit</button>
                </div>
              </div>
            </form>
          </Block>
          {croppedImageBase64 && facialDetail && (
            <Block>
              <Row>
                <Col>
                  <img src={`data:image/png;base64, ${croppedImageBase64}`} alt="Red dot" />
                </Col>
                <Col>
                  <p>Confidence : {facialDetail.Confidence}</p>
                </Col>
                <hr className="mt-5" />
              </Row>
              <Row>
                <Col>
                  <form className="is-alter" onSubmit={handleSubmit(onCompare)}>
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <DocumentScan
                          setValue={setValue}
                          height={200}
                          value={watch("document2")}
                          requiredMessage="This field is required"
                          required
                          errors={errors}
                          capture="environment"
                          title="Upload image to compare"
                          id="1"
                          register={register}
                          name="document2"
                        // onChange={setValue}
                        />
                        <button>Compare</button>
                      </div>
                    </div>
                  </form>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      {croppedImageBase64 && (
                        <img height={80} src={`data:image/png;base64, ${croppedImageBase64}`} alt="Red dot" />
                      )}
                    </Col>
                    <Col>
                      <Col>
                        {unmatchedFaces && <LuEqualNot size={40} />}
                        {faceMatches && <LuEqual size={40} />}
                      </Col>
                      <p>
                        Confidence: {faceMatches && faceMatches.Similarity.toFixed(1)}{" "}
                        {unmatchedFaces && unmatchedFaces.Confidence.toFixed(1)}
                      </p>
                      {/* <p>Confidence: {faceMatches && faceMatches.Confidence.toFixed(1)}</p> */}
                    </Col>
                    <Col>
                      {croppedCompareImageBase64 && (
                        <img height={80} src={`data:image/png;base64, ${croppedCompareImageBase64}`} alt="Red dot" />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Block>
          )}
        </Content>
      }
    </React.Fragment>
  );
};

export default Facial;

import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, Button, Icon, Input, Loading, Textarea } from "../../../../../components/Component";
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import VerifyActivities from "./verifyActivities/VerifyActivities";
import VerifyVerification from "./VerifyVerification/VerifyVerification";
import VerifyBiometrics from "./verifyBiometrics/VerifyBiometrics";
import VerifyDocuments from "./VerifyDocuments/VerifyDocuments";
import VerifytNote from "./verifyNote/VerifyNote";
import VerifyProfile from "./verifyProfile/VerifyProfile";
import VerifyThirdParties from "./verifyThirdParties/VerifyThirdParties";
import VerifyBusiness from "./VerifyBusiness/VerifyBusiness";
import VerifyMatter from "./verifyMatter/VerifyMatter";
import VerifyCompliance from "./VerifyCompliance/VerifyCompliance";
import { ClientDetails } from "../../../../data/clientDetails";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../../../utils/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import VerifyHistory from "./verifyHistory/VerifyHistory";

const VerifyDetail = () => {
  const [loading, setLoading] = useState();
  const [selectedTab, setSelectedTab] = useState("Matter");
  const [data, setData] = useState();
  const [historyData, setHistoryData] = useState();
  const [modal, setModal] = useState(false);
  const [cancelModal, setcancelModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const location = useLocation();
  const [clickedButtonId, setClickedButtonId] = useState(null);
  const [dLoading, setDLoading] = useState();
  const steps = [
    "initiated",
    "welcome",
    "document_1",
    "document_2",
    "person",
    "location",
    "contact",
    "biometric_face",
    "biometric_voice",
    "estate",
    "business",
  ];

  const params = useParams();
  const request_id = params.id;

  const navigate = useNavigate();

  const onSubmitForm = () => {
    toggleModal();
  };

  const onSubmitShareForm = async () => {
    await apiRequest(`/customer/a/solution/smart-verify/${request_id}/share`, "post", getValues2()).then((resp) => {
      if (resp.code === 200) {
        toggleShareModal();
        navigate(`/customer/solutions/verify/${request_id}`);
        toast.success("Share request sent.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log("shared");
      } else {
        toast.error("Something went wrong. Please contact support.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
    setSelectedTab("Matter");
  };

  useEffect(() => {
    getRequestDetails();
    // getHistoryDetail();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleShareSubmit,
    formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm();

  const toggleModal = () => {
    console.log("toggle");
    setModal(!modal);
    reset();
  };

  const toggleCancelModal = () => {
    setcancelModal(!cancelModal);
  };

  const toggleShareModal = () => {
    console.log("toggle");
    setShareModal(!shareModal);
    reset2();
  };

  const cancelRequest = async () => {
    await apiRequest(`/customer/a/solution/smart-verify/${params.id}/cancel`, "PUT").then((resp) => {
      if (resp.code === 200) {
        navigate("/customer/solutions/verify", { state: "requestDelet" });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const getRequestDetails = async (decisionrResp = null) => {
    setLoading(true);
    try {
      await apiRequest(`/customer/a/solution/smart-verify/${params.id}`, "GET").then((resp) => {
        // console.log(resp.data);
        if (resp.code === 200) {
          console.log(resp.data);
          setData(resp.data);
          setLoading(false);
          if (decisionrResp) {
            toast.success(decisionrResp, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  };

  const getHistoryDetail = async () => {
    try {
      setLoading(true);
      await apiRequest(`/customer/a/solution/smart-verify/${params.id}/history`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp.data);
          setHistoryData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const setDecision = async () => {
    setDLoading(true);
    if (Object.keys(errors).length > 0) return;
    try {
      await apiRequest(`/customer/a/solution/smart-verify/${params.id}/decision`, "PUT", {
        result: clickedButtonId,
        remark: getValues("remarks"),
      }).then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          toggleModal();
          setDLoading(false);
          setClickedButtonId();
          console.log(resp.message);
          getRequestDetails(resp.message);
        } else {
          console.log("toast");

          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      setDLoading(false);
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleClickedButton = (value) => {
    if (Object.keys(errors).length > 0) return;
    console.log(value);
    setClickedButtonId(value);
  };

  const resendSmartVerify = async () => {
    setLoading(true);
    try {
      await apiRequest(`/customer/a/solution/smart-verify/${params.id}/resend`, "POST").then((resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp.data);
          setLoading(false);
          toast.success(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }

  const downloadPdf = (data) => {
    console.log(data);
    const link = document.createElement('a');
    const isBase64 = !data.includes('https://');

    if (isBase64) {
      // If the data is a base64 string, set it as the href
      link.href = `data:application/pdf;base64,${data}`;
      link.download = `ASV_${params.id}.pdf`; // Set the download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // If the data is a direct URL, fetch the file and create a blob URL
      setLoading(true);
      fetch(data)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `ASV_${params.id}.pdf`; // Set the download filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
          URL.revokeObjectURL(url); // Clean up the object URL
        })
        .catch(error => {
          console.error('Failed to download PDF:', error);
          setLoading(false);
        });
    }
  };

  const getPdf = async () => {
    setLoading(true);
    try {
      const response = await apiRequest(`/customer/a/pdf/${params.id}`, "get");
      if (response.data) {
        downloadPdf(response.data);
      } else {
        toast.error("Failed to load PDF data.");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
      toast.error("Error fetching PDF.");
    }
    setLoading(false);
  }


  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Smart Verify"}
            back
            nav="Solutions / Smart Verify"
            title={data && data.name}
            description={`Matter: ${data && data.matter_id} / ${data && data.matter_reason}`}
            buttons={[
              // data &&
              //   data.status === "approved" &&
              {
                text: "Share Report",
                icon: "share",
                link: "",
                onclick: toggleShareModal,
                tooltip:
                  data && data.status !== "complete" && data.active
                    ? "Unable to share report until request reviewed"
                    : data && !data.active
                      ? "The request has been canceled"
                      : "Share Report",
                id: "sharePdf",
                form: "my-form",
                color: "primary",
                hidden: data && data.status === "canceled" ? true : false,
                disabled: data && data.status !== "complete",
              },
              {
                text: "Download Report",
                icon: "download",
                link: "",
                // onclick: { toggleModal },
                tooltip:
                  data && data.status !== "complete" && data.active
                    ? "Unable to download report until request reviewed"
                    : data && !data.active
                      ? "The request has been canceled"
                      : "Download Report",
                id: "downloadPdf",
                form: "my-form",
                // linkTo: `/download-pdf/${params.id}`,
                onclick: getPdf,
                color: "primary",
                newTab: true,
                hidden: data && data.status === "canceled" ? true : false,
                disabled: data && data.status !== "complete",
                // disabled: "disabled",
              },
            ].filter(Boolean)}
            extra={[
              {
                type: "badge",
                title: "Status",
                value:
                  data && data.active
                    ? (data.status.charAt(0).toUpperCase() + data.status.slice(1)).replace("_", " ")
                    : data && !data.active
                      ? "Canceled : " + (data.status.charAt(0).toUpperCase() + data.status.slice(1)).replace("_", " ")
                      : "",
                className: "",
                outline: "warning",
                textColor: "success",
                size: "sm",
              },

              {
                type: "text",
                title: "Completion Date",
                value:
                  data && data.submit_date
                    ? moment.utc(data.submit_date).local().format("YYYY-MM-DD hh:mm")
                    : "Incomplete",
              },
              {
                type: "text",
                title: "Review Date",
                value:
                  data && data.review_date
                    ? moment.utc(data.review_date).local().format("YYYY-MM-DD hh:mm")
                    : "Not reviewed",
              },
            ]}
            extraButtons={
              // extraButtons
              [
                {
                  text: "Resend",
                  icon: "redo",
                  link: "",
                  onclick: resendSmartVerify,
                  tooltip:
                    data && data.status !== "initiated"
                      ? "Can not resend after verification started"
                      : "Resend Smart Verify Request",
                  id: "resend-request",
                  form: "my-form",
                  linkTo: ``,
                  color: "gray",
                  // hidden: data && data.status !== "initiated" ? true : false,
                  disabled: data && data.status !== "initiated" ? true : false,
                  outline: false,
                },
                {
                  text: "Review",
                  icon: "eye-alt",
                  link: "",
                  onclick: toggleModal,
                  tooltip:
                    data && steps.includes(data.status) && data.active
                      ? "Wait for form submission to review request"
                      : data && data.status === "complete"
                        ? "Already reviewed"
                        : data && !data.active
                          ? "The request has been canceled"
                          : "Review",
                  id: "review-request",
                  form: "my-form",
                  linkTo: ``,
                  color: "warning",
                  hidden: data && data.status === "canceled" ? true : false,
                  disabled: (data && steps.includes(data.status)) || (data && data.status === "complete"),
                  outline: false,
                },
                {
                  text: "Cancel",
                  icon: "cross",
                  link: "",
                  onclick: toggleCancelModal,
                  tooltip:
                    data && !steps.includes(data.status) && data.active
                      ? "Cannot be cancelled once completed by client."
                      : data && !data.active
                        ? "The request has been canceled"
                        : "Cancel Smart Verify Request",
                  id: "cancel-request",
                  form: "my-form",
                  linkTo: ``,
                  color: "gray",
                  hidden: data && data.status === "canceled" ? true : false,
                  disabled: data && (!steps.includes(data.status) || !data.active),
                  outline: false,
                },

              ]
            }
          />
          <Block>
            <Card className="card-bordered">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li
                  onClick={() => setSelectedTab("Matter")}
                  className={`nav-item pe-4 ${selectedTab === `Matter` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Matter" ? "active" : ""}`}>
                    <Icon name="list"></Icon>
                    <span>Matter</span>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTab("Profile")}
                  className={`nav-item pe-4 ${selectedTab === `Profile` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Profile" ? "active" : ""}`}>
                    <Icon name="user-circle"></Icon>
                    <span>KYC</span>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTab("Third-Parties")}
                  className={`nav-item pe-4 } ${selectedTab === `Third-Parties` ? "active current-page" : ""} `}
                >
                  <div role="button" className={` nav-link ${selectedTab === "Third-Parties" ? "active" : ""}`}>
                    <Icon name="users" color="danger"></Icon>
                    <span>Third-Parties</span>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTab("Business")}
                  className={`nav-item pe-4 } ${selectedTab === `Business` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Business" ? "active" : ""}`}>
                    <Icon name="briefcase"></Icon>
                    <span>Business</span>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTab("Documents")}
                  className={`nav-item pe-4 ${selectedTab === `Documents` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Documents" ? "active" : ""}`}>
                    <Icon name="cc-secure"></Icon>
                    <span>Documents</span>
                  </div>
                </li>
                {/* <li
                onClick={()=>setSelectedTab('Verifications')}
                className={`nav-item pe-4 ${
                  selectedTab === `Verifications` ? "active current-page" : ""} `}
              >
                <div
                  role="button"
                  className={`nav-link ${selectedTab === 'Verifications' ? "active" : ""}`}
                  
                >
                  <Icon name="check-circle-cut"></Icon>
                  <span>Verifications</span>
                </div>
              </li> */}
                <li
                  onClick={() => setSelectedTab("Biometrics")}
                  className={`nav-item pe-4 ${selectedTab === `Biometrics` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Biometrics" ? "active" : ""}`}>
                    <Icon name="eye-alt"></Icon>
                    <span>Biometrics</span>
                  </div>
                </li>
                <li
                  onClick={() => setSelectedTab("Compliance")}
                  className={`nav-item pe-4 ${selectedTab === `Compliance` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Compliance" ? "active" : ""}`}>
                    <Icon name="list-check"></Icon>
                    <span>Compliance</span>
                  </div>
                </li>
                <li
                  onClick={() => {
                    setSelectedTab("History");
                    getHistoryDetail();
                  }}
                  className={`nav-item pe-4 ${selectedTab === `History` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "History" ? "active" : ""}`}>
                    <Icon name="history"></Icon>
                    <span>History</span>
                  </div>
                </li>
              </ul>

              <div className="card-inner ">
                {selectedTab === "Matter" && <VerifyMatter data={data} />}
                {selectedTab === "Profile" && <VerifyProfile more={data} />}
                {selectedTab === "Verifications" && <VerifyVerification data={data} />}
                {selectedTab === "Biometrics" && <VerifyBiometrics data={data} />}
                {selectedTab === "Documents" && <VerifyDocuments data={data} />}
                {selectedTab === "Activities" && <VerifyActivities data={ClientDetails.activities} />}
                {selectedTab === "Notes" && <VerifytNote data={ClientDetails.notes} />}
                {selectedTab === "Third-Parties" && <VerifyThirdParties data={data} />}
                {selectedTab === "Business" && <VerifyBusiness data={data} />}
                {selectedTab === "Compliance" && <VerifyCompliance data={data} />}
                {selectedTab === "History" && <VerifyHistory data={historyData} />}
              </div>
            </Card>
          </Block>
          <ToastContainer />
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader
              toggle={toggleModal}
              close={
                <button className="close" disabled={dLoading} onClick={toggleModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              <div>Name: {data && data.name}</div>
              Matter: {data && data.matter_id} / {data && data.matter_reason}
            </ModalHeader>
            <ModalBody>
              {dLoading ? (
                <Loading />
              ) : (
                <>
                  <div>Please check off all areas to confirm review sections have been completed</div>
                  <form id="verify-form" className="is-alter form-validate" onSubmit={handleSubmit(setDecision)}>
                    <Row className="mb-4 mt-4">
                      <Col>
                        <div className="mt-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id="matter"
                              checked={watch("matter")}
                              {...register("matter", {
                                required: "This field is required",
                              })}
                              onSelect={() => {
                                setValue("matter", !getValues("matter"));
                              }}
                            />
                            <label className="custom-control-label" htmlFor="matter">
                              Matter
                            </label>
                            {errors.matter && <span className="invalid">{errors.matter.message}</span>}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mt-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id="kyc"
                              checked={watch("kyc")}
                              {...register("kyc", {
                                required: "This field is required",
                              })}
                              onSelect={() => {
                                setValue("kyc", !getValues("kyc"));
                              }}
                            />
                            <label className="custom-control-label" htmlFor="kyc">
                              KYC
                            </label>
                            {errors.kyc && <span className="invalid">{errors.kyc.message}</span>}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mt-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id="third_parties"
                              checked={watch("third_parties")}
                              {...register("third_parties", {
                                required: "This field is required",
                              })}
                              onSelect={() => {
                                setValue("third_parties");
                              }}
                            />
                            <label className="custom-control-label" htmlFor="third_parties">
                              Third-Parties
                            </label>
                            {errors.third_parties && <span className="invalid">{errors.third_parties.message}</span>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mt-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id="business"
                              checked={watch("business")}
                              {...register("business", {
                                required: "This field is required",
                              })}
                              onSelect={() => {
                                setValue("business", !getValues("business"));
                              }}
                            />
                            <label className="custom-control-label" htmlFor="business">
                              Business
                            </label>
                            {errors.business && <span className="invalid">{errors.business.message}</span>}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mt-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id="documents"
                              checked={watch("documents")}
                              {...register("documents", {
                                required: "This field is required",
                              })}
                              onSelect={() => {
                                setValue("documents", !getValues("documents"));
                              }}
                            />
                            <label className="custom-control-label" htmlFor="documents">
                              Documents
                            </label>
                            {errors.documents && <span className="invalid">{errors.documents.message}</span>}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mt-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id="biometrics"
                              checked={watch("biometrics")}
                              {...register("biometrics", {
                                required: "This field is required",
                              })}
                              onSelect={() => {
                                setValue("biometrics", !getValues("biometrics"));
                              }}
                            />
                            <label className="custom-control-label" htmlFor="biometrics">
                              Biometrics
                            </label>
                            {errors.biometrics && <span className="invalid">{errors.biometrics.message}</span>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 ">
                      Please enter any remarks you have regarding this matter and review outcome:
                    </div>
                    <div className="form-control-wrap">
                      <textarea
                        id="remarks"
                        name="remarks"
                        type="text"
                        className="  form-control form-control-lg "
                        {...register("remarks", {
                          required: "This field is required",
                        })}
                      ></textarea>
                      {errors.remarks && <span className="invalid">{errors.remarks.message}</span>}
                    </div>
                    <div className="mt-2">
                      Please note, once review has been submitted, you cannot modify the review again
                    </div>
                    <div className="mt-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="form-control custom-control-input"
                          id="agree"
                          checked={watch("agree")}
                          {...register("agree", {
                            required: "This field is required",
                          })}
                          onSelect={() => {
                            setValue("agree", !getValues("agree"));
                          }}
                        />
                        <label className="custom-control-label" htmlFor="agree">
                          I Agree
                        </label>
                        {errors.agree && <span className="invalid">{errors.agree.message}</span>}
                        {/* {errors.agree && <p className="invalid">{errors.agree.message}</p>} */}
                      </div>
                    </div>
                    <div className="center mt-2">
                      <Button
                        disabled={dLoading}
                        onClick={toggleModal}
                        className="center btn border me-2 m-1 closer"
                        style={{ width: "90px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={dLoading}
                        onClick={() => handleClickedButton("rejected")}
                        id="rejected"
                        color="danger"
                        form="verify-form"
                        className="btn btn-primary me-2 m-1 closer"
                      >
                        Fail
                      </Button>
                      <Button
                        disabled={dLoading}
                        onClick={() => handleClickedButton("approved")}
                        id="approved"
                        color="success"
                        form="verify-form"
                        className="btn btn-primary me-2 m-1 closer"
                      >
                        Pass
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </ModalBody>
          </Modal>

          <Modal isOpen={shareModal} toggle={toggleShareModal}>
            <ModalHeader
              toggle={toggleShareModal}
              close={
                <button className="close" onClick={toggleShareModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Share Smart Verify Result PDF
            </ModalHeader>
            <ModalBody>
              <form id="verify-form" className="is-alter form-validate" onSubmit={handleShareSubmit(onSubmitShareForm)}>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          First / Last Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="mobile"
                          name="mobile"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="name" className="form-label">
                          Company or Business Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col">
                        <Input
                          id="company"
                          name="company"
                          type="text"
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-control-wrap">
                        <Textarea
                          id="reason"
                          name="reason"
                          type="text"
                          label="Reason "
                          register={register2}
                          required
                          requiredMessage="This field is required"
                          errors={errors2}
                          className=" form-control form-control-lg"
                        // defaultValue={data && data.matter_reason}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center mt-2">
                  <Button
                    onClick={toggleShareModal}
                    className="center btn border me-2 m-1 closer"
                    style={{ width: "90px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" form="verify-form" className="btn btn-primary me-2 m-1 closer">
                    Share
                  </Button>
                </div>
              </form>
            </ModalBody>
          </Modal>
          <Modal isOpen={cancelModal} toggle={toggleCancelModal}>
            <ModalHeader
              toggle={toggleShareModal}
              close={
                <button className="close" onClick={toggleShareModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Cancel Smart Verify Result
            </ModalHeader>
            <ModalBody>
              <div>
                <p>
                  Are you certain you wish to cancel this verification request? Kindly note that upon submission of
                  cancellation, the request becomes irreversible. You will be unable to view or amend the request, as it
                  will be permanently removed from your verification list.
                </p>
              </div>
              <div className="center mt-2">
                <Button
                  onClick={toggleCancelModal}
                  className="center btn border me-2 m-1 closer"
                  style={{ width: "90px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={cancelRequest}
                  type="submit"
                  form="verify-form"
                  className="btn btn-primary me-2 m-1 closer"
                >
                  Cancel request
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <ToastContainer />
        </Content>
      )}
    </React.Fragment>
  );
};

export default VerifyDetail;

import React, { useEffect, useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { apiRequest } from "../../../../utils/Api";
import { Navigate, useNavigate } from "react-router";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { findUpper } from "../../../../utils/Utils";

const User = (props) => {
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(props.userData);
  const toggle = () => setOpen((prevState) => !prevState);
  const navigate = useNavigate();
  // useEffect(() => {
  //   const getUsetDetail = async () => {
  //     const userDataResp = await apiRequest("/customer/a/profile", "GET");
  //     console.log(userDataResp);

  //     setUserData(userDataResp.data);
  //   };
  //   getUsetDetail();
  // }, []);

  const removeToken = () => {
    return new Promise((resolve, reject) => {
      try {
        Cookies.remove("token", {
          path: "/",
          domain: ".athenty.com",
        });
        Cookies.remove("token", {
          path: "/",
          domain: "localhost",
        });
        Cookies.remove("token");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSignout = async () => {
    console.log("sign out");
    await removeToken();
    window.location.href = "/";
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle" >
          <UserAvatar imageLink={userData.avatar} icon="user-alt" className="sm" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">

              {!userData.avatar ? <span>{findUpper(userData.name)}</span> : <img alt="avatar" style={{ objectFit: 'cover', position: 'absolute', width: '100%', height: '100%' }} src={userData.avatar}></img>}
            </div>
            <div className="user-info">
              <span className="lead-text">{userData.name}</span>
              <span className="sub-text">{userData.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/customer/admin/access/profile#personal" icon="user" onClick={toggle}>
              My Profile
            </LinkItem>
            <LinkItem link="/customer/admin/access/profile#security" icon="account-setting" onClick={toggle}>
              Account Setting
            </LinkItem>
            {/* <LinkItem link="/customer/admin/access/profile#activity" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem> */}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <Link onClick={handleSignout} state={{ data: "acticity" }}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </Link>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;

import Content from "../../../../../layout/content/Content";
import React, { useEffect, useRef, useState } from "react";
import VerifyAddNew from "./verifyAddNew/VerifyAddNew";
import VerifyAddExisting from "./verifyAddExisting/VerifyAddExisting";

import { Block, BlockAthenty, Icon } from "../../../../../components/Component";
import { Card } from "reactstrap";

const VerifyAdd = () => {
  const submitRef = useRef();
  const [selectedTab, setSelectedTab] = useState("new");

  const asignRef = () => {
    return submitRef.current.click();
  };

  return (
    <React.Fragment>
      <Content page="component">
        <BlockAthenty
          head={"New Smart Verify Request"}
          nav="Solutions / Smart Verify"
          title={"New Smart Verify Request"}
          back={"/customer/solutions/verify"}
          description="Send new verification request"
          buttons={[
            {
              // type:"submit",
              text: "Send",
              icon: "send",
              link: "",
              onclick: asignRef,
              tooltip: "Send verification request",
              id: "submit",
              form: "verify-form",
              color: "primary",
            },
          ]}
        />
      </Content>
      <Block className="nk-content-wrap">
        <Card className="card-bordered ">
          <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
            <li
              onClick={() => setSelectedTab("new")}
              className={`nav-item ${selectedTab === `new` ? "active current-page" : ""} `}
            >
              <div role="button" className={`nav-link ${selectedTab === "new" ? "active" : ""}`}>
                <Icon name="user-add"></Icon>
                <span>Add Client</span>
              </div>
            </li>
            {/* <li
              onClick={() => setSelectedTab("existing")}
              className={`nav-item ${selectedTab === "existing" ? "active" : ""}`}
            >
              <div role="button" className={`nav-link ${selectedTab === "existing" ? "active" : ""}`}>
                <Icon name="user-check"></Icon>
                <span>Existing Client</span>
              </div>
            </li> */}
          </ul>

          <div className="card-inner card-inner-lg">
            {selectedTab === "new" && <VerifyAddNew submitRef={submitRef} />}
            {/* {selectedTab === "existing" && <VerifyAddExisting submitRef={submitRef} />} */}
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default VerifyAdd;

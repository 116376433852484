import React from "react";
import Content from "../../../../../layout/content/Content";
import { Link, Outlet } from "react-router-dom";
import {
  Block,
  BlockAthenty,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../../../../components/Component";
import { Card } from "reactstrap";
import Logo from "../../../../../images/logo.png";
import LogoDark from "../../../../../images/logo-dark.png";
import Head from "../../../../../layout/head/Head";

const ApiToken = () => {
  return (
    <React.Fragment>
      <BlockAthenty
        head={"Organization"}
        nav="Admin / Access"
        title={"Organization"}
        description={"View & update your main company information."}
      />
      <Block className="">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <Head title="Customer Profile" />
          <Content page="component">
            <Block size="lg">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5" className="text-center">
                    Coming Soon (API Token)
                  </BlockTitle>
                </BlockHeadContent>
              </BlockHead>
            </Block>
          </Content>
        </PreviewCard>
      </Block>
    </React.Fragment>
  );
};

export default ApiToken;

import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockAthenty,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Icon,
  Input,
  Loading,
  Row,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Button, Card, Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import classNames from "classnames";

import ticketData from "./data/tickets";
import MainTable from "../../components/athenty/table/Main.Table";
import { apiRequest } from "../../../utils/Api";
import { dateConverter } from "../../../utils/Utils";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import RSelect2 from "../../../components/select/ReactSelect2";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../layout/Index";

export const ticketListColumns = [
  {
    name: "Ticket ID",
    selector: (row) => row.name,
    compact: true,
    sortable: true,
    cell: (row) => (
      <Link to={`/customer/tickets/${row.ticket_id}`}>
        <div className="user-card position-relative">{row.ticket_id}</div>
      </Link>
    ),
  },
  {
    name: "SUBJECT",
    selector: (row) => row.subject,
    sortable: true,
    compact: true,
    hide: "sm",
    grow: 3,
    cell: (row) => (
      <div className="user-card " data-toggle="tooltip">
        {row.subject}
      </div>
    ),
  },

  {
    name: "SUBMITED",
    selector: (row) => row.status.description,
    sortable: true,
    grow: 3.2,
    hide: "sm",
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">
            {dateConverter(row.ticket_created_at, true)[0]} <span className={"dot"}></span>
          </span>
          <span>{dateConverter(row.ticket_created_at, true)[1]}</span>
        </div>
      </div>
    ),
  },

  {
    name: "LAST REPLY",
    selector: (row) => row.created_at,
    sortable: true,
    compact: true,
    grow: 2.5,
    hide: "md",
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">
            {moment.utc(row.ticket_last_updated_at).format("YYYY-MM-DD")} <span className={"dot"}></span>
          </span>
          <span>{moment(row.ticket_last_updated_at).fromNow()}</span>
        </div>
      </div>
    ),
  },
  {
    name: "STATUS",
    selector: (row) => row.created_at,
    hide: "md",
    cell: (row) => (
      <div className="">
        <div className={`w-50 text center badge bg-${row.ticket_status === "New" ? "success" : "light"}`}>
          {row.ticket_status}
        </div>
      </div>
    ),
  },
  {
    name: "",
    selector: (row) => row.created_at,
    hide: "md",
    cell: (row) => (
      <div className="tb-ticket-action ">
        <Link to={`${process.env.PUBLIC_URL}/customer/tickets/${row.ticket_id}`} className="btn btn-icon btn-trigger">
          <Icon name="chevron-right" />
        </Link>
      </div>
    ),
  },
];

const Tickets = () => {
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(UserContext)

  useEffect(() => {
    getTickets();
  }, []);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("customer_name", userData.name);
    setValue("customer_email", userData.email);
  }, []);

  const supportTypeOptions = [
    { value: "support", label: "Support" },
    { value: "billing", label: "Billing" },
  ];

  const getTickets = () => {
    try {
      console.log("ty");
      setTimeout(async () => {
        await apiRequest("/customer/a/tickets", "GET").then(async (resp) => {
          console.log(resp);
          setData(resp.data);
          if (resp.code === 200) {
            console.log(resp);
          } else {
            console.log("error");
          }
        });
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  const onFormSubmit = async () => {
    console.log("submit");
    setLoading(true);
    try {
      await apiRequest("/customer/a/tickets", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setLoading(false);
          toggleModal();
          toast.success("You message has been sent", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          console.log(resp);
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const onselect = (name, value) => {
    setValue(name, value);
    console.log(name);
    console.log(getValues(name));
    return;
  };

  return (
    <React.Fragment>
      <Head title="Tickets"></Head>
      <Content>
        <BlockAthenty
          head={"Tickets"}
          nav="Support"
          title={"Tickets"}
          //   back={"/customer/solutions/verify"}
          buttons={[
            {
              // type:"submit",
              text: "New Ticket",
              icon: "plus",
              link: "",
              onclick: toggleModal,
              tooltip: "Create New Ticket",
              id: "submit",
              form: "verify-form",
              color: "primary",
              // disabled: true
            },
          ]}
        />
        <Block>
          <MainTable
            // checkbox
            // compact
            // tools={tools}
            columns={ticketListColumns}
            route="/customer/a/tickets"
          />
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader
              toggle={toggleModal}
              close={
                <button className="close" onClick={toggleModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              CONTACT US
            </ModalHeader>

            <ModalBody>
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="form-group">
                  <Input
                    type="text"
                    label="Your Name"
                    value={watch("customer_name")}
                    id="customer_name"
                    className="form-control-lg form-control"
                    name="customer_name"
                    required
                    requiredMessage="This field is required"
                    errors={errors}
                    register={register}
                    disabled
                  />
                  <Input
                    type="text"
                    label="Email"
                    id="customer_email"
                    className="form-control-lg form-control"
                    name="customer_email"
                    required
                    value={watch("customer_email")}
                    requiredMessage="This field is required"
                    errors={errors}
                    register={register}
                    disabled
                  />
                  <RSelect2
                    defaultValue={0}
                    onselect={onselect}
                    setValue={setValue}
                    selectedAct={watch("department")}
                    label="Department"
                    register={register}
                    requiredMessage="This field is required"
                    id="department"
                    name="department"
                    errors={errors}
                    options={supportTypeOptions}
                    placeholder="Select"
                    className="form-control-lg p-0 border-0 form-control"
                  />
                  <Input
                    type="text"
                    label="Subject"
                    id="subject"
                    className="form-control-lg form-control"
                    name="subject"
                    required
                    value={watch("subject")}
                    requiredMessage="This field is required"
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Message<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <textarea
                      id="request_reason"
                      name="request_reason"
                      type="text"
                      className="  form-control form-control-lg "
                      {...register("request_reason", { required: true })}
                    ></textarea>
                    {errors.request_reason && <p className="invalid">This field is required</p>}
                  </div>
                </div>
                <div className="center mt-2">
                  <button
                    onClick={toggleModal}
                    className={`${loading ? "disabled" : ""} center btn border me-2 m-1 closer`}
                    style={{ width: "90px" }}
                  >
                    Cancel
                  </button>
                  <Button color="primary" size="" className="" outline="">
                    {!loading ? "Send" : <Spinner />}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </Block>
      </Content>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Tickets;

import Jon from "../../../images/jon.png";
import Cookies from "js-cookie";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import LogoDark from "../../../images/logo-dark.png";
import { Block, Icon, Loading } from "../../../components/Component";
import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../utils/Api";

const Decision = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);

  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(true);
  const [decision, setDecision] = useState();
  const [remarks, setRemarks] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    // console.log(Cookies.get())
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    await apiRequest("/ccr/verify/" + request_id + "/decision", "GET").then((resp) => {
      try {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp);
          setDecision(resp.data.result);
          setRemarks(resp.data.remark);
          setLoading(false);
        } else {
          console.log("something went wrong");
        }
      } catch (error) {}

      // console.log(getValues());
    });
  };
  return (
    <Block className="nk-block-middle nk-auth-body  wide-xl">
      {loading && <Loading />}
      {!loading && (
        <Card className="">
          <CardBody className="card-inner text-center">
            <img className="logo-dark logo-img logo-img-lg mb-5" src={LogoDark} alt="logo-dark" />
            <CardTitle tag="h5">Your verification request has been {decision || "NAN"}</CardTitle>
            {/* <CardBody></CardBody> */}
            <CardTitle tag="h5">Remarks : {remarks || "NAN"}</CardTitle>
          </CardBody>
        </Card>
      )}
    </Block>
  );
};

export default Decision;

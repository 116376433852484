import React, { useState, useEffect } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import {
  Icon,
  ClientListTable,
  PreviewCard,
  BlockAthenty,
  Block,
  UserAvatar,
  Loading,
  PreviewAltCard,
  Input,
  Button,
  // DropdownMenu,
} from "../../../../../components/Component";
import { Badge, Form, Card, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Spinner, UncontrolledDropdown } from "reactstrap";

import { Link } from "react-router-dom";
import { capitalizeFirstLetter, capitalizeWords, findUpper } from "../../../../../utils/Utils";
import MainTable from "../../../../components/athenty/table/Main.Table";
import { apiRequest } from "../../../../../utils/Api";
import { toast, ToastContainer } from "react-toastify";
import { parsePhoneNumber } from "libphonenumber-js";
import { useForm } from "react-hook-form";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [modal, setModal] = useState(false)
  const [userToDelete, setUserToDelet] = useState()
  const dataTableColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <div>
          {/* <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar> */}
          <span>
            {capitalizeWords(row.name)}
          </span>
        </div>
      ),
      sortable: false,
      grow: 1.5,
    },
    {
      name: "Email / Phone",
      selector: (row) => row.email,
      cell: (row) => (<div className="user-card " data-toggle="tooltip">
        <div className="user-info">

          <span>
            <Icon name="call-fill" className={"pe-1"} />
            <a href={`tel:${row.phone_mobile}`}>{parsePhoneNumber("+1" + row.phone_mobile).formatNational()}</a>
            <br />
            <Icon name="mail" className={"pe-1"} />
            <a href={`mailto://${row.email}`}>{row.email}</a>
          </span>
        </div>
      </div>),
      sortable: false,
      grow: 2.5,
    },
    // {
    //   name: "Phone Number",
    //   selector: (row) => row.phoneNumber,
    //   compact: true,
    //   width: "110px",
    //   style: { paddingRight: "10px" },
    //   cell: (row) => <span>{row.phoneNumber}</span>,
    //   sortable: false,
    // },
    {
      name: "Title",
      selector: (row) => row.businessTitle,
      cell: (row) => <span>{row.title}</span>,
      sortable: false,
    },
    // {
    //   name: "Role or Profession",
    //   selector: (row) => row.businessRole,
    //   compact: true,
    //   width: "80px",
    //   style: { paddingRight: "10px" },
    //   cell: (row) => <span>{row.businessRole}</span>,
    //   sortable: false,
    // },
    {
      name: "License",
      selector: (row) => row.license,
      cell: (row) => <span>{row.package_name}</span>,
      sortable: false,
    },
    {
      name: "Role",
      selector: (row) => row.role,

      cell: (row) => <span>{row.role}</span>,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => row.permission,

      cell: (row) => (
        <Badge color={row.state === 1 ? "success" : "danger"} pill>
          {row.state === 1 ? "Active" : "Disabled"}
        </Badge>
      ),
      sortable: false,
    },
    {
      name: "",
      compact: true,
      cell: (row) => (
        <div className="row">
          <div className="col-6">
            {row.role !== "Owner" ? (
              <UncontrolledDropdown className="ps-5">
                <DropdownToggle tag="a" className="btn btn-icon btn-trigger">
                  <Icon name="more-v"></Icon>
                </DropdownToggle>
                <DropdownMenu>
                  <ul className="link-list-opt no-bdr">
                    {row.state === 2 && (
                      <li>
                        <Link onClick={() => enableAgent(row)}>
                          <Icon name="check"></Icon>
                          <span>Enable Access</span>
                        </Link>
                      </li>
                    )}
                    {row.state === 1 && (
                      <li>
                        <Link to="" onClick={() => disableAgent(row)}>
                          <Icon name="na"></Icon>
                          <span>Disable Access</span>
                        </Link>
                      </li>

                    )}
                    <li>
                      <Link onClick={() => toggleModal(row)}>
                        <Icon name="trash"></Icon>
                        <span>Delete</span>
                      </Link>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <div style={{ minHeight: "34px" }}></div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const {
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getAgentsList();
  }, []);

  const toggleModal = (data) => {
    setUserToDelet(data)
    console.log(data)
    setModal(!modal)
    // console.log(modal)
  }

  const getAgentsList = async () => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/agents/list", "GET").then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };


  const disableAgent = async (row) => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/agents/disable", "POST", row).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const enableAgent = async (row) => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/agents/enable", "POST", row).then((resp) => {
        if (resp.code === 200) {
          // console.log(resp);
          setData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const deleteAgent = async (data) => {
    console.log('test')
    console.log(userToDelete)
    if (!userToDelete) {
      return
    }
    setLoading(true)
    try {
      await apiRequest("/customer/a//agents/delete", "DELETE", userToDelete).then((resp) => {
        if (resp.code === 200) {
          console.log(resp);
          setLoading(false);
          toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

  }

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Users"}
            nav="Admin / Access"
            title={"Users"}
            description={`View and update your organization's users.`}
            buttons={[
              {
                text: "Add",
                icon: "plus",
                tooltip: "Add User",
                id: "add-client",
                form: "my-form",
                linkTo: "/customer/admin/access/users/add",
                className: "btn-icon",
                color: "primary",
              },
            ]}
          />
          <Block className="">
            <Content page="component">
              {/* <PreviewCard className="card-bordered">
                <Head title="Customer Profile" />
                <Block size="lg">
                  <Card>
                    {data && (
                      <ClientListTable
                        data={data}
                        columns={dataTableColumns}
                        pagination
                        className="nk-tb-list"
                      // selectableRows={true}
                      />
                    )}
                  </Card>
                </Block>
              </PreviewCard> */}
              <MainTable route="/customer/a/agents/list" columns={dataTableColumns} />
            </Content>
          </Block>
        </Content>

      )}
      {modal && (
        <Modal isOpen={modal} toggle={() => { toggleModal(); setUserToDelet() }}>
          <ModalHeader
            toggle={toggleModal}
            close={
              <button className="close" onClick={() => { toggleModal(); setUserToDelet() }}>
                <Icon name="cross" />
              </button>
            }
          >
            CONTACT US
          </ModalHeader>
          {loading && <Loading />}

          {!loading && (
            <ModalBody>
              <Form className="is-alter" onSubmit={handleSubmit(deleteAgent)}>
                <div className="form-group">


                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    Are you sure you want to delete this user? This action cannot be undone.
                  </div>

                </div>
                <div className="center mt-2">
                  <button
                    onClick={() => { toggleModal(); setUserToDelet() }}
                    className={`${loading ? "disabled" : ""} center btn border me-2 m-1 closer`}
                    style={{ width: "90px" }}
                  >
                    Cancel
                  </button>
                  <Button color="danger" size="" className="d-flex justify-content-center" outline="" style={{ width: "90px", alignItems: 'center' }}>
                    {!loading ? "Delete" : <Spinner />}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          )}
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Users;

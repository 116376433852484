import { useState } from "react";
import { Icon } from "../../Component";

const Input = ({
  id,
  label,
  type,
  required,
  autoComplete = "off",
  name,
  disabled,
  defaultValue,
  onChange,
  className,
  page,
  register = function () {},
  errors,
  capture,
  requiredMessage,
  accept,
  display,
  placeholder,
  min,
  max,
  height,
  width,
  value,
}) => {
  const [show, setShow] = useState(false);
  var inputType = type;

  var maxLength;
  if (type === "postal") {
    inputType = "text";
    maxLength = 6;
  }
  return (
    <div className="form-group">
      {label && (
        <label className="form-label" htmlFor="name">
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className="form-control-wrap">
        {type === "password" && (
          <a
            href="#password"
            onClick={(ev) => {
              ev.preventDefault();
              setShow(!show);
            }}
            className={`form-icon lg form-icon-right passcode-switch ${show ? "is-hidden" : "is-shown"}`}
          >
            <Icon name="eye" className="passcode-icon icon-show"></Icon>

            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
          </a>
        )}
        <input
          defaultValue={defaultValue}
          min={min}
          max={max}
          capture={capture}
          type={type === "password" ? (show ? "text" : "password") : inputType}
          id={id}
          value={value}
          accept={accept}
          name={name}
          disabled={disabled}
          style={{
            width: width,
            height: height || "38px",
            display: display === "none" ? "none" : "",
            paddingLeft: "10px",
          }}
          {...register(name, typeof requiredMessage == "string" ? { required: requiredMessage } : requiredMessage)}
          // onChange={(e)=> type === 'file' && onChange ? onChange(name,e.target.files[0]) : onchange }
          autoComplete={autoComplete}
          placeholder={placeholder}
          className={"form-control" + className}
        />
        {errors && errors[name] && <p className="invalid">{errors[name].message}</p>}
      </div>
    </div>
    // <div className="form-group">
    //   <div className="form-label-group">
    //     <label className="form-label" htmlFor="default-01">
    //       {label}
    //       {required && <span className="text-danger">*</span>}
    //     </label>
    //     <div className="form-control-wrap">
    //     <input
    //       {...register(name, { required: requiredMessage })}
    //       onChange={onChange}
    //       autoComplete= {autoComplete}
    //       id={id}
    //       name={name}
    //       type={type}
    //       className={"form-control " + className}
    //       disabled={disabled}
    //       style={{ height: "38px" }}
    //     />
    //   </div>
    //   </div>

    //   {errors && errors[name] && (
    //     <p className="invalid">{errors[name].message}</p>
    //   )}
    // </div>
  );
};

export default Input;

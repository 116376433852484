import { useState } from "react";
import { Block, Icon, Input } from "../../../components/Component";
import LogoDark from "../../../images/logo-dark.png";

import { Button, Card, CardBody, CardTitle, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../utils/Api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router";
const NotMobile = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const location = useLocation();
  console.log(location);

  useState(() => {
    setValue("uuid", location.search.replace("?request_id=", ""));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const sendLink = () => {
    openModal();
  };
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    if (modalOpen) {
      setSmsLoading(false);
    }
    setModalOpen(false);
  };

  const onFormSubmit = async () => {
    console.log("submit");
    setSmsLoading(true);

    console.log(getValues("code"));
    try {
      await apiRequest("/ccr/verify/phone/send-link", "POST", getValues()).then((resp) => {
        if (resp.code === 200) {
          toast.success("Link sent to your mobile phone", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
      });
    } catch (err) {
      console.log("error response: ", err.message);
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    console.log("return");
  };

  return (
    <Block className="nk-block-middle nk-auth-body  wide-xl">
      <Card className="">
        <CardBody className="card-inner text-center">
          <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          <CardTitle tag="h5">Please Use Mobile device to continue</CardTitle>
          <Button onClick={sendLink} color="primary" form="verify-form" className="btn btn-primary me-2 m-1 closer">
            Get Link On Mobile
          </Button>
        </CardBody>
      </Card>
      <Modal backdrop={success && "static"} isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader
          toggle={closeModal}
          close={
            <button disabled={success} className="close" onClick={closeModal}>
              <Icon name="cross" />
            </button>
          }
        >
          Verify Phone Number
        </ModalHeader>
        <ModalBody>
          <p className="text-center">Enter your mobile phone number to get the verification link via SMS.</p>
          <div className="center text-center">
            <Input
              errors={errors}
              required
              requiredMessage="This field is required"
              register={register}
              type="text"
              label="Mobile number"
              id="phone"
              name="phone"
              className="form-control-lg form-control"
            ></Input>
          </div>
          {/* <div className="mt-3 nk-kycfm-note">
            <em className=" icon ni ni-info-fill"></em>
            <p>
              We use your current location to verify that it matches your address on at least one of your ID documents.
            </p>
          </div> */}
          <div className="mt-2 mb-3 center">
            <Button
              disabled={!(watch("phone") && watch("phone").length > 9) || smsLoading}
              color="primary"
              size=""
              className=""
              outline=""
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
            >
              {loading ? <Spinner size="sm" color="light" /> : "Send"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </Block>
  );
};

export default NotMobile;

import React, { useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { apiRequest } from "../../../utils/Api";
import countryList from "react-select-country-list";

import { Block, Input, Button, Loading } from "../../../components/Component";
import { useForm } from "react-hook-form";
import RSelect2 from "../../../components/select/ReactSelect2";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "reactstrap";

const Personal = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [step, setStep] = useState();
  const [loading, setLoading] = useState(true);
  const [previousName, setPreviousName] = useState();
  const [pronoun, setPronoun] = useState();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const countryOptions = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const title_options = [
    { value: "mr", label: "MR." },
    { value: "mrs", label: "MRS." },
    { value: "mx", label: "MX." },
    { value: "dr", label: "DR." },
    { value: "sir", label: "SIR" },
    { value: "madam", label: "MADAM" },
    { value: "ms", label: "MS." },
    { value: "miss", label: "MISS" },
  ];
  const legal_name_options = [
    { value: "y", label: "Yes" },
    { value: "n", label: "No" },
  ];
  const pronoun_options = [
    { value: "He/Him", label: "He/Him" },
    { value: "She/Her", label: "She/Her" },
    { value: "They/Them", label: "They/Them" },
    { value: "Other", label: "Other" },
  ];
  const sex_options = [
    { value: "m", label: "M" },
    { value: "f", label: "F" },
    { value: "x", label: "X" },
  ];

  const marital_options = [
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
    { value: "common law", label: "Common Law" },
    { value: "divorced", label: "Divorced" },
    { value: "windowed", label: "Windowed" },
  ];
  const income_options = [
    { value: "0 - $35,000", label: "0 - $35,000" },
    { value: "$35,001 - $70,000", label: "$35,001 - $70,000" },
    { value: "$70,001 - $125,000", label: "$70,001 - $125,000" },
    { value: "$125,001 - $250,000", label: "$125,001 - $250,000" },
    { value: "$250,001+", label: "$250,001+" },
  ];

  const day_options = Array.from({ length: 31 }, (_, i) => ({
    value: (i + 1).toString().padStart(2, '0'),
    label: (i + 1).toString().padStart(2, '0'),
  }));

  const month_options = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const year_options = Array.from({ length: 100 }, (_, i) => ({
    value: (2023 - i).toString(),
    label: (2023 - i).toString(),
  }));
  const [selectedValue1, setSelectedValue1] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onselect = (name, value) => {
    // console.log(name)
    // console.log(value)
    // console.log(errors)
    if (name === "name_change") {
      setPreviousName(value);
      if (value === "n") {
        delete errors["name_previous"];
      }
    }
    if (name === "pronoun") {
      setPronoun(value);
      if (value !== "other") {
        delete errors["other_pronoun"];
      } else {
      }
    }
    if (getValues(name)) {
      delete errors[name];
      // console.log(errors)
    }
    if (name === "name_change") {
      setPreviousName(value);
      console.log("name_change");
      if (value === "n") {
        delete errors["name_previous"];
      }
    }
    console.log(errors);
  };

  function formatDate() {
    var date = new Date();
    // console.log(date)
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? "0" + date.getMonth() : date.getMonth();
    const year = date.getFullYear() - 18;
    // console.log(day)
    // console.log(month)
    // console.log(year)
    // console.log((year + '-' + month + '-' + day))
    return year + "-" + month + "-" + day;
  }

  useEffect(() => {
    setLoading(false);
    reset();
  }, []);

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async () => {
    setSubmitButtonLoading(true);
    // console.log("form submit");
    try {
      const location = await locationAccess();
      setValue("latitude", location.latitude);
      setValue("longitude", location.longitude);
      console.log("DOING IT NOW!");
      await apiRequest(`/ccr/verify/step/3`, "POST", getValues()).then((resp) => {
        // console.log(resp)
        if (resp.code === 200) {
          console.log(resp);
          console.log('before_set')
          props.setData(resp.data.data)
          console.log('after set')
          // console.log(localStorage.getItem("at_requestor"));
          // localStorage.setItem("at_position", JSON.stringify({step: resp.data.step}));
          props.jump(resp.data.step);
        } else {
          setSubmitButtonLoading(false);
          console.log("error response: ", resp.message);
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (err) {
      setSubmitButtonLoading(false);
      console.log(err);
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const [load, setLoad] = useState(0);
  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }
  return (
    <React.Fragment>
      <Head title="Personal" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>About You</h5>
              <p>Please tell us about yourself.</p>
              <form className="content clearfix" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="gy-4 row pb-5">
                  <div className="entry">
                    <Row>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-xl-3 col-lg-3 col-md-3">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedTitle={watch("title")}
                          label="Title"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={title_options}
                          name="title"
                          color="#f5f5f5"
                          id="title"
                          placeholder="Select title"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-xl-5 col-lg-5 col-md-5">
                        <Input
                          type="text"
                          label="First Name"
                          id="name_first"
                          className="form-control-lg form-control"
                          name="name_first"
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          errors={errors}
                          register={register}
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-xl-4 col-lg-4 col-md-4">
                        <Input
                          disabled={false}
                          type="text"
                          label="Middle Name"
                          id="name_middle"
                          name="name_middle"
                          className="form-control-lg form-control"
                          errors={errors}
                          register={register}
                          requiredMessage={{
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                        ></Input>
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-xl-5 col-lg-5 col-md-5">
                        <Input
                          type="text"
                          label="Last Name"
                          id="name_last"
                          className="form-control-lg form-control"
                          name="name_last"
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          errors={errors}
                          register={register}
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-xl-2 col-lg-2 col-md-2">
                        <Input
                          type="text"
                          label="Suffix"
                          id="suffix"
                          className="form-control-lg form-control"
                          name="suffix"
                          errors={errors}
                          requiredMessage={{
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          register={register}
                        />
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-xl-5 col-lg-5 col-md-5">
                        <Input
                          type="text"
                          label="Also Known As"
                          id="name_alias"
                          className="form-control-lg form-control"
                          name="name_alias"
                          errors={errors}
                          register={register}
                          requiredMessage={{
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                        />
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedTitle={watch("name_change")}
                          label="Have you changed your legal name?"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={legal_name_options}
                          name="name_change"
                          color="#f5f5f5"
                          id="name_change"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>
                      {getValues("name_change") === "y" && (
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            onNameChange={watch("name_previous")}
                            required={watch("name_change") === "y"}
                            // requiredMessage={watch("name_change") === "y" && "This field is required"}
                            disabled={!watch("name_change") || watch("name_change") === "n"}
                            type="text"
                            label="Previous Name"
                            id="name_previous"
                            name="name_previous"
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            className="form-control-lg form-control"
                            register={register}
                            errors={watch("name_change") === "y" && errors}
                          ></Input>
                        </Col>
                      )}

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onNameChange={watch("pronoun")}
                          onselect={onselect}
                          setValue={setValue}
                          selectedTitle={watch("title")}
                          label="Pronoun"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={pronoun_options}
                          name="pronoun"
                          color="#f5f5f5"
                          id="pronoun"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>
                      {getValues("pronoun") === "Other" && (
                        <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                          <Input
                            onNameChange={watch("pronoun")}
                            requiredMessage={{
                              required: "This field is required",
                              pattern: {
                                value: /^.{1,20}$/,
                                message: "Max 20 characters",
                              },
                            }}
                            type="text"
                            label="Other"
                            id="other_pronoun"
                            name="other_pronoun"
                            className="form-control-lg form-control"
                            register={register}
                            errors={watch("pronoun") === "Other" && errors}
                          ></Input>
                        </Col>
                      )}

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-md-6">
                        {/* <Input
                          max={formatDate()}
                          required
                          requiredMessage={"This field is required"}
                          type="date"
                          label="Date of Birth"
                          id="dob"
                          name="dob"
                          className="form-control-lg form-control date-picker-alt"
                          register={register}
                          errors={errors}
                        ></Input> */}
                        <Row>
                          <div className="form-label-group">
                            <label className="form-label" htmlFor="default-01">
                              Date of Birth
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <Col className="mt-1 mb-1 col-sm-4 col-4 col-md-4">
                            <RSelect2
                              onNameChange={watch("day")}
                              onselect={onselect}
                              setValue={setValue}
                              // selectedTitle={watch("title")}
                              // label="date of birth"
                              requiredMessage="This field is required"
                              errors={errors}
                              register={register}
                              options={day_options}
                              name="day"
                              color="#f5f5f5"
                              id="sex"
                              className="form-control-lg p-0 border-0 form-control"
                              placeholder='day'
                            />
                          </Col>
                          <Col className="mt-1 mb-1 col-sm-4 col-4 col-md-4">
                            <RSelect2
                              onNameChange={watch("month")}
                              onselect={onselect}
                              setValue={setValue}
                              // selectedTitle={watch("title")}
                              // label="date of birth"
                              requiredMessage="This field is required"
                              errors={errors}
                              register={register}
                              options={month_options}
                              name="month"
                              color="#f5f5f5"
                              id="month"
                              className="form-control-lg p-0 border-0 form-control"
                              placeholder='Month'
                            />
                          </Col>
                          <Col className="mt-1 mb-1 col-sm-4 col-4 col-md-4">
                            <RSelect2
                              onNameChange={watch("year")}
                              onselect={onselect}
                              setValue={setValue}
                              // selectedTitle={watch("title")}
                              // label="date of birth"
                              requiredMessage="This field is required"
                              errors={errors}
                              register={register}
                              options={year_options}
                              name="year"
                              color="#f5f5f5"
                              id="year"
                              className="form-control-lg p-0 border-0 form-control"
                              placeholder='Year'
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onNameChange={watch("sex")}
                          onselect={onselect}
                          setValue={setValue}
                          selectedTitle={watch("title")}
                          label="Sex"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={sex_options}
                          name="sex"
                          color="#f5f5f5"
                          id="sex"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          max={formatDate()}
                          onNameChange={watch("name_previous")}
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          type="text"
                          label="Place of Birth"
                          id="pob"
                          name="pob"
                          className="form-control-lg form-control date-picker-alt"
                          register={register}
                          errors={errors}
                        ></Input>
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedCountry={watch("citizenship")}
                          label="Citizenship"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={countryOptions}
                          name="citizenship"
                          color="#f5f5f5"
                          id="citizenship"
                          placeholder="Select country"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          max={formatDate()}
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^[1-9][0-9]{0,8}$/,
                              message: "Please Enter max 9 digit number",
                            },
                          }}
                          type="text"
                          label="SSN/SIN/TAX ID"
                          id="sin"
                          name="sin"
                          className="form-control-lg form-control date-picker-alt"
                          register={register}
                          errors={errors}
                        ></Input>
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedCountry={watch("marital")}
                          label="Marital Status"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={marital_options}
                          name="marital"
                          color="#f5f5f5"
                          id="marital"
                          // placeholder="Select country"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          max={formatDate()}
                          onNameChange={watch("name_previous")}
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          type="text"
                          label="Occupation"
                          id="occupation"
                          name="occupation"
                          className="form-control-lg form-control date-picker-alt"
                          register={register}
                          errors={errors}
                        ></Input>
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <RSelect2
                          onselect={onselect}
                          setValue={setValue}
                          selectedCountry={watch("income")}
                          label="Annual Income"
                          requiredMessage="This field is required"
                          errors={errors}
                          register={register}
                          options={income_options}
                          name="income"
                          color="#f5f5f5"
                          id="income"
                          // placeholder="Select country"
                          className="form-control-lg p-0 border-0 form-control"
                        />
                      </Col>

                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          max={formatDate()}
                          onNameChange={watch("company")}
                          required
                          requiredMessage={{
                            required: "This field is required",
                            pattern: {
                              value: /^.{1,20}$/,
                              message: "Max 20 characters",
                            },
                          }}
                          type="text"
                          label="Employer"
                          id="company"
                          name="company"
                          className="form-control-lg form-control date-picker-alt"
                          register={register}
                          errors={errors}
                        ></Input>
                      </Col>
                      <Col className="mt-1 mb-1 col-sm-6 col-12 col-lg-6 col-xl-6 col-md-6">
                        <Input
                          max={formatDate()}
                          onNameChange={watch("website")}
                          type="text"
                          label="Employer Website"
                          id="website"
                          name="website"
                          requiredMessage={{
                            pattern: {
                              value: /^(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                              message: "Please input only domain like athenty.com",
                            },
                          }}
                          className="form-control-lg form-control date-picker-alt"
                          register={register}
                          errors={errors}
                        ></Input>
                      </Col>
                    </Row>

                    <div className="mt-3 nk-kycfm-note">
                      <em className=" icon ni ni-info-fill"></em>
                      <p>
                        The information above must be accurate to avoid false negatives in our authentication process.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
                <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                  <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                    <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                      {" "}
                      <Button color="primary" size="" className="" outline="">
                        {submitButtonLoading && <Spinner size="sm" />} Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Block>
          </div>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default Personal;

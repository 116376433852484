import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { apiRequest } from "../../utils/Api";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  Input,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import jwtDecode from "jwt-decode";

const ReverseVerifyRegister = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState("");
  const [errorVal, setError] = useState("");
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const checkToken = async () => {
    try {
      const token = queryParams.get("token");
      if (!token) navigate("/errors/401-classic", { state: { message: "The linkis invalid" } });
      getInfo();
    } catch (error) {
      navigate("/errors/401-classic", { state: { message: "The linkis invalid" } });
    }

    // if (!decodedToken) if (!token) navigate("/errors/401-classic", { state: { message: "The linkis invalid" } });
  };

  useEffect(() => {
    checkToken();
  }, []);

  const getInfo = async () => {
    const token = queryParams.get("token");
    console.log(token);
    await apiRequest(`/customer/reverse?token=${token}`, "GET").then((resp) => {
      console.log(resp);
      if (resp.code !== 200) {
        console.log("errrorrr");
      } else {
        setData(resp.data);
        setDefaultValue(resp.data);
      }
    });
  };

  const setDefaultValue = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  const onFormSubmit = async (formData) => {
    setError("");
    // console.log(formData.email.split('@'))
    try {
      setLoading(true);
      const fetchResponse = await apiRequest("/customer/reverse", "POST", getValues());
      // console.log(fetchResponse);
      if (fetchResponse.code === 200) {
        // console.log(fetchResponse.message)
        navigate("/auth-success", {
          state: {
            text: "A confirmation email with an accoutn setup link has been sent.",
            buttonText: "Confirm Your Email",
            emailVerify: `https://${formData.email.split("@")[1]}`,
          },
        });
        // localStorage.setItem("token", fetchResponse.message);
        // navigate('/customer')
        // setLoading(false);
      } else {
        console.log("error");
        setError(fetchResponse.message);
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Head title="Register" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Sign Up</BlockTitle>
              <BlockDes>
                <p>Transact and protect, safely and securely.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <Input
                errors={errors}
                required
                requiredMessage={{
                  required: "Full Name Is Required",
                  pattern: {
                    value: /^([\w]{3,})+\s+([\w\s]{3,})+$/i,
                    message: "Please Enter Full Name",
                  },
                }}
                register={register}
                placeholder="Enter Your Full Name"
                type="text"
                label="Full Name"
                id="full_name"
                name="full_name"
                className="form-control-lg form-control"
                defaultValue={data.name}
              ></Input>
            </div>
            <div className="form-group">
              <Input
                errors={errors}
                required
                requiredMessage="Company Name is Required"
                register={register}
                placeholder="Enter Your Company name"
                type="text"
                label="Company Name"
                id="company"
                name="company"
                className="form-control-lg form-control"
              ></Input>
            </div>
            <div className="form-group">
              <Input
                errors={errors}
                required
                requiredMessage={{
                  required: "Email Is Required",
                  pattern: {
                    value: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm,
                    message: "Email Is Invalid",
                  },
                }}
                register={register}
                placeholder="Enter Your Email Address"
                type="text"
                label="Email Address"
                id="email"
                name="email"
                className="form-control-lg form-control"
              ></Input>
            </div>
            <div className="form-group">
              <Input
                errors={errors}
                required
                requiredMessage={{
                  required: "Phone Number Is Required",
                  pattern: {
                    value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                    message: "Phone Number Is Invalid",
                  },
                }}
                register={register}
                placeholder="Enter Your Phone Number"
                type="text"
                label="Phone Number"
                id="phone"
                name="phone"
                className="form-control-lg form-control"
              ></Input>
            </div>
            {/* <ul className="list list-sm list-checked text-start">
			        <li>People who use our service may have authenticated your contact information through Athenty. <a style={{color :'#55b4c1'}} href="learnmorepageathenty">Learn more</a>.
			        </li>
			        <li>By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy. You may receive SMS notifications from us and can opt out any time.
			        </li>
		        </ul> */}
            <Row className="gy-4">
              <Col xl="12">
                <div className="preview-block">
                  <div className="g-3 align-center flex-wrap">
                    <div className="g">
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check1"
                          {...register("check1", { required: "You Need To Accept" })}
                        />
                        {errors.check1 && <span className="invalid">{errors.check1.message}</span>}
                        <label className="custom-control-label" htmlFor="check1">
                          People who use our service may have authenticated your contact information through Athenty.{" "}
                          <a style={{ color: "#55b4c1" }} href="learnmorepageathenty">
                            Learn more
                          </a>
                          .
                        </label>
                        {errors.check1 && <p className="invalid">This field is required</p>}
                      </div>
                    </div>
                    <div className="g"></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="gy-4">
              <Col xl="12">
                <div className="preview-block">
                  <div className="g-3 align-center flex-wrap">
                    <div className="g">
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check2"
                          {...register("check2", { required: "You Need To Accept" })}
                        />
                        {errors.check2 && <span className="invalid">{errors.check2.message}</span>}
                        <label className="custom-control-label" htmlFor="check2">
                          By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy. You may
                          receive SMS Notifications from us and can opt out any time.
                        </label>
                        {errors.check2 && <p className="invalid">This field is required</p>}
                      </div>
                    </div>
                    <div className="g"></div>
                  </div>
                </div>
              </Col>
            </Row>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <div className="form-group">
              <Button type="submit" color="primary" size="lg" className="btn-block">
                {/* {loading ? <Spinner size="sm" color="light" /> : "Register"} */}
                {loading ? <Spinner size="sm" color="light" /> : "Sign up"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            {" "}
            Already have an account?{" "}
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
    </>
  );
};
export default ReverseVerifyRegister;

import React, { useEffect, useState } from "react";
import Head from "../../../../../../layout/head/Head";
import { ReactDataTable } from "../../../../../../components/Component";
import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockDes,
} from "../../../../../../components/Component";

const ActivitiesColumns = [
  {
    name: "Browser",
    selector: (row) => row.name,
    grow: 0.75,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">{row.browser} </div>
      </div>
    ),
  },
  {
    name: "IP",
    grow: 0.5,
    selector: (row) => row.type,
    hide: "sm",
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">{row.ip} </span>
        </div>
      </div>
    ),
  },
  {
    name: "Time",
    grow: 0.5,
    selector: (row) => row.id,
    hide: 370,
    cell: (row) => (
      <div className="user-card">
        <div className="user-info">
          <span className="tb-lead">{row.time} </span>
        </div>
      </div>
    ),
  },
  // {
  //   name: "Request source IP",
  //   grow: 2,
  //   selector: (row) => row.type,
  //   sortable: true,
  //   compact: true,
  //   hide: "sm",
  //   cell: (row) => (
  //     <div className="user-card mt-2 mb-2">
  //       <div className="user-info">
  //         <span className="tb-lead">
  //           {row.date}{" "}
  //           <span
  //             className={`dot dot-${
  //               row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
  //             } d-md-none ms-1`}
  //           ></span>
  //         </span>
  //       </div>
  //     </div>
  //   ),
  // },
];

const CustomerProfileActivity = (props) => {
  const [activitiesData, setActivitiesData] = useState(props.data);
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Login Activity</BlockTitle>
            <BlockDes>
              <p>Here is your last 20 login activities log. </p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {activitiesData && (
        <ReactDataTable data={activitiesData} columns={ActivitiesColumns} pagination search={false} show={false} />
      )}
      {/* <LoginLogTable /> */}
    </React.Fragment>
  );
};

export default CustomerProfileActivity;

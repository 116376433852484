import React, { useRef, useState } from "react";
import Content from "../../../../layout/content/Content";
import { Block, BlockAthenty, Icon, Loading } from "../../../../components/Component";
import { Card, Toast, ToastBody, ToastHeader } from "reactstrap";
import OrganizationGeneral from "./general/OrganizationGeneral";

const Organization = () => {
  const [loading, setLoading] = useState();
  const [selectedTab, setSelectedTab] = useState("general");
  const submitRef = useRef();

  const asignRef = () => {
    return submitRef.current.click();
  };

  return (
    <React.Fragment>
      <Content>
        <BlockAthenty
          head={"Organization"}
          nav="Admin"
          title={"Organization"}
          description={"View and update your organization's information"}
          buttons={[
            {
              text: "Save",
              icon: "save",
              link: "",
              onclick: asignRef,
              tooltip: "Save Chnages",
              id: "save-button",
              type: "submit",
              form: "my-form",
            },
          ]}
        />
        {loading && <Loading />}
        {!loading && (
          <Block>
            <Card className="card-bordered">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li
                  onClick={() => setSelectedTab("general")}
                  className={`nav-item ${selectedTab === `general` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "general" ? "active" : ""}`}>
                    <Icon name="user"></Icon>
                    <span>General</span>
                  </div>
                </li>
              </ul>

              <div className="card-inner card-inner-lg">
                {selectedTab === "general" && <OrganizationGeneral submitRef={submitRef} setLoading={setLoading} />}
                {/* {selectedTab === 'notification' && <OrganizationNotification/>} */}
                {/* {selectedTab === 'security' && <OrganizationSecurity/>} */}
                {/* {selectedTab === 'setting' && <OrganizationSetting/>} */}
              </div>
            </Card>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Organization;

import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Block, Button, DocumentScan } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Document, Page, pdfjs } from "react-pdf";
import { Row } from "reactstrap";
import pdfFile from "./document5.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const Pdf = () => {
  const [text, setText] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [data, setData] = useState();

  const getPdf = async () => {
    console.log("get pdf");

    await apiRequest(`/customer/a/pdf/5a706c87-2fdc-4c8a-87c5-203b9ffe1014?base64=true`, "get").then(
      async (response) => {
        console.log(response.data);
        setData(response.data);
        // fileDownload(response, "test.pdf");
        // setData(response);
        // const file = window.URL.createObjectURL(response);
        // const iframe = document.querySelector("iframe");
        // if (iframe?.src) iframe.src = file;
        // // const blob = new Blob([response], { type: "application/pdf" });
        // // const blobUrl = URL.createObjectURL(blob);
        // // const link = document.createElement("a");
        // // link.href = blobUrl;
        // // console.log(blob);
        // // document.body.appendChild(link);
        // // link.click();
      },
    );
  };

  useEffect(() => {
    // const handleContextmenu = (e) => {
    //   e.preventDefault();
    // };
    // document.addEventListener("contextmenu", handleContextmenu);
    // return function cleanup() {
    //   document.removeEventListener("contextmenu", handleContextmenu);
    // };
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const prevPage = () => {
    setPageNumber(pageNumber <= 1 ? 1 : pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? pageNumber : pageNumber + 1);
  };
  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      <Row>
        <div className="align-items-center justify-content-center">
          <div>
            <i className="bi bi-arrow-left-square-fill display-5 text-danger pointer" onClick={prevPage} role="button">
              prev
            </i>
            <i className="bi bi-arrow-right-square-fill display-5 text-danger pointer" onClick={nextPage} role="button">
              next
            </i>
          </div>
          {data && (
            <Document file={`data:application/pdf;base64,${data}`} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          )}
        </div>
      </Row>
      <Content>
        <Row>
          <Button onClick={getPdf}>get pdf</Button>
        </Row>
        <Row>{data && <embed style={{ width: "100%", height: "70vh" }} src={data} />}</Row>
      </Content>
    </React.Fragment>
  );
};

export default Pdf;

import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, Col, Row, BlockAthenty, Loading, Icon } from "../../../../../components/Component";
import { Link, useLocation } from "react-router-dom";
import { Card, Badge, Modal, ModalBody, Button } from "reactstrap";
import { apiRequest } from "../../../../../utils/Api";
import { toast, ToastContainer } from "react-toastify";

const Subscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const location = useLocation();
  const [modalCancel, setMocalCancel] = useState(false);
  const [packageId, setPackageId] = useState();

  const toggleModalCancel = (id) => {
    setPackageId(id ? id : "");
    setMocalCancel(!modalCancel);
  };

  useEffect(() => {
    // console.log(location)
    if (location.state === "packageAdded") {
      toast.success("Package Added Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    getSubscriptionsList();
  }, []);

  const getSubscriptionsList = async () => {
    setSubscriptionData();
    setLoading(true);
    try {
      await apiRequest("/customer/a/package/list", "GET").then((resp) => {
        console.log(resp.data);
        setSubscriptionData(resp.data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      // console.log(error);
      toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        onClose: "",
      });
    }
  };

  const cancelPackage = () => {
    setLoading(true);
    setMocalCancel(!modalCancel);
    try {
      apiRequest("/customer/a/package/cancel", "DELETE", {
        package_id: packageId,
      }).then((resp) => {
        setLoading(true);
        getSubscriptionsList();
        if (resp.code === 200) {
          toast.success("Package Canceled", {
            autoClose: 2000,
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          setLoading(false);
          toast.error("Something Went Wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <div>
          <BlockAthenty
            head={"Subscriptions"}
            nav="Admin / Billing"
            title={"Subscriptions"}
            // description={"Your current subscriptions and licenses."}
            description={"Your current subscriptions."}
            // back = {'/customer/admin/billing'}
            id="save-button"
            buttons={[
              {
                id: "save-button",
                icon: "plus",
                linkTo: "/customer/admin/billing/subscription/add",
                tooltip: "Add Subscription",
                className: "btn-icon",
              },
            ]}
          />

          <Content>
            <Block>
              {subscriptionData && subscriptionData.length < 1 && (
                <div className="sc-beqWaB ewhdCu rdt_Table" role="table">
                  <div className="sc-ivnCJf gUHoGv">
                    <div className="p-2">No subscription found</div>
                  </div>
                </div>
              )}
              {subscriptionData &&
                subscriptionData.length > 0 &&
                subscriptionData.map((item, index) => (
                  <Card key={index} className="card-bordered sp-plan">
                    <Row className="no-gutters">
                      <Col md="8">
                        <div className="sp-plan-info card-inner">
                          <Row className="gx-0 gy-3">
                            <Col xl="9" sm="8">
                              <div className="sp-plan-name">
                                <h6 className="title">
                                  {/* <Link to={`${process.env.PUBLIC_URL}/subscription/subscription-details/${item.id}`}> */}
                                  {item.name}{" "}
                                  <Badge className={`rounded-pill ${item.status_color}`}>{item.status}</Badge>
                                  {/* </Link> */}
                                </h6>
                                <p>
                                  Product: <span className="text-base">{item.description}</span>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="sp-plan-desc card-inner">
                          <Row className="gx-1">
                            <Col col="6" lg="3">
                              <p>
                                <span className="text-soft">Order Date</span> {item.order_date}
                              </p>
                            </Col>
                            <Col col="6" lg="3">
                              <p>
                                <span className="text-soft">Purchased Licenses</span> {item.quantity}
                              </p>
                            </Col>
                            <Col col="6" lg="3">
                              <p>
                                <span className="text-soft">Assigned Licenses</span> {item.assigned}
                              </p>
                            </Col>

                            <Col col="6" lg="3">
                              <p>
                                <span className="text-soft">Remaining Licenses</span> {item.remaining}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="sp-plan-action card-inner">
                          <div className="sp-plan-btn">
                            {/* <div className="btn btn-danger" href='/#' onClick={() => setSubscriptionChangeModal(true)}> */}
                            {/* <div className="disabled btn btn-danger" href="/#">
                              <span onClick={() => toggleModalCancel(item.id)}>Cancel</span>
                            </div> */}
                          </div>
                          <div className="sp-plan-note text-md-center">
                            <p>
                              {item.billdate ? <>Next Billing on  <span>{item.billdate}</span></> : <span></span>}

                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ))}
            </Block>
          </Content>
          <Modal isOpen={modalCancel} toggle={toggleModalCancel}>
            <ModalBody className="modal-body-lg text-center">
              <div className="nk-modal">
                <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
                <h4 className="nk-modal-title">Are you sure you want to cancel this package?</h4>

                <div className="nk-modal-action mt-5">
                  <Button color="light" size="lg" className="btn-mw me-2" onClick={() => toggleModalCancel()}>
                    Cancel
                  </Button>
                  <Button color="danger" size="lg" className="btn-mw" onClick={() => cancelPackage()}>
                    Confirm
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}

      <ToastContainer autoClose={2000} />
    </React.Fragment>
  );
};

export default Subscriptions;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Block, BlockContent, BlockHead, Button } from "../../components/Component";
import Head from "../../layout/head/Head";

const Error503Classic = () => {
  const navigate = useNavigate();
  return (
    <>
      <Head title="ERROR 503" />}
      <Block className="nk-block-middle wide-xs mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <h1 className="nk-error-head">503</h1>
          <h3 className="nk-error-title">Oops! Something went wrong</h3>
          <p className="nk-error-text">
            We apologize for the inconvenience. It seems there's a technical issue preventing access to this page. Our
            team has been notified and is working to resolve the problem. Please try again later. Thank you for your
            understanding.
          </p>

          <Button color="primary" size="lg" className="mt-2" onClick={() => navigate(-1)}>
            Try again
          </Button>
        </BlockContent>
      </Block>
    </>
  );
};
export default Error503Classic;

import React, { useState } from "react";
import { Icon } from "../../Component";

const Textarea = ({
  id,
  label,
  type,
  required,
  autoComplete = "off",
  name,
  disabled,
  onChange,
  className,
  page,
  register = function () {},
  errors,
  capture,
  requiredMessage,
  accept,
  display,
  placeholder,
  min,
  max,
  defaultValue,
}) => {
  const [focus, setFocus] = useState(false);
  return (
    <div className="form-group pt-1">
      <label className="form-label" htmlFor="name">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="form-control-wrap">
        <textarea
          min={min}
          max={max}
          capture={capture}
          type={type}
          id={id}
          accept={accept}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          style={{ height: "38px", display: display === "none" ? "none" : "" }}
          {...register(name, { required: requiredMessage })}
          // onChange={(e)=> type === 'file' && onChange ? onChange(name,e.target.files[0]) : onchange }
          autoComplete={autoComplete}
          placeholder={placeholder}
          className={"p-1 form-control-lg form-control" + className}
        />
        {errors && errors[name] && <p className="invalid">{errors[name].message}</p>}
      </div>
    </div>
    // <div className="form-control-wrap">
    //     <span className="nk-kycfm-label-text"><b>{label}</b>{required && (<span className="text-danger">&nbsp; *</span>)}</span>
    // <div className="input-group input-group-md">
    //     <textarea onChange={onChange} id={id} name={name} className="form-control" required={required} disabled={disabled} style={{height:'38px'}}/>
    // </div>
    // </div>
  );
};

export default Textarea;

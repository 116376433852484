import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};

function Camera() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const countdownRef = useRef(2); // Countdown in seconds
  const [imgSrc, setImgSrc] = useState(null);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState("");

  useEffect(() => {
    const orientationChange = () => {
      if (window.innerWidth < window.innerHeight) {
        setError("Please rotate your device to landscape mode.");
      } else {
        setError("");
      }
    };

    orientationChange();
    window.addEventListener("resize", orientationChange);
    return () => window.removeEventListener("resize", orientationChange);
  }, []);

  const handleStart = () => {
    if (error) {
      alert(error);
      return;
    }
    setStatus("detecting");
  };

  const handleCapture = () => {
    if (countdownRef.current > 0) {
      setTimeout(handleCapture, 1000); // Countdown timer
      countdownRef.current -= 1;
      setStatus(`Capture in ${countdownRef.current}...`);
    } else {
      capture();
      countdownRef.current = 2; // Reset countdown
    }
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setStatus("captured");
  };

  useEffect(() => {
    if (status === "detecting") {
      handleCapture();
    }
  }, [status]);

  return (
    <div>
      {status === "idle" && (
        <>
          <button onClick={handleStart}>Take Picture</button>
          {error && <div className="error">{error}</div>}
        </>
      )}
      {status.indexOf("Capture in") !== -1 && <div>{status}</div>}
      {status === "detecting" && (
        <>
          <Webcam
            audio={false}
            height={720}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          />
          <canvas ref={canvasRef} width={1280} height={720} />
        </>
      )}
      {imgSrc && status === "captured" && (
        <div>
          <h2>Preview</h2>
          <img src={imgSrc} alt="Captured" />
          <button onClick={() => setStatus("idle")}>Reset</button>
        </div>
      )}
    </div>
  );
  //   const { loaded, cv } = useOpenCv(); // Assuming `useOpenCv` hook which controls the loading of OpenCV and provides the cv object
  //   const videoRef = useRef(null);
  //   const [orientationError, setOrientationError] = useState(false);
  //   const [photo, setPhoto] = useState(null);
  //   const [countdown, setCountdown] = useState(null);

  //   useEffect(() => {
  //     handleOrientationChange();
  //     window.addEventListener("resize", handleOrientationChange);
  //     return () => {
  //       window.removeEventListener("resize", handleOrientationChange);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     if (countdown > 0) {
  //       setTimeout(() => setCountdown(countdown - 1), 1000);
  //     } else if (countdown === 0) {
  //       captureImage();
  //     }
  //   }, [countdown]);

  //   const handleOrientationChange = () => {
  //     if (window.innerHeight > window.innerWidth) {
  //       setOrientationError(true);
  //     } else {
  //       setOrientationError(false);
  //     }
  //   };

  //   const startCamera = () => {
  //     if (!loaded || orientationError) {
  //       return;
  //     }
  //     const constraints = { video: { facingMode: "environment" } };
  //     navigator.mediaDevices
  //       .getUserMedia(constraints)
  //       .then((stream) => {
  //         if (videoRef.current) {
  //           videoRef.current.srcObject = stream;
  //           processVideo();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing the media devices.", error);
  //       });
  //   };

  //   const processVideo = () => {
  //     const video = videoRef.current;
  //     if (!video) return;

  //     const src = new cv.Mat(video.height, video.width, cv.CV_8UC4);
  //     const dst = new cv.Mat(video.height, video.width, cv.CV_8UC1);
  //     const cap = new cv.VideoCapture(video);

  //     const processFrame = () => {
  //       try {
  //         cap.read(src);
  //         cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY, 0);
  //         cv.Canny(dst, dst, 50, 100, 3, false);

  //         if (cv.countNonZero(dst) > 10000 && countdown === null) {
  //           setCountdown(3); // start countdown
  //         }

  //         cv.imshow("canvasOutput", dst); // Display in canvas
  //         requestAnimationFrame(processFrame);
  //       } catch (err) {
  //         console.log("Error processing video:", err);
  //       }
  //     };
  //     processFrame();
  //   };

  //   const captureImage = () => {
  //     const video = videoRef.current;
  //     const canvas = document.createElement("canvas");
  //     canvas.width = video.videoWidth;
  //     canvas.height = video.videoHeight;
  //     const ctx = canvas.getContext("2d");
  //     ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  //     setPhoto(canvas.toDataURL("image/png"));
  //     video.srcObject.getTracks().forEach((track) => track.stop()); // stop camera
  //   };

  //   return (
  //     <div>
  //       {!loaded ? (
  //         <p>Loading OpenCV...</p>
  //       ) : orientationError ? (
  //         <p>Error: Rotate your device to landscape.</p>
  //       ) : (
  //         <button onClick={startCamera}>Start Camera</button>
  //       )}
  //       {countdown && <p>Countdown: {countdown}</p>}
  //       <video ref={videoRef} style={{ display: "none" }} autoPlay playsInline muted></video>
  //       <canvas id="canvasOutput" style={{ width: "100%" }}></canvas>
  //       {photo && <img src={photo} alt="Captured document" style={{ width: "100%" }} />}
  //     </div>
  //   );
}

export default Camera;

import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import { useForm } from "react-hook-form";
import { Steps, Step } from "react-step-builder";
import { Row, Col, Button } from "reactstrap";
import { countries } from "../data/country";
const PersonalForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const submitForm = (data) => {
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <h5 className="title">Personal Details</h5>
      <p className="sub-title">Enter your profile details</p>
      <Row className="gy-4">
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="first-name">
              First Name<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="first-name"
                className="form-control"
                {...register("name_first", { required: true })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.name_first}
              />
              {errors.name_first && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="last-name">
              Last Name<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="last-name"
                className="form-control"
                {...register("name_last", { required: true })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.name_last}
              />
              {errors.name_last && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="work-position">
              Work Position<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="work-position"
                className="form-control"
                {...register("position", {
                  required: true,
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.position}
              />
              {errors.position && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Email<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                style={{
                  backgroundColor: "#f5f6fa",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  msUserSelect: "none",
                }}
                readOnly
                type="text"
                id="email"
                className="form-control "
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.email}
              />
              {errors.email && errors.email.type === "required" && (
                <span className="invalid">This field is required</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="invalid">{errors.email.message}</span>
              )}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <div className="form-control-wrap">
              <input
                type="password"
                id="password"
                className="form-control"
                {...register("password1", {
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g,
                    message: "Password must contain upper case,lower case,number and character",
                  },
                  required: "This field is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.password1}
              />
              {errors.password1 && <span className="invalid">{errors.password1.message}</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="password2">
              Re-Password
            </label>
            <div className="form-control-wrap">
              <input
                type="password"
                id="password2"
                className="form-control"
                {...register("password2", {
                  required: "This field is required",
                  validate: (value) => value === getValues("password1") || "The passwords do not match",
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.password2}
              />
              {errors.password2 && <span className="invalid">{errors.password2?.message}</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="direct-no">
              Direct Number
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                maxLength="10"
                id="direct-no"
                className="form-control"
                {...register("phone_work", {
                  required: "This field is required",
                  pattern: {
                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    message: "Please input valid phone number",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
              />
              {errors.phone_work && <span className="invalid">{errors.phone_work.message}</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="mobile-no">
              Mobile Phone
            </label>

            <div className="form-control-wrap">
              <input
                id="mobile-no"
                className="form-control"
                {...register("phone_mobile", {
                  required: "This field is required",
                  pattern: {
                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    message: "Please input valid phone number",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.phone_mobile}
              />
              {errors.phone_mobile && <span className="invalid">{errors.phone_mobile.message}</span>}
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end" }} className="actions clearfix ">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const CompanyInfo = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submitForm = (data) => {
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <h5 className="title">Organization's Info</h5>
      <p className="sub-title">Understanding your use case</p>
      <Row className="gy-4">
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="company-name">
              Company Name<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="company-name"
                className="form-control"
                {...register("company_name", { required: true })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.company_name}
              />
              {errors.company_name && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="company-phone">
              Company Phone<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="company-phone"
                className="form-control"
                {...register("company_phone", {
                  required: "This field is required",
                  pattern: {
                    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    message: "Please input valid phone number",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.company_phone}
              />
              {errors.company_phone && <span className="invalid">{errors.company_phone.message}</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="company-size">
              Company Size<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control form-select"
                  id="fv-topics"
                  {...register("company_size", {
                    required: true,
                  })}
                  onChange={(e) => props.onInputChange(e)}
                  placeholder="Select a option"
                  defaultValue={props.formData.company_size}
                >
                  <option label="Select a Size" value=""></option>
                  <option value="01-10">01-10</option>
                  <option value="10-20">10-20</option>
                  <option value="20-30">'20-30</option>
                  <option value="30-50">30-50</option>
                  <option value="50-100">50-100</option>
                  <option value="above-100">Above 100</option>
                </select>
                {errors.company_size && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="company-type">
              Company Type<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control form-select"
                  id="fv-topics"
                  {...register("company_type", {
                    required: true,
                  })}
                  onChange={(e) => props.onInputChange(e)}
                  placeholder="Select a option"
                  defaultValue={props.formData.company_type}
                >
                  <option label="Select a Type" value=""></option>
                  <option value="1">Corporation</option>
                  <option value="2">Unlimited Liability Company (ULC)</option>
                  <option value="3">'Limited Liability Company (LLC)</option>
                  <option value="4">General Partnership (GP)</option>
                  <option value="5">Limited Partnership (LP)</option>
                  <option value="6">Limited Liability Partnership (LLP)</option>
                  <option value="7">Joint Venture or Syndicate</option>
                  <option value="8">Trust</option>
                </select>
                {errors.company_type && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end" }} className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const Location = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitForm = (data) => {
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <h5 className="title">Organization's Location</h5>
      <p className="sub-title">Regional address information</p>
      <Row className="gy-3">
        <Col md="12">
          <div className="form-group">
            <label className="form-label" htmlFor="address-1">
              Adreess<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="address-1"
                className="form-control"
                {...register("address1", {
                  required: "This field is required",
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.address1}
              />
              {errors.address1 && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="12">
          <div className="form-group">
            <label className="form-label" htmlFor="address-2">
              Adreess 2
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="address-2"
                className="form-control"
                {...register("address2", {
                  required: false,
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.address2}
              />
              {errors.address2 && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="city">
              City<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="city"
                className="form-control"
                {...register("city", {
                  required: "This field is required",
                  minLength: {
                    value: 6,
                    message: "Password must have at least 6 characters",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.city}
              />
              {errors.city && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="company-type">
              Country<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control form-select"
                  id="fv-topics"
                  {...register("country", {
                    required: true,
                  })}
                  onChange={(e) => props.onInputChange(e)}
                  placeholder="Select a option"
                  defaultValue={props.formData.country}
                >
                  <option label="Select a Country" value=""></option>
                  <option value="CA">Canada</option>
                  <option value="US">United States</option>
                </select>
                {errors.country && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="company-type">
              Provice/State<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control form-select"
                  id="fv-topics"
                  {...register("region", {
                    required: true,
                  })}
                  disabled={props.formData.country === "" ? true : false}
                  onChange={(e) => props.onInputChange(e)}
                  placeholder="Select a option"
                  defaultValue={props.formData.region}
                >
                  <option value="">Select a Province/State</option>

                  {props.formData.country === "CA" &&
                    countries.canada.map((state) => {
                      return (
                        <option key={state.abbreviation} value={state.abbreviation}>
                          {state.name}
                        </option>
                      );
                    })}
                  {props.formData.country === "US" &&
                    countries.unitedStates.map((state) => {
                      return (
                        <option key={state.abbreviation} value={state.abbreviation}>
                          {state.name}
                        </option>
                      );
                    })}
                </select>
                {errors.region && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </div>
        </Col>

        <Col md="6">
          <div className="form-group">
            <label className="form-label" htmlFor="city">
              Zip/Postal<span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="city"
                className="form-control"
                disabled={props.formData.region === "" ? true : false}
                {...register("postal", {
                  required: "This field is required",
                  minLength: {
                    value: 6,
                    message: "Password must have at least 6 characters",
                  },
                })}
                onChange={(e) => props.onInputChange(e)}
                defaultValue={props.formData.postal}
              />
              {errors.postal && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end" }} className="actions clearfix">
        <ul>
          <li>
            <Button className="float-right" color="primary" type="submit">
              Submit
            </Button>
          </li>
          <li>
            <Button className="float-right" color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const Header = (props) => {
  return (
    <div className="steps clearfix">
      <ul>
        <li className={props.current >= 1 ? "first done" : "first"}></li>
        <li className={props.current >= 2 ? "done" : ""}></li>
        <li className={props.current >= 3 ? "done" : ""}></li>
        <li className={props.current === 4 ? "last done" : "last"}></li>
      </ul>
    </div>
  );
};

const Success = (props) => {
  return (
    <div className="d-flex justify-content-center align-items-center p-3">
      <BlockTitle tag="h6" className="text-center">
        Thank you for submitting form
      </BlockTitle>
    </div>
  );
};

const config = {
  before: Header,
};

const SignupVerify = () => {
  const [formData, setFormData] = useState({
    name_first: "",
    name_last: "",
    position: "",
    email: "",
    password1: "",
    password2: "",
    phone_work: "",
    phone_mobile: "",
    company_name: "",
    company_phone: "",
    company_size: "",
    company_type: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    region: "",
    postal: "",
  });

  const submitForm = () => {
    // console.log(formData)
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <Block className="nk-block-middle nk-auth-body  wide-xl">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <Head title="Smart Verify" />
          <Content page="component">
            <Block size="lg">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5">Welcome</BlockTitle>
                  <p>Let's get your account ready for first time use</p>
                </BlockHeadContent>
              </BlockHead>
              <PreviewCard>
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <Steps config={config}>
                    <Step component={PersonalForm} formData={formData} onInputChange={onInputChange} />
                    <Step component={CompanyInfo} formData={formData} onInputChange={onInputChange} />
                    <Step
                      component={Location}
                      formData={formData}
                      onInputChange={onInputChange}
                      submitForm={submitForm}
                    />
                    <Step component={Success} formData={formData} setFormData={setFormData} />
                  </Steps>
                </div>
              </PreviewCard>
            </Block>
          </Content>
        </PreviewCard>
      </Block>
    </React.Fragment>
  );
};

export default SignupVerify;

import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, Input, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../utils/Api";

const ForgotPassword = () => {
  const [loading, setLoading] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();

  const submitData = async () => {
    await apiRequest(`/customer/reset-password/`, "POST", getValues()).then((resp) => {
      // console.log(resp.data);
      if (resp.code === 200) {
        console.log(resp.data);
        setLoading(false);
        toast.success(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    });
  }
  return (
    <>
      <Head title="Forgot-Password" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className=" is-alter content clearfix" onSubmit={handleSubmit(submitData)}>
            <div className="form-group">
              <Input
                errors={errors}
                required
                requiredMessage={{
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter valid email address",
                  },
                }}
                register={register}
                type="text"
                label="Email"
                id="email"
                name="email"
                className="form-control-l form-control"
              />
            </div>
            <div className="form-group">
              <Button color="primary" size="lg" className="btn-block" >
                Send Reset Link
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Return to login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block >
      <AuthFooter />
      <ToastContainer />
    </>
  );
};
export default ForgotPassword;

import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, ClientListTable, Loading, PreviewAltCard } from "../../../../../components/Component";
import { Card } from "reactstrap";
import { apiRequest } from "../../../../../utils/Api";
import { transactionsTableColumns } from "../../../../components/table/TableData";
import { toast } from "react-toastify";

const Transactions = () => {
  const [transactionData, setTransactionData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getInvoiceList();
  }, []);

  const getInvoiceList = async () => {
    setLoading(true);
    try {
      await apiRequest("/customer/a/transaction/list", "GET").then((resp) => {
        if (resp.code === 200) {
          setTransactionData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Transaction History"}
            nav="Admin / Billing"
            title={"Transaction History"}
            description={"Your transaction history."}
            // buttons={[
            //   {
            //     text : '',
            //     icon: '',
            //     link: '',
            //     onclick : '',
            //     tooltip : 'test'
            //   }
            // ]}
          />

          <Block>
            <Card>
              {/* {transactionData && <TransactionTable data = {transactionData}/>} */}
              {transactionData && (
                <PreviewAltCard>
                  <ClientListTable
                    data={transactionData}
                    columns={transactionsTableColumns}
                    pagination
                    className="nk-tb-list"
                  />
                </PreviewAltCard>
              )}
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default Transactions;

import React from "react";
import Select from "react-select";

const RSelect = ({ ...props }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 42,
      minHeight: 35,
    }),
  };
  return (
    <div className="form-control-select">
      <Select
        className={`react-select-container  ${props.className ? props.className : ""}`}
        classNamePrefix="react-select"
        styles={customStyles}
        {...props}
      />
    </div>
  );
};

export default RSelect;

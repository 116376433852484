const InputRadio = ({
  register = function () {},
  requiredMessage,
  setValue,
  errors,
  className,
  label,
  id,
  selectedValue,
  name,
  value,
}) => {
  return (
    <div className="form-group">
      <div className="form-control-wrap custom-control custom-radio">
        <input
          {...register(name, { required: requiredMessage })}
          type="radio"
          className={"form-control custom-control-input " + className}
          checked={selectedValue === value}
          id={id}
          name={name}
          onSelect={(e) => setValue(name, id)}
          value={value}
        />
        <label className="custom-control-label form-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
    // <div className="form-control-select mb-2">
    // <div style={{paddingLeft: "45px"}} className="custom-control-group ">
    //     <div className="form-group custom-control custom-radio form-control-wrap">
    //         <input
    //             {...register(name, { required: requiredMessage })}
    //             type="radio"
    //             className={"form-control custom-control-input "+className}
    //             checked={selectedValue === value}
    //             id={id}
    //             name={name}
    //             onSelect={(e) => setValue(name,'test')}
    //             value={value}
    //         />
    //
    //     </div>

    // </div>
    // </div>
  );
};

export default InputRadio;

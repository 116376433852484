import React, { useEffect, useState } from "react";
import Head from "../../../../../../layout/head/Head";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockContent,
  BlockBetween,
  BlockDes,
  InputSwitch,
  Loading,
} from "../../../../../../components/Component";
import { Col, Row } from "reactstrap";
import { apiRequest } from "../../../../../../utils/Api";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

const CustomerProfileActivity = ({ submitRef }) => {
  const [setting, setSetting] = useState();
  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    setLoading(true);
    await apiRequest("/customer/a/notifications/setting", "GET").then((resp) => {
      console.log(resp);

      setSetting(resp.data.setting);
      console.log(resp.data.setting);
      setLoading(false);
    });
  };

  const setChange = (e, category) => {
    console.log(e.target.checked);

    setSetting((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [e.target.name]: e.target.checked,
      },
    }));
    setTimeout(() => {
      console.log(setting);
    }, 3000);
  };

  const onSave = async () => {

    try {

      const resp = await apiRequest("/customer/a/notifications/setting", "PUT", setting);

      if (resp && resp.code === 200) {
        toast.success("Your information is saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(resp.message || "Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  return (
    <React.Fragment>
      <Head title="Customer Profile" />

      <Block size="lg">
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Notification Settings</BlockTitle>
              <BlockDes>
                <p>You will receive the following enabled notifications</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {loading && <Loading />}
        {!loading && (
          <>
            <form onSubmit={handleSubmit(onSave)}>
              <Row className="mb-4">
                <Col>
                  <BlockHead size="sm">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h6">Security Alerts</BlockTitle>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <BlockContent>
                    <div className="gy-3">
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="security"
                            checked={setting.security.unusual_activity}
                            setChange={setChange}
                            id="unusual_activity"
                            label="Email me when unusual activity is encountered"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="security"
                            checked={setting.security.browser_login}
                            setChange={setChange}
                            id="browser_login"
                            label="Email me if a new browser is used to login"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="security"
                            checked={setting.security.mobile_login}
                            setChange={setChange}
                            id="mobile_login"
                            label="Notify me if a new mobile device is used to login"
                          />
                        </div>
                      </div>
                    </div>
                  </BlockContent>
                </Col>
                <Col>
                  <BlockHead size="sm">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h6">News</BlockTitle>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <BlockContent>
                    <div className="gy-3">
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="news"
                            checked={setting.news.sales}
                            setChange={setChange}
                            id="sales"
                            label="Notify me by email about sales and latest news"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="news"
                            checked={setting.news.features}
                            setChange={setChange}
                            id="features"
                            label="Email me about new features and updates"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="news"
                            checked={setting.news.tips}
                            setChange={setChange}
                            id="tips"
                            label="Email me about tips on using my account"
                          />
                        </div>
                      </div>
                    </div>
                  </BlockContent>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <BlockHead size="sm">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h6">Smart Verify Alerts</BlockTitle>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <BlockContent>
                    <div className="gy-3">
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="smart"
                            checked={setting.smart.verification_started}
                            setChange={setChange}
                            id="verification_started"
                            label="Notify me when a verification has started"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="smart"
                            checked={setting.smart.verification_review}
                            setChange={setChange}
                            id="verification_review"
                            label="Notify me when a verification requires my review"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="smart"
                            checked={setting.smart.verification_completed}
                            setChange={setChange}
                            id="verification_completed"
                            label="Notify me when a verification has been completed"
                          />
                        </div>
                      </div>
                    </div>
                  </BlockContent>
                </Col>
                <Col>
                  <BlockHead size="sm">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h6">Verification Report</BlockTitle>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <BlockContent>
                    <div className="gy-3">
                      <div className="g-item">
                        <div className="custom-control custom-switch">
                          <InputSwitch
                            category="verification"
                            checked={setting.verification.download_report}
                            setChange={setChange}
                            id="download_report"
                            label="Notify me when a verification has beed downloaded by third-party"
                          />
                        </div>
                      </div>
                    </div>
                  </BlockContent>
                </Col>
              </Row>
              <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
            </form>
          </>
        )}
      </Block>
      <ToastContainer />
    </React.Fragment>
  );
};

export default CustomerProfileActivity;

import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { Block, BlockAthenty, Button, Icon, Input } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Link } from "react-router-dom";
import LogoDark from "../../../images/logo-dark.png";

const Sms = ({ number, disabled, ...props }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm();

  const sendSms = async () => {
    await apiRequest(`/customer/sms`, "POST", getValues()).then((resp) => console.log(resp));
  };
  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      {
        <Content>
          <Block>
            <form className="is-alter" onSubmit={handleSubmit(sendSms)}>
              <Input
                errors={errors}
                required
                requiredMessage="This field is required"
                register={register}
                type="text"
                label="Phone number"
                id="phone"
                name="phone"
                className="form-control-lg form-control"
              ></Input>
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  text<span className="text-danger">*</span>
                </label>
              </div>
              <div className="form-control-wrap">
                <textarea
                  id="text"
                  name="text"
                  type="text"
                  className="  form-control form-control-lg "
                  {...register("text", { required: "This field is required" })}
                ></textarea>
                {errors.text && <p className="invalid">{errors.text.message}</p>}
              </div>
              <Button>submit</Button>
            </form>
          </Block>
        </Content>
      }
    </React.Fragment>
  );
};

export default Sms;

import React, { useEffect, useState } from "react";
import Head from "../../../../../../layout/head/Head";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockDes,
  InputSwitch,
  Loading,
} from "../../../../../../components/Component";
import { Button } from "reactstrap";
import { Card, Badge } from "reactstrap";
import { apiRequest } from "../../../../../../utils/Api";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";


const CustomerProfileSetting = ({ submitRef }) => {
  const [setting, setSetting] = useState();
  const [loading, setLoading] = useState(true);

  const {
    handleSubmit,

    formState: { errors },
  } = useForm();


  useEffect(() => {
    getSetting();
  }, []);



  const getSetting = async () => {
    setLoading(true);
    await apiRequest("/customer/a/security/setting", "GET").then((resp) => {
      console.log(resp);

      setSetting(resp.data);
      setLoading(false);
    });
  };

  const setChange = (e) => {
    setSetting((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
    console.log(setting)
  };

  const onSave = async () => {

    try {

      const resp = await apiRequest("/customer/a/security/setting", "PUT", setting);

      if (resp && resp.code === 200) {
        toast.success("Your information is saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(resp.message || "Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };


  return (

    <React.Fragment>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Head title="Customer Profile" />
      {loading && <Loading />}
      {!loading &&
        <form onSubmit={handleSubmit(onSave)}>
          <Block>

            <Card className="card-bordered">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="between-center flex-wrap flex-md-nowrap g-3">
                    <div className="nk-block-text">
                      <h6>Save my Activity Logs</h6>
                      <p>You can save your activity logs including unusual activity detected.</p>
                    </div>
                    <div className="nk-block-actions">
                      <ul className="align-center gx-3">
                        <li className="order-md-last">
                          <div className="custom-control custom-switch me-n2">
                            <InputSwitch setChange={setChange} checked={setting.activity} disabled={true} id="activity" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div className="between-center flex-wrap g-3">
                    <div className="nk-block-text">
                      <h6>Change Password</h6>
                      <p>Set a unique password to protect your account.</p>
                    </div>
                    <div className="nk-block-actions flex-shrink-sm-0">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                        <li className="order-md-last">
                          <Button color="primary" disabled={true}>
                            Change Password
                          </Button>
                        </li>
                        <li>
                          {/* <em className="text-soft text-date fs-12px">
                        Last changed: <span>Oct 2, 2019</span>
                      </em> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body bg-light">
                  <div className="between-center flex-wrap flex-md-nowrap g-3">
                    <div className="nk-block-text">
                      <h6>
                        Two Factor Authentication (2FA) &nbsp;{" "}
                        {/* <Badge className="ms-0" color="success">
                      Enabled
                    </Badge> */}
                      </h6>
                      <p>
                        Secure your account with 2FA. When it is enabled, to login you will need to enter not only your
                        password, but also a passcode generated from a mobile application or text message{" "}
                      </p>
                    </div>
                    <div className="nk-block-actions">
                      <Button onClick={() => setSetting((prevState) => ({ ...prevState, two_factor: !setting.two_factor }))} color={setting.two_factor ? "danger" : "primary"}>
                        {setting.two_factor ? 'Disable' : 'Enable'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Block>
          <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
        </form>
      }
      <ToastContainer />
    </React.Fragment >
  );
};

export default CustomerProfileSetting;

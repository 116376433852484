import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm } from "react-hook-form";
import { Row, Col } from "reactstrap";
import { apiRequest } from "../../../../../utils/Api";
import { countries, regions } from "../../../../data/country";

import Head from "../../../../../layout/head/Head";
import {
  Input,
  Loading,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockDes,
} from "../../../../../components/Component";
import RSelect2 from "../../../../../components/select/ReactSelect2";

const OrganizationGeneral = (props) => {
  const [loading, setLoading] = useState();
  const [formData, setFormData] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const toastToggle = () => {
    setisOpen(!isOpen);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    getComapnyInfo();
  }, []);

  const getComapnyInfo = async () => {
    setLoading(true);
    const companyData = await apiRequest("/customer/a/company", "GET");
    setFormData(companyData.data);
    setValue("country", companyData.data.location.country);
    setValue("region", companyData.data.location.region);
    console.log(companyData);
    setLoading(false);
  };

  const getRegionName = (regionShort) => {
    if (formData.location.country === "CA") {
      let country = countries.canada;
      let regionFull = country.find((x) => x.abbreviation === regionShort);
      return regionFull.name;
    }
    if (formData.location.country === "US") {
      let country = countries.unitedStates;
      let regionFull = country.find((x) => x.abbreviation === regionShort);
      return regionFull.name;
    }
  };

  const onselect = (name, value) => {
    if (name === "country") {
      setValue("country", value);
    }
    if (name === "region") {
      setValue("region", value);
    }
    if (getValues(name)) {
      delete errors[name];
    }
  };

  const submitForm = async (formData) => {
    // console.log(formData);
    setLoading(true);
    const saveGeneral = async () => {
      try {
        await apiRequest("/customer/a/company/save", "PUT", formData).then((resp) => {
          if (resp.code === 200) {
            toast.success("Your information saved !", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // getComapnyInfo();
            setLoading(false);
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          }
        });
      } catch (error) {
        toast.error(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    };

    saveGeneral();
  };

  return (
    <React.Fragment>
      <Head title="Organization"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Organization Information.</BlockTitle>
            <BlockDes>
              <p>Some of the information below may be restricted by your admin.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {loading && <Loading />}
      {!loading && formData && (
        <form className="content clearfix is-alter" onSubmit={handleSubmit(submitForm)}>
          <button type="submit" style={{ display: "none" }} />
          <Row className="gy-4">
            <Col md="6">
              <label className="form-label" htmlFor="company-name">
                Name of Organization<span className="text-danger"> *</span>
              </label>
              <span className="form-note">Legal name of organization</span>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="company"
                    name="company"
                    required
                    requiredMessage="This field is required"
                    className=" form-control"
                    register={register}
                    errors={errors}
                    defaultValue={formData.company}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="last-name">
                  {/* Work Phone<span className="text-danger"> *</span> */}
                  Phone<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="phoneDay"
                    name="phoneDay"
                    required
                    requiredMessage={{
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "An invalid character was found",
                      },
                      required: "This field is required",
                    }}
                    className=" form-control"
                    register={register}
                    errors={errors}
                    defaultValue={formData.phoneDay}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="phoneNight">
                  Admin's Phone<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="phoneNight"
                    name="phoneNight"
                    className=" form-control"
                    register={register}
                    requiredMessage={{
                      pattern: {
                        value: /^\(?\d{3}\)?[-.●]?\d{3}[-.●]?\d{4}$/,
                        message: "An invalid character was found",
                      },
                    }}
                    errors={errors}
                    defaultValue={formData.phoneNight}
                  />
                </div>
              </div>
            </Col>
            {/* <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="phoneMobile">
                  Mobile<span className="text-danger"> *</span>
                </label>
              </div>
            </Col> */}
            {/* <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="phoneMobile"
                    name="phoneMobile"
                    className=" form-control"
                    register={register}
                    requiredMessage={{
                      pattern: {
                        value: /^\(?\d{3}\)?[-.●]?\d{3}[-.●]?\d{4}$/,
                        message: "An invalid character was found",
                      },
                    }}
                    errors={errors}
                    defaultValue={formData.phoneMobile}
                  />
                </div>
              </div>
            </Col> */}
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="phoneFax">
                  Fax<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="phoneFax"
                    name="phoneFax"
                    className=" form-control"
                    register={register}
                    requiredMessage={{
                      pattern: {
                        value: /^\(?\d{3}\)?[-.●]?\d{3}[-.●]?\d{4}$/,
                        message: "An invalid character was found",
                      },
                    }}
                    errors={errors}
                    defaultValue={formData.phoneFax}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="address1">
                  Address1<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="address1"
                    name="address1"
                    required
                    requiredMessage={{

                      required: "This field is required",
                    }}
                    className=" form-control"
                    register={register}
                    errors={errors}
                    defaultValue={formData.location.address1}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="address2">
                  Address2<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="address2"
                    name="address2"
                    className=" form-control"
                    register={register}
                    errors={errors}
                    defaultValue={formData.location.address2}
                  // requiredMessage={{
                  //   pattern: {
                  //     value: /(\d+ .+)|(\d+-\d+ .+)/g,
                  //     message: "An invalid character was found",
                  //   },
                  // }}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="city">
                  City<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    required
                    requiredMessage={{
                      pattern: {
                        value: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
                        message: "Please provide a valid city",
                      },
                      required: "This field is required",
                    }}
                    className=" form-control"
                    register={register}
                    errors={errors}
                    defaultValue={formData.location.city}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="zip">
                  Zip / Postal<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    name="zip"
                    register={register}
                    errors={errors}
                    id="zip"
                    required
                    requiredMessage={{
                      pattern: {
                        value: /^(\d{5}-\d{4}|\d{5}|\d{9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/,
                        message: "Please provide a valid postal/zip code",
                      },
                      required: "This field is required",
                    }}
                    className=" form-control"
                    defaultValue={formData.location.postal}
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="country">
                  Country <span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <RSelect2
                    onselect={onselect}
                    setValue={setValue}
                    selectedCountry={watch("country")}
                    register={register}
                    requiredMessage="This field is required"
                    id="country"
                    name="country"
                    errors={errors}
                    options={[
                      { value: "CA", label: "Canada" },
                      { value: "US", label: "United States" },
                    ]}
                    defaultValue={{
                      value: formData.location.country,
                      label:
                        (formData.location.country === "CA" && "Canada") ||
                        (formData.location.country === "US" && "United States"),
                    }}
                    className="form-control-lg p-0 border-0 form-control"
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="region">
                  State / Province<span className="text-danger"> *</span>
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <div className="form-control-wrap">
                  {/* {watch("country") !== "CA" && watch("country") !== "US" && (
                    <Input
                      type="text"
                      id="region"
                      className="form-control-lg form-control"
                      name="region"
                      required
                      requiredMessage="This field is required"
                      errors={errors}
                      register={register}
                      // defaultValue={{ value: formData.location.region, label: formData.location.region }}
                    />
                  )} */}
                  {(watch("country") === "CA" || formData.location.country === "CA") &&
                    formData.location.country !== "US" && (
                      <RSelect2
                        onselect={onselect}
                        setValue={setValue}
                        selectedCountry={watch("region")}
                        register={register}
                        requiredMessage="This field is required"
                        id="region"
                        name="region"
                        errors={errors}
                        options={regions[watch("country")]}
                        defaultValue={{
                          value: getValues("region"),
                          label: getRegionName(formData.location.region),
                        }}
                        className="form-control-lg p-0 border-0 form-control"
                      />
                    )}
                  {(watch("country") === "US" || formData.location.country === "US") &&
                    formData.location.country !== "CA" && (
                      <RSelect2
                        onselect={onselect}
                        setValue={setValue}
                        selectedCountry={watch("region")}
                        register={register}
                        requiredMessage="This field is required"
                        id="region"
                        name="region"
                        errors={errors}
                        options={regions[watch("country")]}
                        defaultValue={{
                          value: formData.location.region,
                          label: getRegionName(formData.location.region),
                        }}
                        className="form-control-lg p-0 border-0 form-control"
                      />
                    )}
                </div>
              </div>
            </Col>
          </Row>
          <button ref={props.submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
        </form>
      )}
      <ToastContainer autoClose={2000} />
    </React.Fragment>
  );
};

export default OrganizationGeneral;

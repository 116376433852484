import React, { useEffect, useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { ClientListTable, Loading } from "../../../../../../components/Component";
import moment from "moment";
import { apiRequest } from "../../../../../../utils/Api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { capitalizeFirstLetter } from "../../../../../../utils/Utils";
import { useForm } from "react-hook-form";

const LoginHistory = ({ data }) => {
    const [loading, setLoading] = useState();
    const [loginData, setLoginData] = useState("");


    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm();

    const getLoginHistory = async () => {
        setLoading(true);
        apiRequest("/customer/a/login_history", "GET").then(resp => {
            console.log(resp)
            resp.data && setLoginData(resp.data);
            setLoading(false);
        })

    };

    useEffect(() => {
        getLoginHistory();
    }, []);

    const dataTableColumns = [
        {
            name: "Event",
            selector: (row) => row.event,
            style: { padding: "5px 10px" },
            cell: (row) => (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "left" }}>
                    <span style={{ paddingLeft: "5px" }}>
                        {capitalizeFirstLetter(row.description)}
                    </span>
                </div>
            ),
            sortable: false,

        },
        {
            name: "IPV4",
            selector: (row) => row.ipv4,
            style: { padding: "5px 10px" },
            cell: (row) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                    <span style={{ paddingLeft: "5px" }}>{row.ipv4}</span>
                    {/* <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>{row.email}</span> */}
                </div>
            ),
            sortable: false,
        },
        {
            name: "City",
            selector: (row) => row.ipv4,
            style: { padding: "5px 10px" },
            cell: (row) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                    <span style={{ paddingLeft: "5px" }}>{row.city}</span>
                    {/* <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>{row.email}</span> */}
                </div>
            ),
            sortable: false,
        },
        {
            name: "Country",
            selector: (row) => row.ipv4,
            style: { padding: "5px 10px" },
            cell: (row) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                    <span style={{ paddingLeft: "5px" }}>{row.country}</span>
                    {/* <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>{row.email}</span> */}
                </div>
            ),
            sortable: false,
        },
        {
            name: "OS/Browser",
            selector: (row) => row.ipv4,
            style: { padding: "5px 10px" },
            cell: (row) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                    <span style={{ paddingLeft: "5px" }}>{row.device.os.name}</span>
                    <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>{row.device.browser.name} {row.device.browser.version}</span>
                </div>
            ),
            sortable: false,
        },
        // {
        //   name: "IP / useragent",
        //   selector: (row) => row.user,
        //   style: { padding: "5px 10px" },
        //   cell: (row) => (
        //     <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
        //       <span style={{ paddingLeft: "5px" }}>{row.ipv4}</span>
        //     </div>
        //   ),
        //   sortable: false,
        // },
        {
            name: "Date",
            selector: (row) => row.created_at,
            style: { padding: "5px 10px" },
            cell: (row) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
                    <span style={{ paddingLeft: "5px" }}>{moment(row.created_at).format("MM/DD/YYYY")}</span>
                    <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>
                        {moment(row.created_at).fromNow()}
                    </span>
                </div>
            ),
            sortable: false,
        },
    ];
    console.log(loginData)
    return (
        <React.Fragment>
            {loading && <Loading />}
            {!loading && <Content>
                <ClientListTable columns={dataTableColumns} data={loginData} search={false} show={false} />
            </Content>}
        </React.Fragment>
    );
};

export default LoginHistory;

import React, { useEffect, useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { arrayBufferToBase64, dateConverter } from "../../../../../../utils/Utils";
import { Icon } from "../../../../../../components/Component";
import LocationMap from "../../../../../../images/FirstLocation.png";
import { Badge, Button, Modal, ModalBody, ModalHeader, Tooltip } from "reactstrap";

const VerifyProfile = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [tooltipOpen, setOpen] = useState(false);
  const [locationStatus, setLocationStatus] = useState(true)
  const [distancesData, setDistanceData] = useState([])
  const toggleTooltip = () => {
    setOpen(!tooltipOpen);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
    setModal(!modal);
  };

  useEffect(() => {
    let distancesData = []
    if (props.more.location.primary && props.more.location.primary.mobile_distance_exceeding && props.more.location.primary.mobile_distance_exceeding['15km'].length > 0) {
      props.more.location.legal.mobile_distance_exceeding['15km'].forEach(step => {
        distancesData.push({ type: 'Legal Location', step: step })
      })
    }
    if (props.more.location.primary && props.more.location.primary.mobile_distance_exceeding && props.more.location.primary.mobile_distance_exceeding['15km'].length > 0) {
      props.more.location.legal.mobile_distance_exceeding['15km'].forEach(step => {
        distancesData.push({ type: 'Primary Location', step: step })
      })
    }

    props.more.location.documents && props.more.location.documents.length && props.more.location.documents.forEach(doc => {
      console.log(doc.mobile_distance_exceeding)
      if (doc.mobile_distance_exceeding && doc.mobile_distance_exceeding['15km'].length > 0) {
        doc.mobile_distance_exceeding['15km'].forEach(step => {
          distancesData.push({ type: doc.type === 'passport' ? 'Passport' : doc.type === 'card' ? 'Photo ID' : "Driver's License", step: step })
        })

      }
    })



    if (distancesData.length > 0) {
      setDistanceData(distancesData)
      setLocationStatus(false)
    }
  }, [])

  return (
    <React.Fragment>
      {props.more && (
        <Content>
          <div className="nk-block-head">
            <h5 className="title">About {props.more.name ? props.more.name : "Not collected"}</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Title
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {(props.more.person && props.more.person.title) || "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Place of Birth
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.birthplace
                        ? props.more.person.birthplace
                        : "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      First Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_first"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.name_first
                        ? props.more.person.name_first
                        : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Citizenship
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name-last"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.citizenship
                        ? props.more.person.citizenship
                        : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Middle Name(s)
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_middle"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.name_middle
                        ? props.more.person.name_middle
                        : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      SIN/SSN/Tax ID
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="suffix"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.sn ? props.more.person.sn : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Last Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name-last"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.name_last ? props.more.person.name_last : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Maritial Status
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.marital_status
                        ? props.more.person.marital_status
                        : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Suffix
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="suffix"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.suffix ? props.more.person.suffix : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Spouse
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.spouse ? props.more.person.spouse : "Not collected"}
                    </span>
                  </div>
                  {/* <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Also Known As
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      { props.more.person && props.more.person.name_aka ? props.more.person.name_aka : ""}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Legally Changed Name?
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_changed"
                      className="profile-ud-value"
                    >
                      {props.more.person &&props.more.person.name_change ? props.more.person.name_change : ""}
                    </span>
                  </div> */}
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Previous Name
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_prev"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.name_previous
                        ? props.more.person.name_previous
                        : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Occupation
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_changed"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.occupation
                        ? props.more.person.occupation
                        : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Pronoun
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.pronoun ? props.more.person.pronoun : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Annual Income
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_prev"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.income ? props.more.person.income : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Sex
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="name_first"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.sex ? props.more.person.sex : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Employer
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.company ? props.more.person.company : "Not collected"}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Date of Birth
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                        borderLeft: "1px",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.birthdate ? props.more.person.birthdate : "Not collected"}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Employer Website
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "25%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.person && props.more.person.company_website !== "" ? (
                        <a href={props.more.person.company_website}>{props.more.person.company_website}</a>
                      ) : (
                        "Not Collected"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block-head">
            <h5 className="title">
              Provided locations of {props.more.name ? props.more.name : "Not collected"}
              {props.more.location.legal && (
                <span style={{ textAlign: "right", position: "absolute", right: "0px" }}>
                  {/* <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "20%",
                      color: "#526484",
                      fontWeight: "500",
                    }}
                  >
                    Distance With Mobile Location:{" "}
                  </span> */}
                  {props.more.location.legal.mobile_distance_exceeding && props.more.location.legal.address1 ? (
                    <>
                      {/* <Badge color={props.more.location.legal.mobile_distance_exceeding['15km'].length > 0 ? "danger" : "success"}>
                        <span onClick={toggle}>
                          The provided location is {props.more.location.legal.mobile_distance_exceeding['15km'].length > 0 ? 'more than' : 'within'}  15 km {props.more.location.legal.mobile_distance_exceeding['15km'].length > 0 ? 'away from' : ''} {
                            props.more.location.legal.mobile_distance_exceeding['15km'].length > 1 ? 'more than one'
                              : props.more.location.legal.mobile_distance_exceeding['15km'].length === 1 ? 'one'
                                : 'all'} of the mobile locations {props.more.location.legal.mobile_distance_exceeding['15km'].length > 0 && `(${props.more.location.legal.mobile_distance_exceeding['15km'].join(',')})`}
                                   </span>{" "}
                        <Icon name={props.more.location.legal.mobile_distance_exceeding['15km'].length > 0 ? "cross" : "check"}></Icon>
                      </Badge> */}
                      <span style={{ textAlign: "right", position: "absolute", right: "0px", top: "-2px" }}>
                        <Button
                          color={distancesData.length > 0 ? "danger" : 'success'}
                          className="btn-xs"
                          // onClick={distancesData.length && toggle}
                          id="remark-"
                        >
                          <span>{distancesData.length > 0 ? "The provided location is more than 15 km away from one of the mobile locations" : 'The provided is within 15 km of the mobile locations'}</span>{" "}
                          <Icon name={distancesData.length > 0 ? "cross" : "check"}></Icon>
                        </Button>
                        {/* <Tooltip trigger="hover" placement="top" isOpen={tooltipOpen} target="remark-" toggle={toggleTooltip}>
                          View Details
                        </Tooltip> */}
                      </span>
                    </>
                  ) : (
                    <Badge color={"gray"}>
                      <span>
                        Not Calculated
                        {/* {props.more.location.legal.mobile_distance} km{" "}
                      {props.more.location.legal.mobile_distance > 15 ? ">" : "<"} 15 km */}
                      </span>{" "}
                    </Badge>
                  )}
                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader
                      toggle={toggle}
                      close={
                        <button className="close" onClick={toggle}>
                          <Icon name="cross" />
                        </button>
                      }
                    >
                      Some steps exceed a distance of 15 km based on the provided location and mobile device.
                    </ModalHeader>
                    <ModalBody>

                      <div className="card card-bordered card-stretch">
                        <div className="card-inner-group">
                          <div className="card-inner p-0">
                            <div className="nk-tb-list nk-tb-ulist">
                              <div className="profile-ud wider ff-base fw-medium">
                                <span
                                  style={{
                                    textAlign: "center",
                                    alignSelf: "center",
                                    margin: "10px",
                                    width: "50%",
                                  }}

                                >
                                  <h6>Type</h6>
                                </span>

                                <span
                                  style={{
                                    textAlign: "center",
                                    alignSelf: "center",
                                    margin: "10px",
                                    width: "50%",
                                  }}

                                >
                                  <h6>Step</h6>
                                </span>


                              </div>
                              {distancesData.map((data, index) => {
                                return (
                                  <div key={index} className="profile-ud wider ff-base fw-medium">
                                    <span
                                      style={{
                                        textAlign: "center",
                                        alignSelf: "center",
                                        margin: "10px",
                                        width: "50%",
                                      }}

                                    >
                                      {data.type}
                                    </span>

                                    <span
                                      style={{
                                        textAlign: "center",
                                        alignSelf: "center",
                                        margin: "10px",
                                        width: "50%",
                                      }}

                                    >
                                      {data.step}
                                    </span>


                                  </div>
                                )
                              })}

                            </div>
                          </div>
                        </div>
                      </div>

                    </ModalBody>
                  </Modal>
                </span>
              )}
              {!props.more.location.legal && (
                <span style={{ textAlign: "right", position: "absolute", right: "0px" }}>
                  {/* <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "0px 0px 0px 20px",
                      width: "20%",
                      color: "#526484",
                      fontWeight: "500",
                    }}
                  >
                    Distance With Mobile Location:{" "}
                  </span> */}
                  <Badge color={"gray"}>
                    <span>Not Clculated</span>{" "}
                  </Badge>
                </span>
              )}
            </h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      Type
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                    >
                      {/* Address Line 1 */}
                      Address
                    </span>
                    {/* <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      Address Line 2
                    </span> */}
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "15%",
                      }}
                    >
                      City
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      State/Province
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      Country
                    </span>
                  </div>
                  {props.more && props.more.location && props.more.location.legal && <div className="profile-ud wider">
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="address1"
                    >
                      Legal
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="address1"
                    >
                      {props.more.location.legal.address1 ? props.more.location.legal.address1
                        : "Not collected"}
                    </span>
                    {/* <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="address2"
                    >
                      {
                        props.more.location.legal.address2
                          ? props.more.location.legal.address2
                          : "Not collected"}
                    </span> */}
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "15%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="city"
                    >
                      {props.more.location.legal.city
                        ? props.more.location.legal.city
                        : "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="region"
                    >
                      {
                        props.more.location.legal.region
                          ? props.more.location.legal.region
                          : "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="country"
                    >
                      {
                        props.more.location.legal.country
                          ? props.more.location.legal.country
                          : "Not collected"}
                    </span>
                  </div>}
                  {props.more && props.more.location && props.more.location.primary && !props.more.location.primary.current && <div className="profile-ud wider">
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="address1"
                    >
                      Primary
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="address1"
                    >
                      {props.more.location.primary.address1 ? props.more.location.primary.address1
                        : "Not collected"}
                    </span>
                    {/* <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="address2"
                    >
                      {
                        props.more.location.primary.address2
                          ? props.more.location.primary.address2
                          : "Not collected"}
                    </span> */}
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "15%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="city"
                    >
                      {props.more.location.primary.city
                        ? props.more.location.primary.city
                        : "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="region"
                    >
                      {
                        props.more.location.primary.region
                          ? props.more.location.primary.region
                          : "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="country"
                    >
                      {
                        props.more.location.primary.country
                          ? props.more.location.primary.country
                          : "Not collected"}
                    </span>
                  </div>}
                  {props.more && props.more.documents && props.more.documents.map((document, index) => {
                    if (document.type === 'drivers' || document.type === 'card')
                      return (
                        <div key={index} className="profile-ud wider">
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="address1"
                          >
                            {document.type === 'drivers' ? "Driver's license" : "ID card"}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="address1"
                          >
                            {document.file1_ocr_results.find((item) => item.ADDRESS).ADDRESS || 'Not Found'}

                          </span>
                          {/* <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="address2"
                          >
                            Not Found
                          </span> */}
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "15%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="city"
                          >
                            {document.file1_ocr_results.find((item) => item.CITY) ? document.file1_ocr_results.find((item) => item.CITY).CITY : 'Not Found'}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="region"
                          >
                            {props.more &&
                              props.more.location &&
                              props.more.location.legal &&
                              props.more.location.legal.region
                              ? props.more.location.legal.region
                              : "Not collected"}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="country"
                          >

                            {document.file1_ocr_results.find((item) => item.ISSUING_COUNTRY) ? document.file1_ocr_results.find((item) => item.ISSUING_COUNTRY).ISSUING_COUNTRY : 'Not Found'}
                          </span>
                        </div>)

                  })}


                  < hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    {/* <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                      className="profile-ud-label"
                    >
                      Supplied Location
                    </span> */}
                    {props.more.location.image ? (
                      <img
                        alt=""
                        className="mx-auto"
                        style={{ width: "96%" }}
                        src={props.more.location.image}
                      />
                    ) : (
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        className="mx-auto"
                        id="city"
                      >
                        Map will be available after verification request submitted
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="nk-block-head">
            <h5 className="title">Location of Mobile</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      Steps
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                    >
                      {/* Address Line 1 */}
                      Address
                    </span>
                    {/* <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      Address Line 2
                    </span> */}
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "15%",
                      }}
                    >
                      City
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      State/Province
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                    >
                      Country
                    </span>
                  </div>
                  {props.more &&
                    props.more.internal &&
                    props.more.internal.location &&
                    props.more.internal.location.mobile &&
                    props.more.internal.location.mobile.addresses ? (
                    props.more.internal.location.mobile.addresses.map((address, index) => {
                      return (
                        <div key={index} className="profile-ud wider">
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id={`address-${index + 1}`}
                          >
                            {address.steps.join(',')}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="address1"
                          >

                            {address.address && address.address.address1 ? address.address.address1
                              : "Not collected"}
                          </span>
                          {/* <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="address2"
                          >
                            {address.address && address.address.address_2 ? address.address.address_2
                              : "Not collected"}
                          </span> */}
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "15%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="city"
                          >
                            {address.address && address.address.city ? address.address.city
                              : "Not collected"}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="region"
                          >
                            {address.address && address.address.region ? address.address.region
                              : "Not collected"}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "20%",
                              color: "#526484",
                              fontWeight: "500",
                            }}
                            id="country"
                          >
                            {address.address && address.address.country ? address.address.country
                              : "Not collected"}
                          </span>
                        </div>
                      )
                    })) : (
                    <div className="profile-ud wider">
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "20%",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        id={`address-1`}
                      >
                        Not Collected
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "20%",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        id="address1"
                      >

                        Not Collected
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "20%",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        id="address2"
                      >
                        Not Collected
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "20%",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        id="city"
                      >
                        Not Collected
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "20%",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        id="region"
                      >
                        Not Collected
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          margin: "0px 0px 0px 20px",
                          width: "20%",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        id="country"
                      >
                        Not Collected
                      </span>
                    </div>
                  )

                  }



                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    {/* <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "20%",
                      }}
                      className="profile-ud-label"
                    >
                      Real-Time Location at Verfication
                    </span> */}

                    {props.more.intelligence && props.more.intelligence.steps_image ? (

                      <img
                        alt=""
                        className="mx-auto"
                        style={{ width: "96%" }}
                        src={props.more.intelligence.steps_image}
                      />
                    ) : (
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          color: "#526484",
                          fontWeight: "500",
                        }}
                        className="mx-auto"
                        id="city"
                      >
                        Map will be available after verification request submitted
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="nk-block-head">
            <h5 className="title">Verification Info</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Submitted By
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="title"
                      className="profile-ud-value"
                    >
                      {props.more.request && props.more.request.from_name ? props.more.request.from_name:  ""}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Submitted On
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="name_first"
                      className="profile-ud-value"
                    >
                      {props.more.request && props.more.request.created_at ? dateConverter(props.more.request.created_at): ''}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 10px 20px",
                      width: "50%",
                    }}
                    className="profile-ud-label"
                  >
                    Submitted At
                  </span>

                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      margin: "10px 20px 10px 0px",
                      width: "50%",
                    }}
                    id="name_middle"
                    className="profile-ud-value"
                  >
                    {props.more.verificationInfo.submitted_at}
                  </span>
                </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Status
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="name-last"
                      className="profile-ud-value text-warning"
                    >
                      Pending
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Last Checked By
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="suffix"
                      className="profile-ud-value"
                    >
                      {props.more.request && props.more.request.created_at ? dateConverter(props.more.request.created_at): ''}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Last Checked On
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="aka"
                      className="profile-ud-value"
                    >
                      {props.more.request && props.more.request.created_at ? dateConverter(props.more.request.created_at): ''}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Finger Signature
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="name_changed"
                      className="profile-ud-value"
                    >
{props.more.attestation && props.more.attestation.signature ? 
                      (<img
                        className="m-1"
                        alt="test"
                        src={
                          "data:image/jpg;base64, " +
                          arrayBufferToBase64(
                            props.more.attestation.signature.data
                          )
                        }
                      ></img>)
                        :
                        ''
                    }
                      {props.more.verificationInfo.digital_signature}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      IPv4
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="name_prev"
                      className="profile-ud-value"
                    >
                      {props.more.verificationInfo.IPv4}
                      {props.more.location ? '192.168.1.1' : ''}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Country
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.verificationInfo.country}
                      {props.more.location && props.more.location.legal_country ?props.more.location.legal_country : ""}
                      <br></br>
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      City
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.verificationInfo.city}
                      {props.more.location && props.more.location.legal_city ? props.more.location.legal_city:  ""},{" "}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      postal
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.verificationInfo.postal}
                      {props.more.location && props.more.location.legal_postal ? props.more.location.legal_postal: ""}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      User Agent
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                      {props.more.verificationInfo.user_agent}
                      ???
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "10px 0px 10px 20px",
                        width: "50%",
                      }}
                      className="profile-ud-label"
                    >
                      Geocoordinates
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "10px 20px 10px 0px",
                        width: "50%",
                      }}
                      id="pronoun"
                      className="profile-ud-value"
                    >
                       {props.more.location ? '43.688404, -79.394550' : ''}
                      Long : {props.more.verificationInfo.geocoordinates.Longitude} Lat: {props.more.verificationInfo.geocoordinates.Latitude}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Content>
      )
      }
    </React.Fragment >
  );
};

export default VerifyProfile;

import React, { useEffect, useRef, useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { Block, BlockAthenty, PreviewCard, Loading } from "../../../../../../components/Component";
import { apiRequest } from "../../../../../../utils/Api";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

const PaymentMethodAdd = () => {
  const submitRef = useRef();
  const [formData, setFormData] = useState({
    card_number: "",
    card_name: "",
    card_expiry_month: "",
    card_expiry_year: "",
    card_expiry: "",
    card_cvv: "",
    focus: "",
    year: new Date().getFullYear(),
    yearOptions: [],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const asignRef = () => {
    return submitRef.current.click();
  };

  useEffect(() => { }, []);

  const onInputChange = (e) => {
    // console.log(e);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = async () => {
    setLoading(true);
    let data = formData;
    data.card_expiry = `${data.card_expiry_month}-${data.card_expiry_year}`;

    console.log(data);
    try {
      await apiRequest("/customer/a/method/add", "POST", data).then((resp) => {
        if (resp.code !== 200) {
          setLoading(false);
          toast.error(resp.message, {
            autoClose: 2000,
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.success("Payment method Added Successfully !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
            onClose: () => {
              navigate("/customer/admin/billing/payment-methods");
            },
          });
        }
      });
    } catch (error) {
      toast.error("Something Went Wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log("error: ", error);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Add new payment method."}
            nav="Admin / Billing / Payment Methods"
            title={"Add Payment Method"}
            description={"Add new payment method."}
            back="/customer/admin/billing/payment-methods/"
            buttons={[
              {
                onclick: asignRef,
                text: "Save",
                icon: "save",
                link: "",
                tooltip: "Save Chnages",
                id: "save-button",
                type: "submit",
                form: "my-form",
              },
            ]}
          />
          <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
            <PreviewCard>
              <Block>
                <Row>
                  <Col>
                    <Col md="12" className="">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <label className="form-label" htmlFor="company-name">
                            Card Number<span className="text-danger"> *</span>
                          </label>
                          <input
                            placeholder="0000 0000 0000 0000"
                            type="text"
                            id="card_number"
                            className="form-control"
                            {...register("card_number", {
                              required: "This field is required",
                              // pattern: {
                              //   value: /^([\w]{3,})+\s+([\w\s]{3,})+$/i,
                              //   message: "Please input valid card number",
                              // },
                            })}
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.card_number}
                          />
                          {errors.card_number && <span className="invalid">{errors.card_number.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <label className="form-label" htmlFor="company-name">
                            Cardholder Name<span className="text-danger"> *</span>
                          </label>
                          <input
                            placeholder="Name On Your Card"
                            type="text"
                            id="card_name"
                            className="form-control"
                            {...register("card_name", { required: true })}
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.card_name}
                          />
                          {errors.card_name && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <label className="form-label" htmlFor="company-name">
                            Expiry Date<span className="text-danger"> *</span>
                          </label>
                          <Row className="form-group">
                            <Col className="form-control-wrap">
                              {/* <Select
                            defaultValue={formData.card_expiry_month}
                            name="card_expiry_month"
                            id="card_expiry_month"
                            options={[
                              { value: 1, label: "Jan" },
                              { value: 2, label: "Feb" },
                              { value: 3, label: "Mar" },
                              { value: 4, label: "Apr" },
                              { value: 5, label: "May" },
                              { value: 6, label: "Jun" },
                              { value: 7, label: "Jul" },
                              { value: 8, label: "Aug" },
                              { value: 9, label: "Sep" },
                              { value: 10, label: "Oct" },
                              { value: 11, label: "Nov" },
                              { value: 12, label: "Dec" },
                            ]}
                            {...register("card_expiry_month", {
                              required: true,
                            })}
                            placeholder="Select Month"
                            onChange={(e, name) => onChangeSelect(e, name)}
                          /> */}
                              <input
                                placeholder="Month : Example 01, 02, 11"
                                type="number"
                                name="card_expiry_month"
                                id="card_expiry_month"
                                className="form-control"
                                {...register("card_expiry_month", { required: true })}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.card_expiry_month}
                              />
                              {errors.card_expiry_month && <span className="invalid">This field is required</span>}
                            </Col>
                            <Col>
                              {/* <Select
                            name="card_expiry_year"
                            id="card_expiry_year"
                            defaultValue={formData.card_expiry_year}
                            options={formData.yearOptions}
                            {...register("card_expiry_year", {
                              required: true,
                            })}
                            placeholder="Select Year"
                            onChange={(e, name) => onChangeSelect(e, name)}
                          /> */}
                              <input
                                placeholder="Year : Example 2026, 28, 29"
                                type="number"
                                name="card_expiry_year"
                                id="card_expiry_year"
                                className="form-control"
                                {...register("card_expiry_year", { required: true })}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.card_expiry_year}
                              />
                              {errors.card_expiry_year && <span className="invalid">This field is required</span>}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group mt-2">
                        <div className="form-control-wrap">
                          <label className="form-label" htmlFor="company-name">
                            CVV/CVC<span className="text-danger"> *</span>
                          </label>
                          <input
                            placeholder="***"
                            type="password"
                            id="card_cvv"
                            className="form-control"
                            {...register("card_cvv", { required: true })}
                            onChange={(e) => onInputChange(e)}
                            defaultValue={formData.card_cvv}
                          />
                          {errors.card_cvv && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </Col>

                  <Col>
                    <Cards
                      cvc={formData.card_cvv}
                      expiry={formData.card_expiry_month + formData.card_expiry_year}
                      focused={formData.focus}
                      name={formData.card_name}
                      number={formData.card_number}
                    />
                  </Col>
                </Row>
              </Block>
            </PreviewCard>
            <button ref={submitRef} type="submit" id="add-new-verify" style={{ display: "none" }} />
          </form>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default PaymentMethodAdd;

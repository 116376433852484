import React from "react";
import { List } from "reactstrap";

const LocationInstructionsModal = ({ onRetry }) => {
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const isAndroid = () => {
    return /Android/.test(navigator.userAgent);
  };

  const iosInstructions = (
    <div className="pt-5">
      <h5>Enable Location Services on iOS</h5>
      <List>
        <li>1. Open the Settings app on your iPhone.</li>
        <li>2. Scroll down and tap Privacy.</li>
        <li>3. Select Location Services.</li>
        <li>4. Make sure Location Services is toggled on.</li>
        <li>5. Scroll down and find your browser (e.g., Safari or Chrome).</li>
        <li>6. Select the browser and set the location access to "While Using the App".</li>
      </List>
      {/* <img src="path_to_ios_instruction_image" alt="iOS Instructions" /> */}
    </div>
  );

  const androidInstructions = (
    <div className="pt-5">
      <h5>Enable Location Services on Android</h5>
      <List>
        <li>1. Open the Settings app on your Android device.</li>
        <li>2. Scroll down and tap Location.</li>
        <li>3. Make sure Location is toggled on.</li>
        <li>4. Select App permissions.</li>
        <li>5. Find your browser (e.g., Chrome) in the list and tap it.</li>
        <li>6. Set the location access to "Allow only while using the app".</li>
      </List>
      {/* <img src="path_to_android_instruction_image" alt="Android Instructions" /> */}
    </div>
  );

  return (
    // <div className="modal">
    //   <div className="modal-content">
    <>
      {isIOS() && iosInstructions}
      {/* {isAndroid() && iosInstructions} */}
      {isAndroid() && androidInstructions}
      {/* {isIOS() && androidInstructions} */}
      {/* <button onClick={onRetry}>I have enabled it</button> */}
    </>
    //   </div>
    // </div>
  );
};

export default LocationInstructionsModal;

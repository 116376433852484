import React, { useState } from "react";

const InputSwitch = ({ label, category, id, checked, setChange = null }) => {
  const [inputCheck, setCheck] = useState(checked ? true : false);
  return (
    <React.Fragment>
      <input
        type="checkbox"
        className="custom-control-input"
        // defaultChecked={inputCheck}
        checked={checked}
        onChange={(e) => {
          setChange(e, category);
          setCheck(!inputCheck);
        }}
        id={id}
        name={id}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </React.Fragment>
  );
};

export default InputSwitch;

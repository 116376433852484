import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import RSelect2 from "../../select/ReactSelect2";
const CountrySelect2 = ({
  id,
  selectedCountry,
  handleCountryChange,
  label,
  name,
  onChange,
  requiredMessage,
  required,
  className,
  register = function () {},
  setValue,
  errors,
  placeholder,
  onselect,
}) => {
  var handleBlank = false;
  const options = useMemo(() => countryList().getData(), []);
  return (
    <div>
      <div className="form-label-group">
        <label className="form-label" htmlFor="default-01">
          {label}
          {requiredMessage && <span className="text-danger"> *</span>}
        </label>
      </div>
      <div className="form-control-wrap">
        <RSelect2
          requiredMessage={requiredMessage}
          setValue={setValue}
          errors={errors}
          register={register}
          options={options}
          name={name}
          color="#f5f5f5"
          id={id}
          className={className}
          placeholder={placeholder}
          onselect={onselect}
        />
      </div>
    </div>
  );
};

export default CountrySelect2;

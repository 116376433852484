import React, { useState } from "react";

import Content from "../../../../../../layout/content/Content";
import { Button, Block, Col, Row, BlockAthenty, Loading } from "../../../../../../components/Component";
import { useNavigate } from "react-router-dom";
import { Badge, Card } from "reactstrap";
import pricingTableData from "../../../../../data/pricingTable";
import { svgData } from "../../../../../components/crafted-icons/NioIconData";
import Nouislider from "nouislider-react";
import { apiRequest } from "../../../../../../utils/Api";
import { ToastContainer, toast } from "react-toastify";

const AddSubscription = () => {
  const [loading, setLoading] = useState(false);
  const [agentNum, setAgentNum] = useState(0);
  const [priceYear, setPriceYear] = useState(true);
  const navigate = useNavigate();

  const addPackage = async (package_id, package_service) => {
    setLoading(true);
    await apiRequest("/customer/a/package/add", "POST", { package_id, package_service }).then((resp) => {
      if (resp.code === 200) {
        navigate("/customer/admin/billing/subscription", { state: "clientAdded" });
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Add Subscription"}
            nav="Admin / Billing / Subscription"
            title={"Choose Suitable Plan"}
            description={"You can change your plan any time by upgrade your plan."}
            back={"/customer/admin/billing/subscription"}
            id="save-button"
          />
          <Block>
            <h6>Select Number of Agents</h6>
          </Block>
          <div className="card card-bordered card-preview mb-3">
            <table
              className="price-plan-info"
              style={{
                margin: "10px 0px 10px 0px",
                verticalAlign: "middle",
                color: "rgba(128, 148, 174, 0.8)",
                fontSize: "18px",
                marginBottom: "2px",
              }}
            >
              <tbody>
                <tr>
                  <td id="live_value_slider" className="col-11" style={{ paddingLeft: "10px" }}>
                    {agentNum}
                  </td>
                  <td
                    className="col-1"
                    style={{
                      borderLeftWidth: "2px",
                      borderLeftColor: "rgba(128, 148, 174, 0.3)",
                      padding: "10px",
                    }}
                  >
                    Agents
                  </td>
                </tr>
              </tbody>
            </table>
            <Nouislider
              className="form-range-slider"
              accessibility
              connect={[true, false]}
              start={0}
              step={1}
              range={{
                min: 0,
                max: 10,
              }}
              onSlide={(event) => setAgentNum(event[0].split(".")[0])}
            />
          </div>
          <Block>
            <div className="center">Switch to monthly pricing?</div>
            <div style={{ fontSize: "25px" }} className="form-check form-switch center">
              <input
                onChange={() => setPriceYear(!priceYear)}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="monthly_price_toggle"
              />
            </div>
          </Block>
          <Block>
            <Row className="g-gs">
              {pricingTableData.map((item) => {
                if (agentNum <= item.agentsNum) {
                  const icon = svgData.filter((icon) => icon.slug === item.icon)[0];
                  return (
                    <Col md="4" key={item.id}>
                      <Card className={`card-bordered pricing text-center ${item.tags ? "recommend" : ""}`}>
                        {item.tags && (
                          <Badge color="primary" className="pricing-badge">
                            Recommend
                          </Badge>
                        )}
                        <div className="pricing-body">
                          {/* <div style={{width: '110px', height: '110px' ,margin: 'auto'}} className="text-center pricing-media" >{icon.svg}</div> */}
                          <div className="pricing-title w-220px mx-auto mt-2">
                            <h5 className="title">{item.title}</h5>
                            <span className="sub-text">{item.desc}</span>
                          </div>
                          <div className="pricing-amount">
                            {priceYear && (
                              <div className="amount">
                                ${item.amount_year} <span>/year</span>
                              </div>
                            )}
                            {!priceYear && (
                              <div className="amount">
                                ${item.amount_month} <span>/month</span>
                              </div>
                            )}

                            <span className="bill">{item.userNumber} </span>
                          </div>
                          <div className="pricing-action">
                            <Button
                              color="primary"
                              disabled
                              onClick={() => addPackage(item.package_id, item.package_service)}
                            >
                              Select Plan
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                }
              })}
            </Row>
          </Block>
          <Block>
            <Card className="card-bordered">
              <table className="table table-features">
                <thead className="tb-ftr-head table-light">
                  <tr className="tb-ftr-item">
                    <th className="tb-ftr-info">Features</th>

                    <th className="tb-ftr-plan">Free</th>

                    <th className="tb-ftr-plan">Starter</th>

                    <th className="tb-ftr-plan">Pro</th>
                  </tr>
                </thead>
                <tbody className="tb-ftr-body">
                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">Agents</td>

                    <td className="tb-ftr-plan">
                      Max 5<span className="plan-name">Free</span>
                    </td>

                    <td className="tb-ftr-plan">
                      Max 100<span className="plan-name">Starter</span>
                    </td>

                    <td className="tb-ftr-plan">
                      Max 200<span className="plan-name">Pro</span>
                    </td>
                  </tr>

                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">Custom Logo</td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-cross"></i>
                      <span className="plan-name">Free</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-check-thick"></i>
                      <span className="plan-name">Starter</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-check-thick"></i>
                      <span className="plan-name">Pro</span>
                    </td>
                  </tr>

                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">Add Client Notes</td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-cross"></i>
                      <span className="plan-name">Free</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-check-thick"></i>
                      <span className="plan-name">Starter</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-check-thick"></i>
                      <span className="plan-name">Pro</span>
                    </td>
                  </tr>

                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">Additional Forms</td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-cross"></i>
                      <span className="plan-name">Free</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-check-thick"></i>
                      <span className="plan-name">Starter</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-check-thick"></i>
                      <span className="plan-name">Pro</span>
                    </td>
                  </tr>

                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">ID Verifications</td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-sign-dollar"></i>
                      <span className="plan-name">Free</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-sign-dollar"></i>
                      <span className="plan-name">Starter</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-sign-dollar"></i>
                      <span className="plan-name">Pro</span>
                    </td>
                  </tr>

                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">White-label</td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-sign-dollar"></i>
                      <span className="plan-name">Free</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-sign-dollar"></i>
                      <span className="plan-name">Starter</span>
                    </td>

                    <td className="tb-ftr-plan">
                      <i className="icon ni ni-sign-dollar"></i>
                      <span className="plan-name">Pro</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Block>
          <Block>
            <Card className="card-bordered">
              <table className="table table-features">
                <thead className="tb-ftr-head table-light">
                  <tr className="tb-ftr-item">
                    <th className="tb-ftr-info">Add-ons</th>
                    <th className="tb-ftr-plan">Price</th>
                    <th className="tb-ftr-plan">Subscribe</th>
                  </tr>
                </thead>
                <tbody className="tb-ftr-body">
                  <tr className="tb-ftr-item">
                    <td className="tb-ftr-info">White-label</td>
                    <td className="tb-ftr-plan">
                      $2500 / year<span className="plan-name">Price</span>
                    </td>
                    <td className="tb-ftr-plan">
                      <button type="button" className="btn btn-sm btn-primary">
                        Subscribe Now
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Block>
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default AddSubscription;

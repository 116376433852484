const invoiceData = [
  {
    id: 1,
    orderId: "746F5K2",
    name: "Abu Bin Ishtiyak",
    email: "info@softnio.com",
    phone: "+811 847-4958",
    date: "23 Jan 2019, 10:45pm",
    invoiceItem1: "500.00",
    invoiceItem2: "500.00",
    invoiceItem3: "1000.00",
    invoiceItem4: "40.00",
    totalAmount: "2300.00",
    status: "Complete",
  },
  {
    id: 2,
    orderId: "546H74W",
    name: "Ashley Lawson",
    email: "ashley@softnio.com",
    phone: "+124 394-1787",
    date: "12 Jan 2020, 10:45pm",
    invoiceItem1: "50.00",
    invoiceItem2: "50.00",
    invoiceItem3: "10.00",
    invoiceItem4: "40.00",
    totalAmount: "210.00",
    status: "Pending",
  },
  {
    id: 3,
    orderId: "87X6A44",
    name: "Joe Larson",
    email: "larson@example.com",
    phone: "+168  603-2320",
    date: "26 Dec 2019, 12:15 pm",
    invoiceItem1: "50.00",
    invoiceItem2: "200.00",
    invoiceItem3: "200.00",
    invoiceItem4: "50.00",
    totalAmount: "560.00",
    status: "Complete",
  },
  {
    id: 4,
    orderId: "986G531",
    name: "Jane Montgomery",
    email: "jane84@example.com",
    phone: "+439 271-5360",
    date: "21 Jan 2019, 6:12 am",
    invoiceItem1: "1500.00",
    invoiceItem2: "500.00",
    invoiceItem3: "1000.00",
    invoiceItem4: "600.00",
    totalAmount: "3660.00",
    status: "Cancelled",
  },
  {
    id: 5,
    orderId: "326T4M9",
    name: "Frances Burns",
    email: "frances@example.com",
    phone: "+639 130-3150",
    date: "21 Jan 2019, 6 :12 am",
    invoiceItem1: "100.00",
    invoiceItem2: "100.00",
    invoiceItem3: "50.00",
    invoiceItem4: "40.00",
    totalAmount: "350.00",
    status: "Complete",
  },
  {
    id: 6,
    orderId: "746F5K2",
    name: "Alan Butler",
    email: "butler@example.com",
    phone: "+963 309-1706",
    date: "23 Jan 2019, 10:45pm",
    invoiceItem1: "500.00",
    invoiceItem2: "500.00",
    invoiceItem3: "1000.00",
    invoiceItem4: "300.00",
    totalAmount: "2360.00",
    status: "Complete",
  },
  {
    id: 7,
    orderId: "546H74W",
    name: "Victoria Lynch",
    email: "victoria@example.com",
    phone: "+811 985-4846",
    date: "12 Jan 2020, 10:45pm",
    invoiceItem1: "10.00",
    invoiceItem2: "30.00",
    invoiceItem3: "10.00",
    invoiceItem4: "6.00",
    totalAmount: "126.00",
    status: "Pending",
  },
  {
    id: 8,
    orderId: "87X6A44",
    name: "Patrick Newman",
    email: "patrick@example.com",
    phone: "+942 238-4474",
    date: "26 Dec 2019, 12:15 pm",
    invoiceItem1: "150.00",
    invoiceItem2: "150.00",
    invoiceItem3: "200.00",
    invoiceItem4: "50.00",
    totalAmount: "610.00",
    status: "Complete",
  },
  {
    id: 9,
    orderId: "746F5K2",
    name: "Billiard Homes",
    email: "info@softnio.com",
    phone: "+811 847-4958",
    date: "23 Jan 2019, 10:45pm",
    invoiceItem1: "500.00",
    invoiceItem2: "500.00",
    invoiceItem3: "1000.00",
    invoiceItem4: "40.00",
    totalAmount: "2300.00",
    status: "Complete",
  },
  {
    id: 10,
    orderId: "546H74W",
    name: "Ashley Anderson",
    email: "ashley@softnio.com",
    phone: "+124 394-1787",
    date: "12 Jan 2020, 10:45pm",
    invoiceItem1: "50.00",
    invoiceItem2: "50.00",
    invoiceItem3: "10.00",
    invoiceItem4: "40.00",
    totalAmount: "210.00",
    status: "Pending",
  },
  {
    id: 11,
    orderId: "87X6A44",
    name: "Jim Larson",
    email: "larson@example.com",
    phone: "+168  603-2320",
    date: "26 Dec 2019, 12:15 pm",
    invoiceItem1: "50.00",
    invoiceItem2: "200.00",
    invoiceItem3: "200.00",
    invoiceItem4: "50.00",
    totalAmount: "560.00",
    status: "Complete",
  },
  {
    id: 12,
    orderId: "986G531",
    name: "Jane Gomez",
    email: "jane84@example.com",
    phone: "+439 271-5360",
    date: "21 Jan 2019, 6 :12 am",
    invoiceItem1: "100.00",
    invoiceItem2: "100.00",
    invoiceItem3: "50.00",
    invoiceItem4: "40.00",
    totalAmount: "350.00",
    status: "Cancelled",
  },
  {
    id: 13,
    orderId: "326T4M9",
    name: "Frances Airens",
    email: "frances@example.com",
    phone: "+639 130-3150",
    date: "21 Jan 2019, 6 :12 am",
    invoiceItem1: "100.00",
    invoiceItem2: "100.00",
    invoiceItem3: "50.00",
    invoiceItem4: "40.00",
    totalAmount: "350.00",
    status: "Complete",
  },
  {
    id: 14,
    orderId: "746F5K2",
    name: "James Butler",
    email: "butler@example.com",
    phone: "+963 309-1706",
    date: "23 Jan 2019, 10:45pm",
    invoiceItem1: "500.00",
    invoiceItem2: "500.00",
    invoiceItem3: "1000.00",
    invoiceItem4: "40.00",
    totalAmount: "2300.00",
    status: "Complete",
  },
  {
    id: 15,
    orderId: "326T4M9",
    name: "Jack Simmons",
    email: "frances@example.com",
    phone: "+639 130-3150",
    date: "21 Jan 2019, 6 :12 am",
    invoiceItem1: "100.00",
    invoiceItem2: "100.00",
    invoiceItem3: "50.00",
    invoiceItem4: "40.00",
    totalAmount: "350.00",
    status: "Cancelled",
  },
];
export default invoiceData;
